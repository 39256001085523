import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { FormHelperText } from '@mui/material';
import theme from '../../utils/theme';

const NormalSelect =  ({label, size, value, setValue, options, error, optionLabel })=>{
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <FormControl fullWidth size="small">
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          error={!!error}
          size={size}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value || ""}
          label={label}
          onChange={handleChange}
        >
          {
            options && options.length > 0 ?
            options.map((option, index)=>
              <MenuItem key={index} value={option}>{option[optionLabel]}</MenuItem>
              ):
              <MenuItem disabled>There is no option</MenuItem>
          }
        </Select>
        <FormHelperText sx={{ color: theme.palette.danger.main }}>{error?.message}</FormHelperText>
      </FormControl>
  );
}

export default NormalSelect