import { Box, Button, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from "@mui/material";
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import { createAccount } from "../../slices/authSlice";
import { getAllUsers } from "../../slices/userSlice";
import AsyncAutoComplete from "../utils/AsyncAutoComplete";
import { getAllShops } from "../../slices/shopSlice";

const createAccountSchema = yup.object().shape({
    name: yup.string().required('Please add Shop Name!'),
    position: yup.string().required('Please add Position!'),
    email: yup.string().email('Your Email is Wrong!').required('Please add Email Address!'),
    password: yup.string().min(8, "Password must be at least 8 characters").required('Password is required'),
    type: yup.string().required("Select account type!"),
    shop: yup.object().required("Please select an option")
});

const AddNewAccount = ({ open, setOpen }) => {
    const dispatch = useDispatch()
    const [ searchText,setSearchText ] = React.useState("")
    const [ shop, setShop ] = React.useState(null);
    const { shops: shops, loading } = useSelector(state=> state.Shop)

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
        trigger
    } = useForm({
        resolver: yupResolver(createAccountSchema)
    });

    const fetchData = async()=>{
        await dispatch(getAllUsers({ method: 'get', data: null }))
    }
    const fetchShop = async()=>{
        await dispatch(getAllShops({ method: 'get', data: null }))
    }

    const handleClose = () => {
        setOpen(false);
        reset();
        setShop(null)
    };

    const handleOnChange = (event)=>{
        setValue("type",event.target.value);
    }

    const onSubmit = async (data) => {
        const newData = {...data, shopId: data.shop.id}
        await dispatch(createAccount({ method: 'post', data: newData }))
        handleClose();
        await fetchData()
    };

    React.useEffect(()=>{
        if(shop){
            setValue('shop', shop)
            trigger('shop')
        }else{
            setValue('shop', null)
        }
    },[shop])

    React.useEffect(() => {
        setOpen(open);
        if(!shops){
            fetchShop()
        }
    }, [open, setOpen]);

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                    <DialogContent sx={{ width: 400 }}>
                        <DialogTitle sx={{ pl: 0, pt: 0 }}>Add New Account</DialogTitle>
                        <form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
                            <TextField fullWidth sx={{ mb: 3}} {...register('name')} error={!!errors?.name} helperText={errors?.name?.message} label="Name" />
                            <AsyncAutoComplete loading={loading} setSearchText={setSearchText} selectedOption={shop} setSelectedOption={setShop} options={shops?.data || []} placeholder={'Select Shop'} error={errors?.shop}/>
                            <TextField fullWidth sx={{ my: 3}} {...register('position')} error={!!errors?.position} helperText={errors?.position?.message} label="Position" />
                            <TextField fullWidth sx={{ mb: 3}} {...register('email')} error={!!errors?.email} helperText={errors?.email?.message} label="Email" />
                            <TextField fullWidth sx={{ mb: 3}} {...register('password')} error={!!errors?.password} helperText={errors?.password?.message} label="Password" />
                            <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label" sx={{ mb: 1 }}>Type</FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="female"
                                    name="radio-buttons-group"
                                    row
                                    onChange={handleOnChange}
                                >
                                    <FormControlLabel value="owner" control={<Radio />} label="Owner" />
                                    <FormControlLabel value="employee" control={<Radio />} label="Staff" />
                                </RadioGroup>
                            </FormControl>
                            <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                <Button type='button' variant="text" sx={{ mr: 2 }} onClick={handleClose} >Cancel</Button>
                                <Button type='submit' variant="contained">Create</Button>
                            </Box>
                        </form>
                    </DialogContent>
            </Dialog>
        </React.Fragment>
    );
};

export default AddNewAccount;