import * as React from 'react';
import { Button, TextField, DialogContent, DialogTitle,Dialog, Box, FormControl, FormHelperText, RadioGroup, FormControlLabel, Radio } from "@mui/material"
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch } from "react-redux";
import { getAllItem, updateStock } from "../../slices/itemSlice";
import theme from '../../utils/theme';

const addStockSchema = yup.object().shape({
    quantity: yup.number().positive().required('Please Add Quantity!').typeError('Your Quantity is Wrong!'),
    type: yup.number().test('is boolean',
      'Please select reduction or addition',
      (value) => value === 0 || value === 1)
})

const UpdateStock = ({ open, setOpen, idToAdd }) => {

    const dispatch = useDispatch()
    const [ type,setType ] = React.useState('')
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue
    } = useForm({
        resolver: yupResolver(addStockSchema)
    })

    const [isopen, setIsOpen] = React.useState(open);

    const fetchItems = async ()=>{
        await dispatch(getAllItem({ method: 'get', data: null }))
    }

    function handleClose() {
        setIsOpen(false)
        setOpen(false)
        reset()
    }

    const handleOnSubmit = async (data) => {
        const newData = {
          quantity: data.quantity,
        };
        await dispatch(updateStock({ method: 'put', data: newData, id: idToAdd, type: data.type === 0 ? 'reduct' :'add'}));
        fetchItems();
        handleClose();
    };

    React.useEffect(
        () => {
            reset()
            setType('')
            setIsOpen(open)
        }, [open]
    )

    return (
        <React.Fragment>
          <Dialog
            open={isopen}
            onClose={handleClose}
          >
              <DialogContent sx={{ width: 350, pt: 0 }}>
                <DialogTitle sx={{ pl: 0 }}>Update Stock</DialogTitle>
                <form autoComplete="off" onSubmit={handleSubmit(handleOnSubmit)}>
                <TextField
                  fullWidth
                  {...register('quantity')}
                  error={!!errors?.quantity}
                  placeholder='Quantity'
                  helperText={errors?.quantity?.message}
                  size="small"
                ></TextField>
                <FormControl sx={{ my: 2 }}>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={type}
                    onChange={(e)=>{
                      setType(e.target.value)
                      setValue('type', e.target.value)
                    }}
                  >
                    <FormControlLabel value={0} control={<Radio />} label="Reduction" />
                    <FormControlLabel value={1} control={<Radio />} label="Addition" />
                    <FormHelperText sx={{ color: theme.palette.danger.main }} >{errors?.type?.message}</FormHelperText>
                  </RadioGroup>
                </FormControl>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: 0, gap: 2 }}>
                  <Button type='button' onClick={handleClose}>Cancel</Button>
                  <Button type='submit' variant="contained">Update</Button>
                </Box>
              </form>
              </DialogContent>
          </Dialog>
        </React.Fragment>
      );
    };
    
    export default UpdateStock;