import React from 'react'
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material'

const CommissionTypeSelect = ({value,setValue, error}) => {
  return (
    <FormControl fullWidth size='small'>
        <InputLabel id="demo-simple-select-label">Select Type</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label="Select Type"
          onChange={(event)=>{ setValue(event.target.value) }}
        >
            <MenuItem value={'percent'}>Percentage (%)</MenuItem>
            <MenuItem value={'flat'}>Amount</MenuItem>
        </Select>
        <FormHelperText>{error?.message}</FormHelperText>
    </FormControl>
  )
}

export default CommissionTypeSelect
