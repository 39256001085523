import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import NoDataAlert from '../utils/NoDataAlert'
import { useLocation } from 'react-router-dom'

const GoldCardSaleTable = ({ goldCardRecords }) => {
    const { pathname } = useLocation()
    const [ isStaffDetail,setIsStaffDetail ] = useState(false)
    useEffect(()=>{
        if(pathname.includes('staff-detail')){
            setIsStaffDetail(true)
        }else{
            setIsStaffDetail(false)
        }
    },[pathname])
    return (
    <div style={{ pageBreakAfter: "always" }}>
        <Typography sx={{ pl: 2, fontWeight: 'bold' }}>Gold Card Sale Records</Typography>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontSize: '12px' }} align="center">No</TableCell>
                            {
                                isStaffDetail === false && <TableCell sx={{ fontSize: '12px' }}>Customer Name</TableCell>
                            }
                            <TableCell sx={{ fontSize: '12px' }}>Code</TableCell>
                            {
                                isStaffDetail === false && <TableCell sx={{ fontSize: '12px' }}>Staffs</TableCell>
                            }
                            <TableCell sx={{ fontSize: '12px' }} align="right">Price</TableCell>                   
                            {
                                isStaffDetail === true &&
                                <TableCell sx={{ fontSize: '12px' }} align="center">Commission</TableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            goldCardRecords && goldCardRecords.length > 0 ?
                            goldCardRecords.map((rec,index)=>
                            <TableRow key={index}>
                                <TableCell sx={{ fontSize: '12px' }} align="center">{index+ 1}</TableCell>
                                {
                                    isStaffDetail === false && <TableCell sx={{ fontSize: '12px' }}>{rec.customer?.name}</TableCell>
                                }
                                <TableCell sx={{ fontSize: '12px' }}>{rec.code}</TableCell>
                                {
                                    isStaffDetail === false && <TableCell sx={{ fontSize: '12px' }}>{rec.employees && rec.employees.length > 0 && rec.employees.map(el=> `${el.name}${el.status ? ` (${el.status}- ${el.commission} MMK)` : ` (${el.commission} MMK)`}, ` )}</TableCell>
                                }
                                <TableCell sx={{ fontSize: '12px' }} align="right">{rec.price}</TableCell>
                                {
                                    isStaffDetail === true &&
                                    <TableCell sx={{ fontSize: '12px' }} align="center">{rec.commission}</TableCell>
                                } 
                            </TableRow>
                            )                            
                            :
                            <TableRow>
                                <TableCell sx={{ fontSize: '12px' }} colSpan={8}>
                                    <NoDataAlert content={"There is no records"}></NoDataAlert>
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
    </div>
  )
}

export default GoldCardSaleTable
