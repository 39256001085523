import * as React from 'react';
import Button from '@mui/material/Button';
import { Box, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { getAllShops, shopCud } from '../../slices/shopSlice'

const validationSchema = yup.object().shape({
    name: yup.string().required('Please add Shop Name!'),
    address: yup.string().required('Please add address!'),
    phone: yup.string().required('Please add Phone Number!')
        .matches(/^\d{9,11}$/, 'Your Phone Number is Wrong!'),
})

const CreateNewShop = () => {

    const dispatch = useDispatch()
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(validationSchema)
    })

    const onSubmit = async (data)=>{
        await dispatch(shopCud({ method: 'post', data: data, id: null }));
        reset()
        await dispatch(getAllShops({ method: 'get', data: null }))
    }

    return (
        <Box sx={{ width: 300 }}>
            <Typography variant='h6' sx={{ fontWeight: 'bold', mb: 2 }}>Create New Shop</Typography>
            <form autoComplete='off' onSubmit={handleSubmit(onSubmit)} sx={{ display: 'flex', flexDirection: 'column'}}>
                <TextField size="small" sx={{ width: '100%', mb: 2 }} {...register('name')} error={!!errors?.name} placeholder='Shop Name' helperText={errors?.name?.message}></TextField>
                <TextField size="small" sx={{ width: '100%', mb: 2 }} multiline {...register('address')} error={!!errors?.address} placeholder='address' helperText={errors?.address?.message}></TextField>
                <TextField size="small" sx={{ width: '100%', mb: 2 }} {...register('phone')} error={!!errors?.phone} placeholder='Phone Number' helperText={errors?.phone?.message}></TextField>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button type='button' sx={{ mr: 2 }} onClick={()=> reset()}>Cancel</Button>
                    <Button type='submit' variant='contained'>Create</Button>
                </Box>
            </form>
        </Box>
    )
}

export default CreateNewShop