import { useEffect, useRef, useState } from "react";
import { Box, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Typography, alpha } from "@mui/material"
import huaMeiLogo from '../assets/images/hua_mei_logo.png'
import theme from "../utils/theme";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getReceipt, resetReceipt } from "../slices/receiptSlice";
import { changeDateTime } from "../utils/changeDateTime";
import MemberDialog from "../components/utils/MemberDialog";
import Loading from "../components/utils/Loading";
import { shopId } from "../utils/config";
import GoToBackButton from "../components/utils/GoToBackButton";

const SaleVoucherDetail = () => {
    const dispatch = useDispatch()
    const { id } = useParams()
    const [ showCode,setShowCode ] = useState(false)
    const { receipt: receipt , loading } = useSelector(state=> state.Receipt)
    const { shops, loading: shopLoading } = useSelector(state=> state.Shop)
    const [ tableItemsToShow, setTableItemsToShow] = useState([]);
    const [ filteredShop,setFilteredShop ] = useState(null)
    // const [ additionalCost,setAdditionalCost ] = useState(0)
    const [ tax,setTax ] = useState(0)
    const [ cash,setCash ] = useState(0)
    const [ kpay,setKpay ] = useState(0)
    const [ total,setTotal ] = useState(0)
    const [ subTotal,setSubTotal ] = useState(0)
    const [ date,setDate ] = useState(null)
    const [ code,setCode ] = useState(null)
    const [ customer,setCustomer ] = useState(null)

    const fetchReceipt = async()=>{
        await dispatch(getReceipt({ method: 'get', data: null, type: 'sale' ,id: id }))
    }

    const updateTableItems = (items, changeItemFormat, type, setTableItemsToShow) => {
        items.forEach(item => {
            setTableItemsToShow(prev => {
                return [...prev, changeItemFormat(item, type)];
            });
        });
    };

    const changeItemFormat = (itemData, type) => {
        if (type === 'goldCard') {
            return {
                name: `GoldCard ( code- ${itemData.code})`,
                price: itemData.price,
                qty: 1,
                mainStaff: itemData.employees,
                total: itemData.price
            }
        } else if (type === 'membership') {
            return {
                name: `${itemData.serviceName} (Membership)`,
                price: itemData.servicePrice,
                qty: Number(itemData.time) + Number(itemData.freeTime),
                mainStaff: itemData.employees,
                total: itemData.price
            }
        } else if (type === 'membershipUsage'){
            return {
                name: itemData.membershipName,
                price: 0,
                qty: 1,
                additionalCost: itemData.additionalCost,
                mainStaff: itemData.employees,
                total: itemData.additionalCost
            }
        } else if (type === 'service') {
            return {
                name: itemData.name,
                price: itemData.originalTotalPrice,
                discount: itemData.seasonalDiscountPercent ? `Seasonal discount - ${itemData.seasonalDiscountPercent}%` : itemData.goldCardDiscountPercent ? `Gold Card - ${itemData.goldCardDiscountPercent}%` : "",
                manualDiscount: itemData.manualDiscountPrice,
                qty: itemData.quantity,
                mainStaff: itemData.employees,
                total: itemData.totalPrice
            }
        } else if (type === 'item') {
            return {
                name: itemData.name,
                price: itemData.originalTotalPrice,
                qty: itemData.quantity,
                mainStaff: itemData.employees,
                discount: itemData.seasonalDiscountPercent ? `Seasonal discount - ${itemData.seasonalDiscountPercent}%` : itemData.goldCardDiscountPercent ? `Gold Card - ${itemData.goldCardDiscountPercent}%` : "",
                manualDiscount: itemData.manualDiscountPrice,
                total: itemData.totalPrice
            }
        }
    };

    useEffect(()=>{
        if(receipt && receipt.data){
            const data = receipt.data;
            setCustomer(data.customer)
            setKpay(data.kpay)
            setCash(data.cash)
            // setAdditionalCost(data.additionalCost)
            setTax(data.tax)
            // setTotal(data.totalPrice)
            setCode(data.code)
            setDate(data.date)
            // const subtotal = Number(data.totalPrice) - (Number(data.additionalCost) + Number(data.tax))
            // setSubTotal(subtotal)
            data.memberships && updateTableItems(data.memberships, changeItemFormat, 'membership', setTableItemsToShow);
            data.goldCardRecords && updateTableItems([data.goldCardRecords], changeItemFormat, 'goldCard', setTableItemsToShow);
            data.membershipUsageRecords && updateTableItems(data.membershipUsageRecords, changeItemFormat, 'membershipUsage', setTableItemsToShow);
            data.serviceRecords && updateTableItems(data.serviceRecords, changeItemFormat, 'service', setTableItemsToShow);
            data.saleRecords && updateTableItems(data.saleRecords, changeItemFormat, 'item', setTableItemsToShow);
        }
    },[receipt])

    useEffect(()=>{
        if(tableItemsToShow && tableItemsToShow.length > 0){
            const total = tableItemsToShow.reduce((acc, currentItem) => acc += Number(currentItem.total), 0);
            setSubTotal(total);
            const grandTotal = Number(total) + Number(tax)
            setTotal(grandTotal)
        }
    },[tableItemsToShow])

    useEffect(()=>{
        if(shops){
            const filtered = shops.data.filter(shop=> shop.id === shopId())
            setFilteredShop(filtered[0])
        }
    },[shops])

    useEffect(()=>{
        setTableItemsToShow([])
        dispatch(resetReceipt())
        fetchReceipt()
    },[])

    return (
        <Box sx={{
            bgcolor: theme.palette.common.white,
            borderRadius: '10px',
            minHeight: '100vh',
            pt: 2
        }}>
            {
                loading && <Loading></Loading>
            }
            <MemberDialog open={showCode} setOpen={setShowCode} code={code} date={date}></MemberDialog>
            {/* voucher section */}
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', px: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                    <img
                        height={80}
                        src={`${huaMeiLogo}`}
                        alt={"Hua Mei"}
                        loading="lazy"
                    />
                    <Box sx={{ ml: 2 }}>
                        <Typography variant="h6" sx={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>{filteredShop?.name}</Typography>
                        <Typography>Beauty Center</Typography>
                        <Typography sx={{
                                fontSize: '12px',
                                maxWidth: 300,
                                mt: 1,
                                color: alpha(theme.palette.dark.main, 0.5)
                        }}>
                            { filteredShop?.address }
                            <br></br>
                            Phone - { filteredShop?.phone }
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <Table>
                        <TableBody>
                        <TableRow>
                            <TableCell sx={{ border: 'none', py: 1, fontSize: "14px" }} align="left">Date </TableCell>
                            <TableCell sx={{ border: 'none', py: 1 }}>-</TableCell>
                            <TableCell sx={{ border: 'none', py: 1 }} variant="body2" align="right">{date && changeDateTime(date)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{ border: 'none', py: 1, fontSize: "14px" }} align="left">Customer Name</TableCell>
                            <TableCell sx={{ border: 'none', py: 1 }}>-</TableCell>
                            <TableCell sx={{ border: 'none', py: 1 }} variant="body2" align="right">{customer?.name}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{ border: 'none', py: 1, fontSize: "14px" }} align="left">Code</TableCell>
                            <TableCell sx={{ border: 'none', py: 1 }}>-</TableCell>
                            <TableCell sx={{ border: 'none', py: 1 }} variant="body2" align="right">{code ? code : "-"}</TableCell>
                        </TableRow>
                        </TableBody>
                    </Table>
                </Box>
                </Box>
                <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ py: 1, fontWeight: 'bold' }} align="center">No</TableCell>
                            <TableCell sx={{ py: 1, fontWeight: 'bold' }}>Item Or Service Name</TableCell>
                            <TableCell sx={{ py: 1, fontWeight: 'bold' }}>Staff</TableCell>
                            <TableCell sx={{ py: 1, fontWeight: 'bold' }} align="center">Price</TableCell>
                            <TableCell sx={{ py: 1, fontWeight: 'bold' }} align="center">Qty</TableCell>
                            <TableCell sx={{ py: 1, fontWeight: 'bold' }} align="center">Discount</TableCell>
                            <TableCell sx={{ py: 1, fontWeight: 'bold' }} align="center">Manual Discount</TableCell>
                            <TableCell sx={{ py: 1, fontWeight: 'bold' }} align="center">Additional Cost</TableCell>
                            <TableCell sx={{ py: 1, fontWeight: 'bold' }} align="right">Total</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            tableItemsToShow.length > 0 ?
                            tableItemsToShow.map((item,index)=>
                            <TableRow key={index}>
                                <TableCell align="center">{index + 1}</TableCell>
                                <TableCell>{item.name}</TableCell>
                                <TableCell>
                                    { item.mainStaff && item.mainStaff.length > 0 && item.mainStaff.map(el=> `${el.name}${el.status ? ` (${el.status}- ${el.commission} MMK)` : ` (${el.commission} MMK)`}, ` )}
                                </TableCell>
                                <TableCell align="center">{item.price ? item.price.toLocaleString() : '-'}</TableCell>
                                <TableCell align="center">{item.qty}</TableCell>
                                <TableCell align="center">{item.discount ? item.discount : "-"}</TableCell>
                                <TableCell align="center">{item.manualDiscount ? (item.manualDiscount).toLocaleString() : "-"}</TableCell>
                                <TableCell align="center">{item.additionalCost ? (item.additionalCost).toLocaleString() : "-"}</TableCell>
                                <TableCell align="right">{item.total ? item.total.toLocaleString() : 0}</TableCell>
                            </TableRow>
                            )
                            :
                            <></>
                        }
                        <TableRow>
                            <TableCell colSpan={7} sx={{ py: 1 }}></TableCell>
                            <TableCell align="right" sx={{ py: 1 }}>Subtotal</TableCell>
                            <TableCell align="right" sx={{ fontSize: '18px', py: 1 }}>{subTotal.toLocaleString()}</TableCell>
                        </TableRow>
                        {/* <TableRow>
                            <TableCell colSpan={7} sx={{ py: 1 }}></TableCell>
                            <TableCell align="right" sx={{ py: 1 }}>Additional Cost</TableCell>
                            <TableCell align="right" sx={{ fontSize: '18px', py: 1 }}>{additionalCost}</TableCell>
                        </TableRow> */}
                        <TableRow>
                            <TableCell colSpan={7} sx={{ py: 1 }}></TableCell>
                            <TableCell align="right" sx={{ py: 1 }}>Tax</TableCell>
                            <TableCell align="right" sx={{ fontSize: '18px', py: 1 }}>{tax.toLocaleString()}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={7} sx={{ py: 1 }}></TableCell>
                            <TableCell align="right" sx={{ py: 1 }}>Total Cost</TableCell>
                            <TableCell align="right" sx={{ fontSize: '18px', py: 1, fontWeight: 'bold' }}>{total.toLocaleString()}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={7} sx={{ py: 1 }}></TableCell>
                            <TableCell align="right" sx={{ py: 1 }}>Kpay</TableCell>
                            <TableCell align="right" sx={{ fontSize: '18px', py: 1, fontWeight: 'bold' }}>{kpay.toLocaleString()}</TableCell>
                        </TableRow><TableRow>
                            <TableCell colSpan={7} sx={{ py: 1 }}></TableCell>
                            <TableCell align="right" sx={{ py: 1 }}>Cash</TableCell>
                            <TableCell align="right" sx={{ fontSize: '18px', py: 1, fontWeight: 'bold' }}>{cash.toLocaleString()}</TableCell>
                        </TableRow>
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={8} sx={{ border: 'none' }} align="center">
                                <Typography>Thank you !</Typography>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <GoToBackButton></GoToBackButton>
        </Box>
    )
}

export default SaleVoucherDetail