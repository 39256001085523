import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import CustomAutocomplete from '../utils/CustomAutocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCategory, getAllItem, itemCud } from '../../slices/itemSlice';
import { shopId } from '../../utils/config';

const validationSchema = yup.object().shape({
    name: yup.string().required('Please add Item Name!'),
    category: yup.object().required('Please Select Category!'),
    salePrice: yup.number().min(0).typeError('Sale price is Wrong!').required('Sale price is required'),
    purchasePrice:yup.number().min(0).typeError('Purchase price is Wrong!').required('Purchase price is required'),
    commission: yup.number().typeError("Please fill a valid commission value!").required("Commission percentage is required!")
})

const CreateNewStock = ({ open, setOpen }) => {

    const dispatch = useDispatch()
    const [category, setCategory] = React.useState(null);
    const [isopen, setIsOpen] = React.useState(open);
    const categories = useSelector(state=> state.Item.categories)

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        trigger,
    } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            purchasePrice: 0,
        },
    })


    function handleClose() {
        setIsOpen(false)
        setOpen(false)
        reset()
        setCategory(null)
    }

    const fetchData = async()=>{
        await dispatch(getAllItem({ method: 'get', data: null }))
    }

    const fetchCategories = async ()=>{
        await dispatch(getAllCategory({ method: 'get', data: null }))
    }

    const handleOnSubmit = async (data)=>{
        const newData = {
            name: data.name,
            categoryId: data.category.id,
            salePrice: data.salePrice,
            purchasePrice: data.purchasePrice,
            // stock : data.quantity,
            commissionPercent: data.commission,
            shopId : shopId()
        }
        await dispatch(itemCud({ method: 'post', data: newData, id: null }))
        fetchData()
        setOpen(false)
        reset()
        setCategory(null)
        setValue('category', null)
    }

    React.useEffect(()=>{
        if(category){
            setValue('category', category)
            trigger('category')
        }else{
            setValue('category', null)
        }
    },[category])

    React.useEffect(
        () => {
            fetchCategories()
            setIsOpen(open)
        }, [open]
    )

    return (
        <React.Fragment>
            <Dialog
                open={isopen}
                onClose={handleClose}
            >
                    <DialogContent sx={{
                        width: 400,
                    }}>
                    <DialogTitle sx={{ pl: 0, pt: 0 }}>Create New Item</DialogTitle>
                    <form autoComplete='off' onSubmit={handleSubmit(handleOnSubmit)}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            <TextField sx={{ mb: 3 }} {...register('name')} error={!!errors?.name} label='Item Name' helperText={errors?.name?.message}></TextField>
                            <CustomAutocomplete selectedOption={category} setSelectedOption={setCategory} options={categories?.data || []} placeholder={'Select Category'} error={errors?.category}></CustomAutocomplete>
                            <TextField sx={{ mt: 3 }} {...register('salePrice')} error={!!errors?.salePrice} label='Sale Price' helperText={errors?.salePrice?.message}></TextField>
                            <TextField sx={{ mt: 3 }} {...register('purchasePrice')} fullWidth error={!!errors?.purchasePrice} label='Purchase Price' helperText={errors?.purchasePrice?.message}></TextField>
                            {/* <TextField sx={{ mt: 3 }} {...register('quantity')} error={!!errors?.quantity} label='Quantity' helperText={errors?.quantity?.message}></TextField> */}
                            <TextField sx={{ mt: 3 }} {...register('commission')} error={!!errors?.commission} label='Commission (%)' helperText={errors?.commission?.message}></TextField>
                        </Box>
                        <Box sx={{ mt: 3, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <Button type='button' variant="text" sx={{ mr: 2 }} onClick={handleClose} >Cancel</Button>
                            <Button type='submit' variant="contained">Create</Button>
                        </Box>
                    </form>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

export default CreateNewStock