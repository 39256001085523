import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { damagedCud, getAllDamaged, getAllItem, getAllServiceSupply, serviceSupplyCud } from '../../slices/itemSlice';
import { shopId } from '../../utils/config';
import AsyncSelect from '../utils/AsyncSelect';

const validationSchema = yup.object().shape({
    item: yup.object().nullable().required('Please Select Item Name!'),
    quantity: yup.number().required('Please Add Quantity!').typeError('Your Quantity is Wrong!')
})


const CreateUsage = ({ open, setOpen, type }) => {

    const dispatch = useDispatch()
    const [ pageNo,setPageNo ] = React.useState(1)
    const { items: response, loading } = useSelector(state=> state.Item)
    const [searchQuery, setSearchQuery] = React.useState("");
    const [item, setItem] = React.useState({});
    const [items, setItems] = React.useState([]);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        trigger,
        reset
    } = useForm({
        resolver: yupResolver(validationSchema)
    })

    const [isopen, setIsOpen] = React.useState(open);

    const fetchItems = async ()=>{
        await dispatch(getAllItem({ method: 'get', data: null, stock: true, keyword: searchQuery, scrollPage: pageNo }))
    }

    function handleClose() {
        setItem({})
        setSearchQuery("")
        setPageNo(1)
        setIsOpen(false)
        setOpen(false)
        reset();
    }

    const onSubmit = async (data)=>{
        const newData = {
            itemId: data.item.id,
            shopId: shopId,
            quantity: data.quantity
        }
        if(type !== 'ss' ){
            await dispatch(damagedCud({ method: 'post', data: newData, id: null }))
            await dispatch(getAllDamaged({ method: 'get', data: null }))
        }else{
            await dispatch(serviceSupplyCud({ method: 'post', data: newData, id: null }));
            await dispatch(getAllServiceSupply({ method: 'get', data: null }))
        }
        handleClose()
    }

    React.useEffect(()=>{
        fetchItems()
    },[searchQuery])

    React.useEffect(()=>{
        if(item){
            setValue('item', item)
            trigger('item')
        }else{
            setValue('item', null)
        }
    },[item])

    React.useEffect(() => {
        if (response) setItems(response.data);
      }, [response]);
    
    React.useEffect(() => {
        fetchItems();
    }, [pageNo, searchQuery]);

    React.useEffect(
        () => {
            setIsOpen(open)
            fetchItems()
        }, [open]
    )
    
    return (
        <React.Fragment>
            <Dialog
                open={isopen}
                onClose={handleClose}
                // classes={dialogPaperClasses}
            >
                <DialogContent>
                    <DialogTitle sx={{ pl: 0, pt: 0 }}>Add {type === 'ss' ? "Service Supply" : "Damaged"} Item</DialogTitle>
                    <form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: 300
                        }}>
                            <AsyncSelect 
                                placeholder={"Select Item"}
                                loading={loading}
                                value={item ? item.name : ""}
                                setValue={setItem}
                                pageNo={pageNo}
                                searchText={searchQuery}
                                options={items}
                                setSearchText={setSearchQuery}
                                setPageNo={setPageNo}
                                hasMore={pageNo < response?.meta?.last_page}
                                position='static'/>
                            <TextField
                                sx={{ mt: 3 }}
                                size='small'
                                {...register('quantity')}
                                error={!!errors?.quantity}
                                placeholder='Quantity'
                                helperText={errors?.quantity?.message}>
                            </TextField>
                        </Box>
                        <Box sx={{ mt: 3, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <Button type='button' variant="text" sx={{ mr: 2 }} onClick={handleClose} >Cancel</Button>
                            <Button type='submit' variant="contained">Create</Button>
                        </Box>
                    </form>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

export default CreateUsage