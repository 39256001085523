import * as React from 'react';
import { Box, ImageListItem, ImageListItemBar, Paper, Typography, alpha } from "@mui/material"
import SearchInput from "../components/main/filter-components/SearchInput";
import theme from "../utils/theme";
import DateFilters from "../components/main/filter-components/DateFilters";
import AppPagination from '../components/main/filter-components/AppPagination';
import { useDispatch, useSelector } from 'react-redux';
import { getMembershipRec, getMembershipRecDelete } from '../slices/membershipSlice';
import { changeDate, changeDateTime, formatTime } from '../utils/changeDateTime'
// import DeleteIcon from '@mui/icons-material/Delete';
import { IMG_BASE_URL } from '../utils/config';
// import MemberDialog from '../components/utils/MemberDialog';
import { useLocation } from 'react-router-dom';
import { getAllCus } from '../slices/customerSlice';
import Loading from '../components/utils/Loading';
import NoDataAlert from '../components/utils/NoDataAlert';
import AsyncSelect from '../components/utils/AsyncSelect';
import AlertDialog from '../components/utils/AlertDialog';

const MemberList = () => {

    const dispatch = useDispatch()
    const { search } = useLocation()
    const [ value, setValue ] = React.useState(0);
    const [ customer,setCustomer ] = React.useState(null)
    const [ customers,setCustomers ] = React.useState([])
    const { memberships : membershipsList, loading } = useSelector(state=> state.Membership)
    const { customers: response, loading: customerLoading } = useSelector(
      (state) => state.Customer
    );
    const [ showAlert,setShowAlert ] = React.useState(false)
    const [ idToDelMembershipUsage,setIdToDelMembershipUsage ] = React.useState(null)
    const [ idToDelMembership,setIdToDelMembership ] = React.useState(null)
    const [ pageNo, setPageNo] = React.useState(1);
    const [ searchQuery, setSearchQuery ] = React.useState("");

    const fetchCustomers = async () => {
      await dispatch(
        getAllCus({
          method: "get",
          data: null,
          keyword: searchQuery,
          scrollPage: pageNo,
        })
      );
    };

    const fetchData = async()=>{
        await dispatch(getMembershipRec({ method: 'get', data: null, cusId: customer?.id || null }))
    }

    // const handleMembershipUsageDelete = (membershipId, membershipUsageId)=>{
    //   setIdToDelMembershipUsage(membershipUsageId);
    //   setIdToDelMembership(membershipId)
    //   setShowAlert(true)
    // }

    const handleCancel = ()=>{
      setShowAlert(false)
      setIdToDelMembershipUsage(null)
      setIdToDelMembership(null)
    }
    
    const handleConfirm = async ()=>{
      await dispatch(getMembershipRecDelete({ method: 'delete', data: null , membershipId: idToDelMembership, membershipUsageId: idToDelMembershipUsage }))
      setShowAlert(false)
      setIdToDelMembershipUsage(null)
      setIdToDelMembership(null)
      fetchData();
    }

    const renderUsages = (time,recs, membershipId) => {
        const tabs = [];      
        for (let i = 0; i < time; i++) {
          tabs.push(
            <Paper elevation={2} sx={{ height: '100%', m: 1 ,display: 'inline-block' ,border: '1px solid', borderColor: alpha(theme.palette.common.black, 0.3), borderRadius: '10px' , height: '100%' }} key={i}>              
              <ImageListItem key={`Subheader-${i}`}>
                    <Box
                      sx={{
                        borderRadius: '10px',
                      }}
                    >
                      {
                        recs[i]?.image ?
                        <img
                          src={`${IMG_BASE_URL}/${recs[i].image}`}
                          style={{
                            height: 140,
                            borderRadius: '10px',
                          }}
                          alt={`Signature-${i}`}
                        />
                        :
                        <Box sx={{
                          width: 140,
                          height: 140,
                          borderRadius: '10px',
                        }}></Box>
                      }
                    </Box>
                    <ImageListItemBar
                      title={<Typography sx={{ textAlign: 'left', fontSize: '11px' }}>{recs[i] && `${changeDate(recs[i].usedAt)}`} {recs[i] && `${formatTime(recs[i].usedAt)}`}</Typography>}
                      // subtitle={<Typography variant='body2' sx={{ textAlign: 'left' }}>{recs[i] && `${formatTime(recs[i].usedAt)}`}</Typography>}
                      sx={{
                        borderBottomLeftRadius: '10px',
                        borderBottomRightRadius: '10px',
                        height: '30px',
                      }}
                      // actionIcon={
                      //   recs[i] ?
                      //   <IconButton
                      //     onClick={()=> handleMembershipUsageDelete(membershipId,recs[i].id)}
                      //     sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                      //   >
                      //     <DeleteIcon />
                      //   </IconButton>
                      //   :
                      //   <></>
                      // }
                    ></ImageListItemBar>
                  </ImageListItem>
            </Paper>
          );
        }
        return tabs;
    };  
      
    React.useEffect(() => {
      if (response && response.data){
        setCustomers(response.data);
      }
    }, [response]);

    React.useEffect(()=>{
        fetchData()
    },[search,customer])

    React.useEffect(() => {
      fetchCustomers();
    }, [searchQuery, pageNo]);

    return (
        <Box sx={{
            bgcolor: theme.palette.common.white,
            borderRadius: '10px',
            minHeight: '100vh',
            overflow: 'hidden',
            pb: 5
        }}>
            { loading && <Loading/> }
            <AlertDialog
                toggle={showAlert}
                setToggle={setShowAlert}
                cancel={handleCancel}
                confrim={handleConfirm}
                title={"Are you sure?"}
                content={"You want to delete this membership usage."}
            ></AlertDialog>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                px: 3,
                pt: 3,
                pb: 5
            }}>
                <DateFilters />
                <Box sx={{ display: 'flex' }}>
                  <Box sx={{ width: 300, mr: 2 }}>
                    <AsyncSelect
                      placeholder={"Select Customer"}
                      loading={customerLoading}
                      value={customer?.name || ""}
                      setValue={setCustomer}
                      pageNo={pageNo}
                      searchText={searchQuery}
                      options={customers}
                      setSearchText={setSearchQuery}
                      setPageNo={setPageNo}
                      hasMore={pageNo < response?.meta?.last_page}
                      position='absolute'
                    ></AsyncSelect>
                  </Box>
                  <SearchInput placeholderText={"Search by service name"}/>
                </Box>
            </Box>
            {
                  (membershipsList && membershipsList.data && membershipsList.data.length > 0) ?
                    membershipsList.data.map((membership,index)=>
                        <Box key={index} value={value} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-end', flexWrap: 'wrap', m: 3 }}>
                            <Paper elevation={3} sx={{ height: '100%', p: 1, display: 'inline-block' ,border: '1px solid', borderColor: alpha(theme.palette.common.black, 0.3), borderRadius: '10px' , height: 140, minWidth: 140 ,m: 1 }}>
                              <Typography sx={{ textAlign: 'left', fontWeight: 'bold', textTransform: 'capitalize' }}>{membership.customerName}</Typography>
                              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                  <Typography sx={{ textAlign: 'left', fontSize: '12px', textTransform: 'capitalize' }}>Service -</Typography>
                                  <Typography sx={{ textAlign: 'left', fontSize: '12px', textTransform: 'capitalize' }}>{membership.serviceName}</Typography>
                              </Box>
                              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                  <Typography sx={{ textAlign: 'left', fontSize: '12px', textTransform: 'capitalize' }}>Price -</Typography>
                                  <Typography sx={{ textAlign: 'left', fontSize: '12px' }}>{membership.servicePrice}MMK</Typography>
                              </Box>
                              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                  <Typography sx={{ textAlign: 'left', fontSize: '12px',textTransform: 'capitalize' }}>Usage -</Typography>
                                  <Typography sx={{ textAlign: 'left', fontSize: '12px' }}>{`${membership.usedTime}/ ${membership.time + membership.freeTime}`} Times</Typography>
                              </Box>
                              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                  <Typography sx={{ textAlign: 'left', fontSize: '12px' }}>{changeDateTime(membership.date)}</Typography>
                              </Box>
                              <Typography sx={{ textAlign: 'left',fontWeight: 'bold', fontSize: '12px', textTransform: 'capitalize', color: theme.palette.primary.main }}>
                                { membership?.employees?.length > 0 &&
                                  membership?.employees.map((staff,index)=> index > 0 ? `, ${staff.name} ` : staff.name)
                                }
                              </Typography>
                            </Paper>
                            {
                                renderUsages((Number(membership.time) + Number(membership.freeTime)), membership.membershipUsages, membership.id)
                            }
                        </Box>
                    ):
                    <Box sx={{ px: 3 }}>
                      <NoDataAlert content={"There is no memberships"}></NoDataAlert>
                    </Box>
                }
            <AppPagination pageCount={membershipsList?.meta?.last_page}/>
        </Box>
    )
}

export default MemberList