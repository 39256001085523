import React, { useState, useEffect, Fragment } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Collapse, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useDispatch, useSelector } from "react-redux";
import { getAllServices, priceCrud, serviceCud } from "../slices/serviceSlice";
import CreateNewService from "../components/service_components/CreateNewService";
import UpdateService from "../components/service_components/UpdateService";
import NoDataAlert from "../components/utils/NoDataAlert";
import Loading from "../components/utils/Loading";
import AlertDialog from "../components/utils/AlertDialog";
import AppPagination from "../components/main/filter-components/AppPagination";
import SearchInput from "../components/main/filter-components/SearchInput";
import StyledTableHead from "../components/utils/StyledTableHead";
import StyledTableData from "../components/utils/StyledTableData";
import theme from "../utils/theme";
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import CUPrice from "../components/service_components/CUPrice";

const NormalServiceList = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showAlert, setShowAlert] = useState(false);
    const [idToDel, setIdToDel] = useState(null);
    const { services: serviceList, loading } = useSelector(state => state.Service);
    const [createOpen, setCreateOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [serviceData, setServiceData] = useState(null);

    const fetchData = async () => {
        await dispatch(getAllServices({ method: 'get', data: null }));
    };

    const handleDelete = async (id) => {
        setShowAlert(true);
        setIdToDel(id);
    };

    const handleCancel = () => {
        setShowAlert(false);
        setIdToDel(null);
    };
    
    const handleConfirm = async () => {
        await dispatch(serviceCud({ method: 'delete', data: null, id: idToDel }));
        setShowAlert(false);
        setIdToDel(null);
        fetchData();
    };

    const ServiceRow = ({ service, index }) => {
        const [ open, setOpen ] = useState(false);
        const [priceDialogOpen, setPriceDialogOpen] = useState(false);
        const [priceToUpdate, setPriceToUpdate] = useState(null);
        const [priceShowAlert, setPriceShowAlert] = useState(false);
        const [idToDelPrice, setIdToDelPrice] = useState(null);

        const handlePriceCru = (priceData) => {
            setPriceDialogOpen(true);
            setPriceToUpdate(priceData);
        };

        const handleCancelPrice = () => {
            setPriceShowAlert(false);
            setIdToDelPrice(null);
        };
        
        const handleConfirmPrice = async () => {
            await dispatch(priceCrud({ method: 'delete', data: null, priceId: idToDelPrice }));
            setPriceShowAlert(false);
            // setIdToDelPrice(null);
            fetchData();
        };

        const handlePriceDelete = async (priceId) => {
            setPriceShowAlert(true);
            setIdToDelPrice(priceId);
        };

        return (
            <Fragment>
                <AlertDialog
                    toggle={priceShowAlert}
                    setToggle={setPriceShowAlert}
                    cancel={handleCancelPrice}
                    confrim={handleConfirmPrice}
                    title={"Are you sure?"}
                    content={"You want to delete this price."}
                />
                <CUPrice open={priceDialogOpen} setOpen={setPriceDialogOpen} priceDetail={priceToUpdate} serviceId={service.id} />
                <TableRow>
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <StyledTableData align="center">{((serviceList?.meta?.current_page - 1) * serviceList?.meta?.per_page) + (index + 1)}</StyledTableData>
                    <StyledTableData>{service.name}</StyledTableData>
                    <StyledTableData align="center">{service.normalCommissionPercent}%</StyledTableData>
                    <StyledTableData align="center">{service.bynameCommissionPercent}%</StyledTableData>
                    <StyledTableData align="center">
                        <Tooltip title="Edit">
                            <IconButton sx={{ mr: 2 }} onClick={() => {
                                setEditOpen(true);
                                setServiceData(service);
                            }}>
                                <DriveFileRenameOutlineIcon sx={{ color: theme.palette.warning.main }} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <IconButton onClick={() => handleDelete(service.id)}>
                                <DeleteIcon sx={{ color: theme.palette.primary.main }} />
                            </IconButton>
                        </Tooltip>
                    </StyledTableData>
                </TableRow>
                <TableRow>
                    <TableCell sx={{ p: 0 }} />
                    <TableCell sx={{ py: 0 }} colSpan={5}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ py: 1, display: 'flex' }}>
                                <Grid container spacing={2}>
                                    {service.prices.length > 0 ? (
                                        service.prices.map(p => (
                                            <Grid key={p.id} item md={2} sx={{ display: 'flex', alignItems: 'center', ml: 3 }}>
                                                <Typography sx={{ mb: 0 }}>{p.price}</Typography>
                                                <Tooltip title="Edit">
                                                    <IconButton onClick={() => handlePriceCru(p)} sx={{ ml: 2 }} color="warning">
                                                        <DriveFileRenameOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Delete">
                                                    <IconButton color="danger" onClick={() => handlePriceDelete(p.id)}>
                                                        <DoNotDisturbOnIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        ))
                                    ) : (
                                        <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                                            <NoDataAlert content={"There are no prices"} />
                                        </Grid>
                                    )}
                                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography onClick={() => handlePriceCru(null)} variant="span" sx={{ color: theme.palette.success.main, textDecoration: 'underline', cursor: 'pointer', userSelect: 'none', py: 2 }}>
                                            Add New Price
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </Fragment>
        );
    };

    useEffect(() => {
        fetchData();
    }, [location.search]);

    useEffect(() => {
        navigate("");
    }, []);

    return (
        <>
            {loading && <Loading />}
            <AlertDialog
                toggle={showAlert}
                setToggle={setShowAlert}
                cancel={handleCancel}
                confrim={handleConfirm}
                title={"Are you sure?"}
                content={"You want to delete this service."}
            />
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                p: 3
            }}>
                <Button onClick={() => setCreateOpen(true)} variant='contained' sx={{ height: '100%' }}>Add New</Button>
                <CreateNewService open={createOpen} setOpen={setCreateOpen} />
                <UpdateService open={editOpen} setOpen={setEditOpen} oldData={serviceData} />
                <SearchInput placeholderText={'Search by service name'} />
            </Box>
            <TableContainer sx={{ px: 3 }}>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <StyledTableHead align="center"></StyledTableHead>
                            <StyledTableHead align="center">No</StyledTableHead>
                            <StyledTableHead>Name</StyledTableHead>
                            <StyledTableHead align="center">Normal Commission (%)</StyledTableHead>
                            <StyledTableHead align="center">Byname Commission (%)</StyledTableHead>
                            <StyledTableHead sx={{ textAlign: 'center' }}>Actions</StyledTableHead>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {serviceList && serviceList.data.length > 0 ? (
                            serviceList.data.map((service, index) => (
                                <Fragment key={index}>
                                    <ServiceRow index={index} service={service} />
                                </Fragment>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={6}>
                                    <NoDataAlert content={"There are no services"} />
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <AppPagination pageCount={serviceList?.meta?.last_page} />
        </>
    );
};

export default NormalServiceList;
