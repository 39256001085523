import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { customerCud, getAllCus } from '../../slices/customerSlice';

const createCustomerSchema = yup.object().shape({
    name: yup.string().required('Please add Customer Name!'),
    address: yup.string(),
    phone: yup.string().required('Please add Phone Number!')
        .matches(/^\d{9,11}$/, 'Your Phone Number is Wrong!'),
})

const CreateNewCustomer = ({ open, setOpen }) => {

    const dispatch = useDispatch()
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(createCustomerSchema)
    })

    const [isopen, setIsOpen] = React.useState(open);

    function handleClose() {
        setIsOpen(false)
        setOpen(false)
        reset();
    }

    const handleOnSubmit = async (data)=>{
        await dispatch(customerCud({ method: 'post', data: data, id: null }))
        await dispatch(getAllCus({ method: 'get', data: null, keyword: "" }))
        handleClose()
    }

    React.useEffect(
        () => {
            setIsOpen(open)
        }, [open]
    )

    return (
        <React.Fragment>
            <Dialog
                open={isopen}
                onClose={handleClose}
            >
                    <DialogContent
                        sx={{ width: 350 }}
                        >
                        <form autoComplete='off' onSubmit={handleSubmit(handleOnSubmit)}>
                            <DialogTitle sx={{ pl: 0, pt: 0 }}>Create New Customer</DialogTitle>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column'
                            }}>
                                <TextField sx={{ mb: 3 }} {...register('name')} error={!!errors?.name} label='Customer Name' helperText={errors?.name?.message}></TextField>
                                <TextField sx={{ mb: 3 }} {...register('address')} error={!!errors?.address} label='Address' helperText={errors?.address?.message}></TextField>
                                <TextField {...register('phone')} error={!!errors?.phone} label='Phone Number' helperText={errors?.phone?.message}></TextField>
                            </Box>
                            <Box sx={{ mt: 3, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                <Button type='button' variant="text" sx={{ mr: 2 }} onClick={handleClose} >Cancel</Button>
                                <Button type='submit' variant="contained">Create</Button>
                            </Box>
                        </form>
                    </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

export default CreateNewCustomer