import React, { useEffect } from 'react'
import { TableContainer, Table, TableHead, TableRow, TableBody, TableCell } from '@mui/material'
import StyledTableData from '../utils/StyledTableData';
import StyledTableHead from '../utils/StyledTableData'
import AppPagination from '../main/filter-components/AppPagination';
import { useDispatch, useSelector } from 'react-redux';
import { getGoldCardSales } from '../../slices/goldCardSlice';
import { changeDateTime } from '../../utils/changeDateTime';
import Loading from '../utils/Loading';
import { useLocation } from 'react-router-dom';
import NoDataAlert from '../utils/NoDataAlert';

const GoldCardSalesList = () => {
    const dispatch = useDispatch()
    const { search } = useLocation()
    const { goldCardSales: goldCardSales , loading } = useSelector(state=> state.GoldCard)

    const fetchData = async ()=>{
        await dispatch(getGoldCardSales())
    }

    useEffect(()=>{
        fetchData()
    },[search])

  return (
        <>
            <TableContainer sx={{ px: 3 }}>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <StyledTableHead align="center">No</StyledTableHead>
                            <StyledTableHead>Date</StyledTableHead>
                            <StyledTableHead>Customer Name</StyledTableHead>
                            <StyledTableHead>Gold Card Code</StyledTableHead>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            goldCardSales && goldCardSales.data && goldCardSales.data.length > 0 ?
                            goldCardSales.data.map((goldCardSale,index)=>
                                <TableRow key={index}>
                                    <StyledTableData align="center">{((goldCardSales?.meta?.current_page - 1) * goldCardSales?.meta?.per_page)+(index + 1)}</StyledTableData>
                                    <StyledTableData>{changeDateTime(goldCardSale?.date)}</StyledTableData>
                                    <StyledTableData>{`${goldCardSale?.customer?.name} (${goldCardSale?.customer?.phone})`}</StyledTableData>
                                    <StyledTableData>{goldCardSale?.code}</StyledTableData>
                                </TableRow>) 
                            :
                            <TableRow>
                                <TableCell colSpan={4}>
                                    <NoDataAlert content={"There is no record for gold card sales."}></NoDataAlert>
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <AppPagination pageCount={goldCardSales?.meta?.last_page}></AppPagination>
        </>
  )
}

export default GoldCardSalesList