import * as React from "react";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Box, Button, Typography } from "@mui/material"
import AsyncSelect from "../utils/AsyncSelect";
import { useDispatch, useSelector } from "react-redux";
import { employeeTrans, getAllStaff } from "../../slices/staffSlice";
import AsyncAutoComplete from "../utils/AsyncAutoComplete";
import { getInfoFromLocal, shopId } from "../../utils/config";
import Loading from "../utils/Loading"

const moveShopSchema = yup.object().shape({
    staff: yup.object().nullable().required('Please select an option!'),
    shop: yup.object().nullable().required('Please select an option!'),
})

const MoveOtherShop = () => {
    const dispatch = useDispatch()
    const [ staff,setStaff ] = React.useState(null)
    const [ staffs,setStaffs ] = React.useState([])
    const [ staffPageNo,setStaffPageNo ] = React.useState(1)
    const { staffs: staffResponse, loading: staffLoading } = useSelector(state=> state.Staff)
    const [ staffSearchQuery,setStaffSearchQuery ] = React.useState("")
    const [ shop,setShop ] = React.useState(null)
    const { shops, loading: shopLoading } = useSelector(state=> state.Shop)
    const [ searchShop,setSearchShop ] = React.useState("")
    const [ filteredShops,setFilteredShops ] = React.useState([])

    const {
        handleSubmit,
        setValue,
        trigger,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(moveShopSchema),
    });

    const fetchStaffs = async () => {
        await dispatch(
          getAllStaff({
            method: "get",
            data: null,
            keyword: staffSearchQuery,
            shop: shopId(),
            scrollPage: staffPageNo,
          })
        );
    };

    const handleOnSubmit = async (data)=>{
        const newData = {
            employees: [
                {
                    id: data.staff.id,
                    shopId: data.shop.id
                }
            ],
            shopId: shopId()
        }
        await dispatch(employeeTrans({ method: "patch", data: newData }))
        fetchStaffs()
        reset()
        setStaff(null)
        setShop(null)
    }

    React.useEffect(()=>{
        if(shops){
            const filtered = shops.data.filter(shop=> shop.id !== shopId())
            setFilteredShops(filtered)
        }
    },[shops])

    React.useEffect(() => {
        if (staffResponse) setStaffs(staffResponse.data);
      }, [staffResponse]);
    
    React.useEffect(() => {
        fetchStaffs();
    }, [staffPageNo,staffSearchQuery]);

    React.useEffect(()=>{
        if(shop){
            setValue('shop', shop)
            trigger('shop')
        }else{
            setValue('shop', null)
        }
    },[shop])

    React.useEffect(()=>{
        if(staff){
            setValue('staff', staff)
            trigger('staff')
        }else{
            setValue('staff', null)
        }
    },[staff])

    // React.useEffect(()=>{
    //     fetchShop()
    // },[searchShop])

    return (
        <Box sx={{ width: 300 }}>
            { staffLoading && <Loading/>}
            <Typography variant='h6' sx={{ fontWeight: 'bold', mb: 2 }}>Employee Transfer</Typography>
            <form autoComplete="off" onSubmit={handleSubmit(handleOnSubmit)} sx={{ position: 'absolute', zIndex: 2 }}>
                <AsyncSelect
                    placeholder={"Select Employee"}
                    loading={staffLoading}
                    value={staff?.name || ""}
                    setValue={setStaff}
                    pageNo={staffPageNo}
                    searchText={staffSearchQuery}
                    options={staffs}
                    setSearchText={setStaffSearchQuery}
                    setPageNo={setStaffPageNo}
                    hasMore={staffPageNo < staffResponse?.meta?.last_page}
                    error={errors.staff}
                    position="absolute"
                ></AsyncSelect>
                <Box sx={{ mb: 2 }}></Box>
                <AsyncAutoComplete size={"small"} setSearchText={setSearchShop} selectedOption={shop} setSelectedOption={setShop} options={filteredShops || []} placeholder={'Select A Shop'} error={errors?.shop} loading={shopLoading}/>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                    <Button type='button' sx={{ mr: 2 }} onClick={()=> reset()}>Cancel</Button>
                    <Button type='submit' variant='contained'>Transfer</Button>
                </Box>
            </form>
        </Box>
    )
}

export default MoveOtherShop