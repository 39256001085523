import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Dialog, DialogContent, DialogTitle ,FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup, Switch, TextField, Typography } from "@mui/material"
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import theme from '../../utils/theme';
import { getAllStaff } from '../../slices/staffSlice';
import AsyncSelect from '../utils/AsyncSelect';
import { getAllServices } from '../../slices/serviceSlice';
import NormalSelect from '../utils/NormalSelect';
import { collectServiceForSale } from '../../slices/receiptSlice';
import { nanoid } from '@reduxjs/toolkit';
import { shopId } from "../../utils/config";


const validationSchema = yup.object().shape({
    service: yup.object().nullable().required('Please Select Service Name!'),
    servicePrice: yup.object().nullable().required("Please select a price!"),
    staff: yup.object().nullable().required("Please select the staff!"),
    status: yup.string().required('Please select an option!'),
    quantity: yup.number().required('Please Add Quantity!').typeError('Your Quantity is Wrong!')
})

const BuyService = ({open,setOpen}) => {
    const dispatch = useDispatch()
    const [ searchTextStaff,setSearchTextStaff ] = React.useState("")
    const [ searchTextService,setSearchTextService ] = React.useState("")
    const [ service, setService ] = React.useState(null);
    const [ services, setServices ] = useState([]);
    const [ status,setStatus ] = useState(null)
    const [ staff, setStaff ] = useState(null)
    const [ staffs,setStaffs ] = useState([])
    const [ prices,setPrices ] = useState([])
    const [ selectedPrice,setSelectedPrice ] = useState(null)
    const [ staffPageNo, setStaffPageNo ] = useState(1);
    const [ servicePageNo, setServicePageNo ] = useState(1);

    const { services: serviceResponse, loading: servicesLoading } = useSelector(
        (state) => state.Service
      );
    const { assistants: staffResponse, loading: staffsLoading } = useSelector(
        (state) => state.Staff
    );
      
    const handleClose = ()=>{
        reset()
        setService(null)
        setStaff(null)
        setStatus(null)
        setStaffPageNo(1)
        setServicePageNo(1)
        setOpen(false)
    }

    const {
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        register,
        trigger,
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const fetchStaffs = async () => {
        await dispatch(
          getAllStaff({
            method: "get",
            data: null,
            shop: shopId(),
            keyword: searchTextStaff,
            scrollPage: staffPageNo,
            isAdvance: false,
            advancedServiceId: null
          })
        );
    };

    const fetchServices = async () => {
        await dispatch(
          getAllServices({
            method: "get",
            data: null,
            keyword: searchTextService,
            scrollPage: servicePageNo,
          })
        );
    };

    const handleOnSubmit = (data)=>{
        const formattedData = {
            id: nanoid(),
            manualDiscount: 0,
            ...data
        }
        dispatch(collectServiceForSale(formattedData))
        handleClose()
    }

    useEffect(()=>{
        if(status){
            setValue('status', status)
            trigger('status')
        }
    },[status])

    useEffect(() => {
        staffResponse &&
        staffResponse.data &&
        setStaffs(staffResponse.data);
    }, [staffResponse]);

    React.useEffect(() => {
        fetchStaffs();
    }, [searchTextStaff, staffPageNo]);

    React.useEffect(() => {
        if(staff) {
          setValue("staff", staff);
          trigger("staff");
        }else if(!staff) {
          setValue("staff", null);
        }
    }, [staff]);


    useEffect(() => {
        serviceResponse &&
        serviceResponse.data &&
        setServices(serviceResponse.data);
    }, [serviceResponse]);

    React.useEffect(() => {
        fetchServices();
    }, [searchTextService, servicePageNo]);

    React.useEffect(() => {
        if(service) {
          setPrices(service.prices)
          setValue("service", service);
          trigger("service");
        }else if(!service) {
          setValue("service", null);
          setPrices([])
        }
        setSelectedPrice(null)
    }, [service]);

    useEffect(()=>{
        if(selectedPrice){
          setValue('servicePrice', selectedPrice)
          trigger('servicePrice')
        }else{
          setValue('servicePrice', null)
          setSelectedPrice(null)
        }
    },[selectedPrice])

    useEffect(()=>{
        setSearchTextService("")
        setSearchTextStaff("")
    },[open])

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogContent sx={{ width: 400 }}>
                <DialogTitle sx={{ pt: 0, pl: 0 }}>Buy Normal Service</DialogTitle>
                <form autoComplete='off' onSubmit={handleSubmit(handleOnSubmit)}>
                    {/* select service */}
                    <AsyncSelect
                        placeholder={"Select Service"}
                        loading={servicesLoading}
                        value={service?.name || ""}
                        setValue={setService}
                        pageNo={servicePageNo}
                        searchText={searchTextService}
                        options={services}
                        setSearchText={setSearchTextService}
                        setPageNo={setServicePageNo}
                        hasMore={servicePageNo < serviceResponse?.meta?.last_page}
                        error={errors?.service}
                        position={'absolute'}
                    ></AsyncSelect>
                    <Box sx={{ mb: 3 }}></Box>
                    {/* select price */}
                    <NormalSelect
                      label={"Select Price"}
                      size={"small"}
                      value={selectedPrice}
                      setValue={setSelectedPrice}
                      options={prices}
                      error={errors.servicePrice}
                      optionLabel={'price'}
                    ></NormalSelect>
                    <Box sx={{ mb: 3 }}></Box>
                    <AsyncSelect
                        placeholder={"Select Staff"}
                        loading={staffsLoading}
                        value={staff?.name || ""}
                        setValue={setStaff}
                        pageNo={staffPageNo}
                        searchText={searchTextStaff}
                        options={staffs}
                        setSearchText={setSearchTextStaff}
                        setPageNo={setStaffPageNo}
                        error={errors?.staff}
                        hasMore={staffPageNo < staffResponse?.meta?.last_page}
                        position='absolute'
                    ></AsyncSelect>
                    <FormControl sx={{ my: 2 }}>
                        <RadioGroup
                            value={status}
                            onClick={(e)=>{
                                if(e.target.value){
                                    setValue("status", e.target.value)
                                    setStatus(e.target.value)
                                }
                            }}
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                        >
                            <FormControlLabel value="normal" control={<Radio />} label={<Typography sx={{ fontSize: '12px' }}>Normal</Typography>} />
                            <FormControlLabel value="byname" control={<Radio />} label={<Typography sx={{ fontSize: '12px' }}>Byname</Typography>} />
                        </RadioGroup>
                        <FormHelperText sx={{ color: theme.palette.danger.main }}>{errors?.status?.message}</FormHelperText>
                    </FormControl>
                    <TextField
                        fullWidth
                        size='small'
                        label={'Quantity'}
                        {...register('quantity')}
                        error={!!errors.quantity}
                        helperText={errors?.quantity?.message}
                    ></TextField>
                    <Box sx={{ mt: 3, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <Button type='button' variant="text" sx={{ mr: 2 }} onClick={handleClose} >Cancel</Button>
                        <Button type='submit' variant="contained">Buy</Button>
                    </Box>
                </form>
            </DialogContent>
        </Dialog>
    )
}

export default BuyService