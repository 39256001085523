import { useNavigate, useLocation } from 'react-router-dom';
import { Box, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import theme from '../../utils/theme';
import React from 'react';

const SideBarItem = ({ section, isExpandable, isOpen }) => {
    const location = useLocation();
    const path = location.pathname;
    const navigate = useNavigate();

    const parts = path.split('/');
    let lastPart;
    if (parts[3]) {
        lastPart = parts[2] + '/' + parts[3];
    } else {
        lastPart = parts[parts.length - 1];
    }

    const handleOnClick = (link) => {
        navigate(`/${link}`);
    };

    return (
        <ListItem sx={{ px: 0 }} key={section.title}>
            <ListItemButton onClick={() => handleOnClick(section.link)}
                sx={ lastPart === section.link || lastPart === section.link2 || lastPart === section.link3 ? { borderLeft: '3px solid red' } : {}}
            >
                <ListItemIcon
                    sx={(lastPart === section.link || lastPart === section.link2 || lastPart === section.link3) && !isOpen
                    ? { color: theme.palette.primary.main }
                    : {}}
                >{section.icon}</ListItemIcon>
                <ListItemText
                    sx={(lastPart === section.link || lastPart === section.link2 || lastPart === section.link3) && !isOpen
                        ? { color: theme.palette.primary.main, fontWeight: 'bold', fontFamily: 'Poppins' }
                        : { fontFamily: 'Poppins' }}
                    primary={section.title}
                ></ListItemText>
            </ListItemButton>
        </ListItem>
    );
};

export default SideBarItem;
