import React, { useEffect, useState } from 'react'
import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    IconButton
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from 'react-router-dom'
import StyledTableHead from "../components/utils/StyledTableHead";
import StyledTableData from "../components/utils/StyledTableData";
import NoDataAlert from "../components/utils/NoDataAlert";
import DriveFileRenameOutline from "@mui/icons-material/DriveFileRenameOutline";
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import theme from '../utils/theme';
import CUBeauServiceByEmployee from '../components/beautician_service_components/CUBeauServiceByEmployee';
import { beauticianServiceByEmployeeCrud } from '../slices/serviceSlice';
import Loading from '../components/utils/Loading'
import AlertDialog from '../components/utils/AlertDialog';
import GoToBackButton from '../components/utils/GoToBackButton';

const BeauServiceByEmployee = () => {
  const { state } = useLocation();
  const dispatch = useDispatch()
  const { id: employeeId } = useParams();
  const [ serviceToArchive,setServiceToArchive ] = useState(null)
  const [ open,setOpen ] = useState(false)
  const [ showAlert, setShowAlert ] = useState(false)
  const [ beauticianServices,setBeauticianServices ] = useState([])
  const [ serviceDetail, setServiceDetail ] = useState(null)
  const { beauticianServicesByEmployee, loading } = useSelector(state=> state.Service)

  const fetchData = async ()=>{
    await dispatch(beauticianServiceByEmployeeCrud({ method: 'get', data: null, employeeId: employeeId, isDisable: null}))
  }

  const handleCancel = ()=>{
    setShowAlert(false)
  }

  const handleConfirm = async ()=>{
    await dispatch(beauticianServiceByEmployeeCrud({ method: serviceToArchive?.isDisable ? 'patch' : 'delete', data: null, employeeId: employeeId, serviceId: serviceToArchive.id , isDisable: null}))
    setShowAlert(false)
    fetchData()
  }

  useEffect(()=>{
    if(beauticianServicesByEmployee && beauticianServicesByEmployee.data) setBeauticianServices(beauticianServicesByEmployee.data)
  },[beauticianServicesByEmployee])

  useEffect(()=>{
    setBeauticianServices([])
    fetchData()
  },[])

  return (
    <Box
      sx={{
        bgcolor: theme.palette.common.white,
        borderRadius: "10px",
        height: "100%",
        pb: 3
      }}
    >
      <CUBeauServiceByEmployee open={open} setOpen={setOpen} serviceDetail={serviceDetail}></CUBeauServiceByEmployee>
      { loading && !beauticianServicesByEmployee && <Loading/> }
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          p: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Button onClick={() =>{
              setServiceDetail(null)
              setOpen(true)
            }} variant="contained" sx={{ height: '100%' }}>
            Add New
          </Button>
        </Box>
      </Box>
      <TableContainer sx={{ px: 3 }}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <StyledTableHead align="center">No</StyledTableHead>
              <StyledTableHead>Service Name</StyledTableHead>
              {
                state?.isAdvance &&
                <StyledTableHead>Price</StyledTableHead>
              }
              <StyledTableHead>Normal Commission</StyledTableHead>
              <StyledTableHead>Byname Commission</StyledTableHead>
              <StyledTableHead sx={{ textAlign: "center" }}>
                Actions
              </StyledTableHead>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              beauticianServices.length > 0 ?
              beauticianServices.map((service,index)=>
                <TableRow key={index}>
                  <AlertDialog
                      toggle={showAlert}
                      setToggle={setShowAlert}
                      cancel={handleCancel}
                      confrim={handleConfirm}
                      title={"Are you sure?"}
                      content={`You want to ${service.isDisable ? "restore" : "archive"} this service.`}
                  ></AlertDialog>
                  <StyledTableData align="center">
                    {index + 1}
                  </StyledTableData>
                  <StyledTableData>{service.name}</StyledTableData>
                  {
                    state?.isAdvance &&
                    <StyledTableData>{service.price ? Number(service.price).toLocaleString() : "-"}</StyledTableData>
                  }
                  <StyledTableData>{service.normalCommissionValue} {service.normalCommissionType === 'percent' ? "%" : "MMK"}</StyledTableData>
                  <StyledTableData>{service.bynameCommissionValue} {service.bynameCommissionType === 'percent' ? "%" : "MMK"}</StyledTableData>
                  <StyledTableData align='center'>
                    <Tooltip title={"Edit"}>
                      <IconButton
                          sx={{ mr: 2 }}
                          onClick={() => {
                              setServiceDetail(service)
                              setOpen(true)
                          }}
                      >
                      <DriveFileRenameOutline
                          sx={{ color: theme.palette.warning.main }}
                      />
                      </IconButton>
                  </Tooltip>
                  <Tooltip title={service.isDisable ? "Restore" : "Archive"}>
                    <IconButton onClick={()=>{
                      setShowAlert(true)
                      setServiceToArchive(service)
                    }}>
                      {
                        service.isDisable ?
                        <UnarchiveIcon sx={{ color: theme.palette.info.main }}/>
                        :
                        <ArchiveIcon
                          sx={{ color: theme.palette.primary.main }}
                        />
                      }
                    </IconButton>
                  </Tooltip>
                  </StyledTableData>
              </TableRow>
              ):
              <TableRow>
                <TableCell colSpan={9}>
                  <NoDataAlert content={"There is no beautician's service"}></NoDataAlert>
                </TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>
      <GoToBackButton/>
    </Box>
  )
}

export default BeauServiceByEmployee
