import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL, HEADERS, getInfoFromLocal } from "../utils/config";
import axios from "axios";
import { ShowToast } from "../components/utils/ShowToast";

const initialState = {
    dailyCommission: null,
    beauticians: null,
    assistants: null,
    staffToMove: [],
    staffDetail: null,
    staffs: null,
    totalSaving: null,
    loading: false,
    data: null,
    error: null
}

export const getAllStaff = createAsyncThunk(
    'staff/getAllStaff',
    async ({ method,data,keyword,shop,scrollPage,isAdvance,advancedServiceId }) => {
        const shop_id = getInfoFromLocal().user.shopId
        const searchParams = new URLSearchParams(window.location.search);
        const pageNo = searchParams.get('page');
        const searchKeyword = searchParams.get('keyword')
        try {
            const config = {
                method: method,
                url: `${BASE_URL}/employees?name=${keyword || searchKeyword || ""}&page=${scrollPage || pageNo}&shopId=${shop || shop_id}&isAdvanced=${isAdvance}${advancedServiceId ? `&advancedServiceId=${advancedServiceId}` : ""}`,
                headers: HEADERS(),
                data: data
            };
            const response = await axios(config);
            return { data: response.data, isAdvance };
        } catch (error) {
            if(error.code === "ERR_NETWORK"){
                ShowToast('error', error.message)
            }else{
                ShowToast('error', "Something went wrong")
            }
            throw new Error(error);
        }
    }
);

export const getStaffDetail = createAsyncThunk(
    'staff/getStaffDetail',
    async ({ method,date,shop, id }) => {
        const shop_id = getInfoFromLocal().user.shopId
        try {
            const config = {
                method: method,
                url: `${BASE_URL}/employees/${id ? id: ""}?shopId=${shop ? shop : shop_id}${date ? `&date=${date}` : '' }`,
                headers: HEADERS(),
                data: null
            };
            const response = await axios(config);
            return response.data;
        } catch (error) {
            ShowToast('error', 'Something went wrong')
            throw new Error(error);
        }
    }
);


export const staffCud = createAsyncThunk(
    'staff/staffCud',
    async ({ method,data,id, isAdvance }) => {
        const shop_id = getInfoFromLocal().user.shopId
        try {
            const config = {
                method: method,
                url: `${BASE_URL}/employees${id ? `/${id}` : ''}?shopId=${shop_id}${isAdvance ? `&isAdvanced=${isAdvance}` : ``}`,
                headers: HEADERS(),
                data: data
            };
            const response = await axios(config);
            if(method === 'post'){
                ShowToast('success', 'Successfully created')
            }else if(method === 'put'){
                ShowToast('success', 'Successfully updated')
            }else if(method === 'delete'){
                ShowToast('success', 'Successfully deleted')
            }
            return response.data;
        } catch (error){
            console.log(error)
            ShowToast('error', 'Something went wrong')
            throw new Error(error);
        }
    }
);

export const employeeTrans = createAsyncThunk(
    'staff/employeeTrans',
    async ({ method,data }) => {
        try {
            const config = {
                method: method,
                url: `${BASE_URL}/employees/move-employees`,
                headers: HEADERS(),
                data: data
            };
            const response = await axios(config);
            if(method === 'patch'){
                ShowToast('success', 'Successfully transfered')
            }
            return response.data;
        } catch (error) {
            console.log(error)
            if(error.status === '403'){
                ShowToast('error', 'Access denied!')
            }else{
                ShowToast('error', 'Something went wrong!')
            }
            throw new Error(error);
        }
    }
);

export const staffTotalSaving = createAsyncThunk(
    'staff/totalSaving',
    async ({ method,data, id }) => {
        const shop_id = getInfoFromLocal().user.shopId
        try {
            const config = {
                method: method,
                url: `${BASE_URL}/employees/${id}/total-savings?shopId=${shop_id}`,
                headers: HEADERS(),
                data: data
            };
            const response = await axios(config);
            return response.data;
        } catch (error) {
            throw new Error(error);
        }
    }
)

const staffSlice = createSlice({
    name: "staff",
    initialState,
    reducers: {
        getDailyCommission: (state,action)=>{
            const total = action.payload.reduce((acc, currentItem) => acc + Number(currentItem.commission), 0);
            state.dailyCommission = total;
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getAllStaff.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(getAllStaff.fulfilled, (state, action) => {
            state.loading = false;
            if (action.payload.isAdvance === true) {
                state.beauticians = action.payload.data;
            }else if(action.payload.isAdvance === false) {
                state.assistants = action.payload.data
            }else{
                state.staffs = action.payload.data;
            }
        })
        .addCase(getAllStaff.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })
        .addCase(getStaffDetail.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(getStaffDetail.fulfilled, (state, action) => {
            state.loading = false;
            state.staffDetail = action.payload;
        })
        .addCase(getStaffDetail.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })
        .addCase(staffCud.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(staffCud.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        })
        .addCase(staffCud.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })
        .addCase(employeeTrans.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(employeeTrans.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        })
        .addCase(employeeTrans.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })
        .addCase(staffTotalSaving.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(staffTotalSaving.fulfilled, (state, action) => {
            state.loading = false;
            state.totalSaving = action.payload;
        })
        .addCase(staffTotalSaving.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })
    }
})

export const { getDailyCommission } = staffSlice.actions

export default staffSlice.reducer;