import { Box, Fab, Tooltip } from '@mui/material';
import React from 'react';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import { useNavigate } from 'react-router-dom';

const GoToBackButton = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Box sx={{ position: 'fixed', bottom: 40, right: 50 }}>
      <Tooltip title="Go Back">
        <Fab onClick={handleGoBack} size="medium" color="primary" aria-label="go-back">
          <FirstPageIcon />
        </Fab>
      </Tooltip>
    </Box>
  );
};

export default GoToBackButton;
