import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useEffect } from 'react'
import SearchInput from '../main/filter-components/SearchInput'
import StyledTableHead from '../utils/StyledTableHead'
import NoDataAlert from '../utils/NoDataAlert'
import DateFilters from '../main/filter-components/DateFilters'
import AppPagination from '../main/filter-components/AppPagination'
import StyledTableData from '../utils/StyledTableData'
import { useDispatch, useSelector } from "react-redux";
import { stockTransfer } from '../../slices/itemSlice'
import { useLocation } from 'react-router-dom'
import Loading from '../utils/Loading'
import { changeDate } from '../../utils/changeDateTime'

const ItemTransferLog = () => {
    const { search } = useLocation()
    const dispatch = useDispatch()
    const { transferedLogs, loading } = useSelector(state=> state.Item)

    const fetchData = async()=>{
        await dispatch(stockTransfer({ method: 'get', data: null }))
    }

    useEffect(()=>{
        fetchData()
    },[search])

  return (
    <Grid container spacing={2} sx={{ mt: 1 }}>
            {
                loading && <Loading></Loading>
            }
            <Grid item sm={12}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    px: 4, pt: 1
                }}>
                    <DateFilters></DateFilters>
                    <SearchInput placeholderText={"Search by item name"}/>
                </Box>
                <TableContainer sx={{ px: 3, mt: 3 }}>
                    <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                            <TableRow>
                                <StyledTableHead align="center">No</StyledTableHead>
                                <StyledTableHead>Date & Time</StyledTableHead>
                                <StyledTableHead>Item Name</StyledTableHead>
                                <StyledTableHead>Item Code</StyledTableHead>
                                <StyledTableHead>From</StyledTableHead>
                                <StyledTableHead>To</StyledTableHead>
                                <StyledTableHead align="center">Quantity</StyledTableHead>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                transferedLogs && transferedLogs.data && transferedLogs.data.length > 0 ?
                                transferedLogs.data.map((log,index)=>
                                    <TableRow key={index}>
                                        <StyledTableData align="center">{((transferedLogs?.meta?.current_page - 1) * transferedLogs?.meta?.per_page)+(index + 1)}</StyledTableData>
                                        <StyledTableData>{changeDate(log.date)}</StyledTableData>
                                        <StyledTableData>{log.item}</StyledTableData>
                                        <StyledTableData>{log.itemCode}</StyledTableData>
                                        <StyledTableData>{log.sender}</StyledTableData>
                                        <StyledTableData>{log.receiver}</StyledTableData>
                                        <StyledTableData align="center">{log.quantity}</StyledTableData>
                                    </TableRow>
                                )
                                :
                                <TableRow>
                                    <TableCell colSpan={7}>
                                        <NoDataAlert content={"There is no log for stock transfer"}></NoDataAlert>
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <AppPagination pageCount={transferedLogs?.meta?.last_page}/>
            </Grid>
        </Grid>
  )
}

export default ItemTransferLog
