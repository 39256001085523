import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import NoDataAlert from '../utils/NoDataAlert'
import { useLocation } from 'react-router-dom'

const ItemSaleTable = ({ saleRecords }) => {
    const { pathname } = useLocation()
    const [ isStaffDetail,setIsStaffDetail ] = useState(false)
    useEffect(()=>{
        if(pathname.includes('staff-detail')){
            setIsStaffDetail(true)
        }else{
            setIsStaffDetail(false)
        }
    },[pathname])
  return (
    <div style={{ pageBreakAfter: "always" }}>
        <Typography sx={{ pl: 2, fontWeight: 'bold' }}>Item Sale Records</Typography>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontSize: '12px' }} align="center">No</TableCell>
                            {/* <TableCell sx={{ fontSize: '12px' }}>Date</TableCell> */}
                            <TableCell sx={{ fontSize: '12px' }}>Item Name</TableCell>
                            {
                                isStaffDetail === false && <TableCell sx={{ fontSize: '12px' }}>Staffs</TableCell>
                            }
                            <TableCell sx={{ fontSize: '12px' }} align="center">Price</TableCell>
                            <TableCell sx={{ fontSize: '12px' }} align="center">Qty</TableCell>
                            {
                                isStaffDetail === false &&
                                <>
                                    <TableCell sx={{ fontSize: '12px' }} align="center">Discount</TableCell>
                                    <TableCell sx={{ fontSize: '12px' }} align="center">Manual Discount</TableCell>
                                </>
                            }
                            {
                                isStaffDetail === true &&
                                <TableCell sx={{ fontSize: '12px' }} align="center">Commission</TableCell>
                            }
                            <TableCell sx={{ fontSize: '12px' }} align="center">Total</TableCell>                        
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            saleRecords && saleRecords.length > 0 ?
                            saleRecords.map((rec,index)=>
                            <TableRow key={index}>
                                <TableCell sx={{ fontSize: '12px' }} align="center">{index+ 1}</TableCell>
                                {/* <TableCell sx={{ fontSize: '12px' }} sx={{ whiteSpace: 'nowrap' }}>{changeDate(rec.date)}</TableCell> */}
                                <TableCell sx={{ fontSize: '12px' }}>{rec.itemName}</TableCell>
                                {
                                    isStaffDetail === false &&
                                    <TableCell sx={{ fontSize: '12px' }}>{rec.employees && rec.employees.length > 0 && rec.employees.map(el=> `${el.name}${el.status ? ` (${el.status}- ${el.commission} MMK)` : ` (${el.commission} MMK)`}, ` )}</TableCell>
                                }
                                <TableCell sx={{ fontSize: '12px' }} align="center">{rec.originalTotalPrice}</TableCell>
                                <TableCell sx={{ fontSize: '12px' }} align="center">{rec.quantity}</TableCell>
                                {
                                    isStaffDetail === false &&
                                    <>
                                        <TableCell sx={{ fontSize: '12px' }} align="center">{rec.seasonalDiscountPercent ? `Seasonal discount - ${rec.seasonalDiscountPercent}%` : rec.goldCardDiscountPercent ? `Gold Card - ${rec.goldCardDiscountPercent}%` : "-"}</TableCell>
                                        <TableCell sx={{ fontSize: '12px' }} align="center">{rec.manualDiscountPrice}</TableCell>
                                    </>
                                }
                                {
                                    isStaffDetail === true &&
                                    <TableCell sx={{ fontSize: '12px' }} align="center">{rec.commission} MMK</TableCell>
                                }
                                <TableCell sx={{ fontSize: '12px' }} align="center">{rec.totalPrice}</TableCell>      
                            </TableRow>
                            )                            
                            :
                            <TableRow>
                                <TableCell sx={{ fontSize: '12px' }} colSpan={8}>
                                    <NoDataAlert content={"There is no records"}></NoDataAlert>
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
    </div>
  )
}

export default ItemSaleTable
