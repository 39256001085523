import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { getAllStaff } from '../../slices/staffSlice';
import theme from '../../utils/theme';
import ReactDatePicker from 'react-datepicker';
import { format } from 'date-fns';
import { shopId } from '../../utils/config';
import { salaryAdjust } from '../../slices/salarySlice';
import AsyncSelect from '../utils/AsyncSelect';

const validationSchema = yup.object().shape({
  employee: yup.object().nullable().required('Please Select Staff!'),
  date: yup.string().nullable().required('Please Select Date!'),
  penaltyFee: yup.number().required('Please Add Penalty Fee!').typeError('Your fee is Wrong!'),
  attendanceBonus: yup.boolean().required('Please select an option'),
});

const SalaryAdjustDialog = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const [ searchTextStaff,setSearchTextStaff ] = React.useState("")
  const [ staff, setStaff ] = useState(null)
  const [ staffs,setStaffs ] = useState([])
  const [ staffPageNo, setStaffPageNo ] = useState(1);
  const [selectedDate, setSelectedDate] = useState(null);
  const { staffs: staffResponse, loading: staffsLoading } = useSelector(
    (state) => state.Staff
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    trigger,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const [isopen, setIsOpen] = useState(open);

  const fetchStaffs = async () => {
    await dispatch(
      getAllStaff({
        method: "get",
        data: null,
        keyword: searchTextStaff,
        scrollPage: staffPageNo,
      })
    );
};

  const handleClose = () => {
    setOpen(false);
    reset()
    setSelectedDate(null)
    setStaff(null)
    setStaffPageNo(1)
    setIsOpen(false);
  };

  const onSubmit = async (data) => {
    const newData = {
        attendanceBonus: data.attendanceBonus,
        date: data.date,
        employeeId: data.employee.id,
        penaltyFee: data.penaltyFee,
        shopId: shopId()
    }
    await dispatch(salaryAdjust({ method: 'post', data: newData }))
    handleClose()
  };


  useEffect(() => {
    staffResponse &&
    staffResponse.data &&
    setStaffs(staffResponse.data);
  }, [staffResponse]);

  React.useEffect(() => {
      fetchStaffs();
  }, [searchTextStaff, staffPageNo]);

  
  React.useEffect(() => {
    if(staff) {
      setValue("employee", staff);
      trigger("employee");
    }else if(!staff) {
      setValue("employee", null);
    }
}, [staff]);

  useEffect(() => {
    fetchStaffs();
    setIsOpen(open);
  }, [open]);

  return (
    <React.Fragment>
      <Dialog open={isopen} onClose={handleClose}>
          <DialogContent sx={{ width: 400 }}>
            <DialogTitle sx={{ pl: 0, pt: 0 }}>Penalty Fee & Bonus</DialogTitle>
              <form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
                <AsyncSelect
                  placeholder={"Select Staff"}
                  loading={staffsLoading}
                  value={staff?.name || ""}
                  setValue={setStaff}
                  pageNo={staffPageNo}
                  searchText={searchTextStaff}
                  options={staffs}
                  setSearchText={setSearchTextStaff}
                  setPageNo={setStaffPageNo}
                  error={errors?.staff}
                  hasMore={staffPageNo < staffResponse?.meta?.last_page}
                  position='absolute'
                ></AsyncSelect>
                <TextField
                  fullWidth
                  sx={{ my: 3 }}
                  {...register('penaltyFee')}
                  error={!!errors?.penaltyFee}
                  placeholder="PenaltyFee"
                  helperText={errors?.penaltyFee?.message}
                />
                <ReactDatePicker
                  showMonthYearPicker
                  isClearable
                  placeholderText="Select Month"
                  dateFormat="MM-yyyy"
                  selected={selectedDate}
                  className={errors.date ? 'customDatePicker1 customDatePickerError' : 'customDatePicker1'}
                  onChange={(date) => {
                      if (date instanceof Date && !isNaN(date)) {
                          const formattedDate = format(date, 'yyyy-MM-dd');
                          setSelectedDate(date);
                          setValue('date', formattedDate);
                      }else{
                          setSelectedDate(null);
                          setValue('date', null);
                      }
                  }}
                />
                <FormHelperText sx={{ color: theme.palette.danger.main }}>{errors.date?.message}</FormHelperText>
                <FormControl sx={{ mt: 2 }} error={errors.attendanceBonus} variant="standard">
                  <FormLabel id="demo-error-radios">Attendance Bonus</FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-error-radios"
                    name="quiz"
                    value={getValues('attendanceBonus') || null}
                    onChange={(event) => {
                      setValue('attendanceBonus', event.target.value);
                      trigger('attendanceBonus');
                    }}
                  >
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                    <FormControlLabel value={false} control={<Radio />} label="No!" />
                  </RadioGroup>
                </FormControl>
                <FormHelperText sx={{ color: theme.palette.danger.main }}>{errors.attendanceBonus?.message}</FormHelperText>
                <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Button type='button' variant="text" sx={{ mr: 2 }} onClick={handleClose} >Cancel</Button>
                    <Button type='submit' variant="contained">Confirm</Button>
                </Box>
              </form>
          </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default SalaryAdjustDialog;
