import React, { useState, useEffect } from 'react';
import { Box, Button, Dialog, DialogContent, DialogTitle, TextField } from "@mui/material"
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { getAllStaff } from '../../slices/staffSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getAllItem } from '../../slices/itemSlice';
import { nanoid } from '@reduxjs/toolkit';
import { collectItemForSale } from '../../slices/receiptSlice';
import AsyncMultiSelect from '../utils/AsyncMultiSelect';
import AsyncSelect from '../utils/AsyncSelect';
import { shopId } from "../../utils/config";

const itemSchema = yup.object().shape({
    item: yup.object().nullable().required('Please Select Item Name!'),
    staff: yup.array().of(yup.object()).typeError("Please select at least one staff!"),
    quantity: yup.number().required('Please Add Quantity!').typeError('Your Quantity is Wrong!')
})

const BuyItem = ({ open,setOpen }) => {
    const dispatch = useDispatch()
    const { staffs: staffsResponse, loading: staffsLoading } = useSelector(
        (state) => state.Staff
    );
    const { items: itemsResponse, loading: itemsLoading } = useSelector(
        (state) => state.Item
    );
    const [ searchTextItem,setSearchTextItem ] = React.useState("")
    const [ searchTextStaff,setSearchTextStaff ] = React.useState("")
    const [ staffs, setStaffs ] = useState([]);
    const [staff, setStaff] = useState(null)
    const [items, setItems] = useState([])
    const [item, setItem] = useState(null)
    const [staffPageNo, setStaffPageNo] = useState(1);
    const [itemPageNo, setItemPageNo] = useState(1);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        trigger,
    } = useForm({
        resolver: yupResolver(itemSchema),
    });

    const handleClose = ()=>{
        setOpen(false)
        setStaff(null);
        setStaffPageNo(1)
        setItem(null)
        setItemPageNo(1)
        reset()
    }

    const fetchStaff = async () => {
        await dispatch(
          getAllStaff({
            method: "get",
            data: null,
            shop: shopId(),
            keyword: searchTextStaff,
            scrollPage: staffPageNo,
        })
        );
      };

    const fetchItem = async () => {
        await dispatch(
          getAllItem({
            method: "get",
            data: null,
            keyword: searchTextItem,
            scrollPage: itemPageNo,
            stock: true
          })
        );
    };

    const handleOnSubmit = async (data) => {
        // const employees = [];
        // data.staff.forEach((el, i) => {
        //   employees.push({id: el.id});
        // });
        const formattedData = {
            id: nanoid(),
            manualDiscount: 0,
            ...data
        }
        dispatch(collectItemForSale(formattedData))
        handleClose()
    }

    useEffect(() => {
        staffsResponse &&
        staffsResponse.data &&
        setStaffs(staffsResponse.data);
    }, [staffsResponse]);

    useEffect(() => {
        itemsResponse &&
        itemsResponse.data &&
        setItems(itemsResponse.data);
    }, [itemsResponse]);

    React.useEffect(() => {
        fetchStaff();
    }, [searchTextStaff, staffPageNo]);

    React.useEffect(() => {
        fetchItem();
    }, [searchTextItem, itemPageNo]);

    React.useEffect(() => {
        if (staff) {
          setValue("staff", staff);
          trigger("staff");
        } else {
          setValue("staff", []);
        }
    }, [staff]);

    useEffect(()=>{
        if(item){
            setValue('item', item)
            trigger('item')
        }
    },[item])


    React.useEffect(() => {
        setStaff([])
        setSearchTextStaff("")
        setStaffs([])
        setItem(null)
        setSearchTextItem("")
        setItems([])
        fetchStaff();
        fetchItem()
    }, [open]);

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogContent sx={{ width: 400 }}>
                <DialogTitle sx={{ pt: 0, pl: 0 }}>Buy Item</DialogTitle>
                <form autoComplete='off' onSubmit={handleSubmit(handleOnSubmit)}>
                    {/* select item */}
                    <AsyncSelect
                        placeholder={"Select Item"}
                        loading={itemsLoading}
                        value={item?.name || ""}
                        setValue={setItem}
                        pageNo={itemPageNo}
                        searchText={searchTextItem}
                        options={items}
                        setSearchText={setSearchTextItem}
                        setPageNo={setItemPageNo}
                        hasMore={itemPageNo < itemsResponse?.meta?.last_page}
                        position='absolute'
                        error={errors?.item}
                    ></AsyncSelect>
                    <Box sx={{ mt: 3 }}></Box>
                    {/* select staffs */}
                    <AsyncMultiSelect
                        placeholder={"Select Staff"}
                        loading={staffsLoading}
                        values={staff || []}
                        setValues={setStaff}
                        pageNo={staffPageNo}
                        searchText={searchTextStaff}
                        options={staffs}
                        setSearchText={setSearchTextStaff}
                        setPageNo={setStaffPageNo}
                        hasMore={staffPageNo < staffsResponse?.meta?.last_page}
                        error={errors?.staff}
                        position={'relative'}
                    ></AsyncMultiSelect>
                    <TextField
                        size='small'
                        fullWidth
                        {...register('quantity')}
                        error={!!errors?.quantity}
                        helperText={errors?.quantity?.message}
                        sx={{ my: 3 }}
                        placeholder="Quantity"
                    />
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <Button type='button' variant="text" sx={{ mr: 2 }} onClick={handleClose} >Cancel</Button>
                        <Button type='submit' variant="contained">Buy</Button>
                    </Box>
                </form>
            </DialogContent>
        </Dialog>
    )
}

export default BuyItem