import { Box, Paper, Tooltip, IconButton, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import theme from '../utils/theme'
import DriveFileRenameOutline from "@mui/icons-material/DriveFileRenameOutline";
import GoldCardSalesList from '../components/create_sale_voucher_components/GoldCardSalesList';
import SearchInput from '../components/main/filter-components/SearchInput';
import { useSelector } from "react-redux";
import UpdateGoldCardInfo from '../components/gold_card_components/UpdateGoldCardInfo';
import Loading from '../components/utils/Loading';

const GoldCard = () => {
  const { goldCardInfo, loading } = useSelector(state=> state.GoldCard)
  const [ openEditGoldCardInfo,setOpenEditGoldCardInfo ] = useState(false)
  const [ goldCard,setGoldCard ] = useState(null)

  useEffect(()=>{
    if(goldCardInfo) setGoldCard(goldCardInfo[0])
  },[goldCardInfo])

  return (
      <Box sx={{
        bgcolor: theme.palette.common.white,
        borderRadius: '10px',
        p: 3,
        height: '100%'
      }}>
          {
            loading && <Loading/>
          }
          <UpdateGoldCardInfo open={openEditGoldCardInfo} setOpen={setOpenEditGoldCardInfo} info={goldCard}></UpdateGoldCardInfo>
          <Box>
              <Paper elevation={3} sx={{ ml: 0, p: 2, m: 1, textAlign: 'center', minWidth: 150, display: 'inline-block', alignItems: 'center', justifyContent: 'center' }}>
                <Typography sx={{ whiteSpace: 'nowrap' }}>Price - {goldCard?.price ? Number(goldCard.price).toLocaleString() : 0} MMK</Typography>
              </Paper>
              <Paper elevation={3} sx={{ p: 2, m: 1, textAlign: 'center', minWidth: 150, display: 'inline-block', alignItems: 'center', justifyContent: 'center' }}>
                <Typography sx={{ whiteSpace: 'nowrap' }}>Commission - {goldCard?.commission || 0} %</Typography>
              </Paper>
              <Paper elevation={3} sx={{ p: 2, m: 1, textAlign: 'center', minWidth: 150, display: 'inline-block', alignItems: 'center', justifyContent: 'center' }}>
                <Typography sx={{ whiteSpace: 'nowrap' }}>Item Discount - {goldCard?.itemDiscountPercent || 0} % </Typography>
              </Paper>
              <Paper elevation={3} sx={{ p: 2, m: 1, textAlign: 'center', minWidth: 150, display: 'inline-block', alignItems: 'center', justifyContent: 'center' }}>
                <Typography sx={{ whiteSpace: 'nowrap' }}>Service Discount - {goldCard?.advancedServiceDiscountPercent || 0} %</Typography>
              </Paper>
              <Paper elevation={3} sx={{ p: 1, m: 1, textAlign: 'center', display: 'inline-block', minWidth: 100 }}>
                <Tooltip title="Update">
                  <IconButton onClick={()=>{
                    setOpenEditGoldCardInfo(true)                         
                  }}>
                      <DriveFileRenameOutline sx={{ color: theme.palette.warning.main }} />
                  </IconButton>
                </Tooltip>
              </Paper>
          </Box>
          <Box sx={{ my: 3, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            {/* <Button onClick={()=> setOpenBuy(true)} variant="contained">Buy Gold Card</Button> */}
            <SearchInput placeholderText={"Search By Customer Name"}></SearchInput>
          </Box>
          <GoldCardSalesList></GoldCardSalesList>
      </Box>
  )
}

export default GoldCard
