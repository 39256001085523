import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL, HEADERS } from "../utils/config";
import axios from "axios";
import { ShowToast } from "../components/utils/ShowToast";
import { shopId } from "../utils/config";

const initialState = {
    services: null,
    beauticianServices: null,
    beauticianServicesByEmployee: null,
    loading: false,
    data: null,
    error: null
}

export const getAllServices = createAsyncThunk(
    'service/getAllServices',
    async ({ method,data,keyword, scrollPage }) => {
        const searchParams = new URLSearchParams(window.location.search);
        const pageNo = searchParams.get('page');    
        const searchKeyword = searchParams.get('keyword')
        try {
            const config = {
                method: method,
                url: `${BASE_URL}/services?name=${keyword || searchKeyword || ""}&page=${scrollPage || pageNo}`,
                headers: HEADERS(),
                data: data
            };
            const response = await axios(config);
            return response.data;
        } catch (error) {
            if(error.code === "ERR_NETWORK"){
                ShowToast('error', error.message)
            }else{
                ShowToast('error', "Something went wrong")
            }
            throw new Error(error);
        }
    }
);

export const serviceCud = createAsyncThunk(
    'service/serviceCud',
    async ({ method,data,id }) => {
        try {
            const config = {
                method: method,
                url: `${BASE_URL}/services${id ? `/${id}` : ''}`,
                headers: HEADERS(),
                data: data
            };
            const response = await axios(config);
            if(method === 'post'){
                ShowToast('success', 'Successfully created')
            }else if(method === 'put'){
                ShowToast('success', 'Successfully updated')
            }else if(method === 'delete'){
                ShowToast('success', 'Successfully deleted')
            }
            return response.data;
        } catch (error) {
            ShowToast('error', 'Something went wrong')
            throw new Error(error);
        }
    }
);

export const beauticianServiceCrud = createAsyncThunk(
    'service/beauticianServiceCrud',
    async ({ method,data,id,employeeId, notHave, scrollPage }) => {
        const searchParams = new URLSearchParams(window.location.search);
        const pageNo = searchParams.get('page');
        try {
            const config = {
                method: method,
                url: `${BASE_URL}/advanced-services${id ? `/${id}` : ''}?${scrollPage ? `&page=${scrollPage}` : `&page=${pageNo || 1}`}${employeeId ? `&employeeId=${employeeId}` : ``}${ notHave === true ? `&notHave=${notHave}` : ``}`,
                headers: HEADERS(),
                data: data
            };
            const response = await axios(config);
            if(method === 'post'){
                ShowToast('success', 'Successfully created')
            }else if(method === 'put'){
                ShowToast('success', 'Successfully updated')
            }else if(method === 'delete'){
                ShowToast('success', 'Successfully deleted')
            }
            return response.data;
        } catch (error) {
            ShowToast('error', 'Something went wrong')
            throw new Error(error);
        }
    }
);

export const priceCrud = createAsyncThunk(
    'service/priceCrud',
    async ({ method,data,priceId }) => {
        try {
            const config = {
                method: method,
                url: `${BASE_URL}/service-prices${priceId ? `/${priceId}` : ''}`,
                headers: HEADERS(),
                data: data
            };
            const response = await axios(config);
            if(method === 'post'){
                ShowToast('success', 'Successfully created')
            }else if(method === 'put'){
                ShowToast('success', 'Successfully updated')
            }else if(method === 'delete'){
                ShowToast('success', 'Successfully deleted')
            }
            return response.data;
        } catch (error) {
            ShowToast('error', 'Something went wrong')
            throw new Error(error);
        }
    }
);

export const beauticianServiceByEmployeeCrud = createAsyncThunk(
    'service/beauticianServiceByEmployeeCrud',
    async ({ method,data,employeeId,isDisable, serviceId }) => {
        try {
            const config = {
                method: method,
                url: `${BASE_URL}/employees/${employeeId}/advanced-services${serviceId ? `/${serviceId}` : ""}?shopId=${shopId()}${isDisable ? `&isDisable=${isDisable}`: ''}`,
                headers: HEADERS(),
                data: data
            };
            const response = await axios(config);
            if(method === 'post'){
                ShowToast('success', 'Successfully created')
            }else if(method === 'put'){
                ShowToast('success', 'Successfully updated')
            }else if(method === 'delete'){
                ShowToast('success', 'Achived its')
            }else if(method === 'patch'){
                ShowToast('success', 'Successfully restored')
            }
            return response.data;
        } catch (error) {
            if(error.response.status === 422){
                ShowToast('error', 'This service is already exist')
            }else{
                ShowToast('error', 'Something went wrong')
            }
            throw new Error(error);
        }
    }
);

const serviceSlice = createSlice({
    name: "service",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(getAllServices.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(getAllServices.fulfilled, (state, action) => {
            state.loading = false;
            state.services = action.payload;
        })
        .addCase(getAllServices.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })
        .addCase(serviceCud.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(serviceCud.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        })
        .addCase(serviceCud.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })
        .addCase(beauticianServiceCrud.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(beauticianServiceCrud.fulfilled, (state, action) => {
            state.loading = false;
            if(action.meta.arg.method === 'get'){
                state.beauticianServices = action.payload
            }else{
                state.data = action.payload;
            }
        })
        .addCase(beauticianServiceCrud.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })
        .addCase(beauticianServiceByEmployeeCrud.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(beauticianServiceByEmployeeCrud.fulfilled, (state, action) => {
            state.loading = false;
            if(action.meta.arg.method === 'get'){
                state.beauticianServicesByEmployee = action.payload
            }else{
                state.data = action.payload;
            }
        })
        .addCase(beauticianServiceByEmployeeCrud.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })
        .addCase(priceCrud.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(priceCrud.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        })
        .addCase(priceCrud.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })
    }
})

export default serviceSlice.reducer;