import React, { useEffect, useState } from 'react'
import { Dialog, DialogContent, DialogTitle, Box, Button, TextField, Divider } from '@mui/material'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import AsyncMultiSelect from "../utils/AsyncMultiSelect";
import { getAllStaff } from "../../slices/staffSlice";
import { collectGoldcardForSale } from '../../slices/receiptSlice';
import { nanoid } from '@reduxjs/toolkit';
import { shopId } from "../../utils/config";

const validationSchema = yup.object().shape({
    staff: yup.array().of(yup.object()).typeError("Please select at least one staff!"),
    code: yup.string().required('Gold card code is required')
})

const BuyGoldCard = ({open,setOpen}) => {
    const dispatch = useDispatch()
    const [ staffs, setStaffs ] = useState([]);
    const [ staffSearchQuery,setStaffSearchQuery ] = useState("")
    const [ staff, setStaff ] = React.useState([]);
    const { goldCardInfo } = useSelector(state=> state.GoldCard)
    const { staffs: staffResponse, loading: staffsLoading } = useSelector(
      (state) => state.Staff
    );
    const [staffPageNo, setStaffPageNo] = useState(1);

    const fetchStaffs = async () => {
        await dispatch(
          getAllStaff({
            method: "get",
            data: null,
            shop: shopId(),
            keyword: staffSearchQuery,
            scrollPage: staffPageNo,
            isAdvance: null
          })
        );
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset
    } = useForm({
        resolver: yupResolver(validationSchema)
    })

    const handleClose = ()=>{
        setOpen(false)
        setStaff(null);
        setStaff([])
        setStaffPageNo(1)
        reset()
    }

    const onSubmit = (data)=>{
        const formattedData = {
          id: nanoid(),
          ...data
        }
        dispatch(collectGoldcardForSale(formattedData))
        handleClose()  
    }
    
    useEffect(() => {
        staffResponse &&
        staffResponse.data &&
        setStaffs(staffResponse.data);
    }, [staffResponse]);

    React.useEffect(() => {
        fetchStaffs();
    }, [staffSearchQuery, staffPageNo]);

    useEffect(()=>{
      staff?.length > 0 && setValue('staff', staff)
    },[staff])

    useEffect(()=>{
      setStaffSearchQuery("")
    },[open])

  return (
    <Dialog open={open} onClose={handleClose}>
        <DialogContent sx={{ width: 450 }}>
            <DialogTitle sx={{ pl: 0, pt: 0 }}>Buy Gold Card</DialogTitle>
            <Divider sx={{ mb: 3 }}>Price - {goldCardInfo && goldCardInfo[0]?.price} MMK</Divider>
            <form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
                <AsyncMultiSelect
                    placeholder={"Select Staff"}
                    loading={staffsLoading}
                    values={staff || []}
                    setValues={setStaff}
                    pageNo={staffPageNo}
                    searchText={staffSearchQuery}
                    options={staffs}
                    setSearchText={setStaffSearchQuery}
                    setPageNo={setStaffPageNo}
                    hasMore={staffPageNo < staffResponse?.meta?.last_page}
                    error={errors?.employee}
                    position="relative"
                  ></AsyncMultiSelect>
                  <TextField
                    fullWidth
                    sx={{ mt: 3 }}
                    size={'small'}
                    error={!!errors?.code}
                    helperText={errors?.code?.message}
                    {...register('code')}
                    label="Enter Gold Card Code"
                  ></TextField>
                <Box sx={{ mb: 3 }}></Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Button type='button' variant="text" sx={{ mr: 2 }} onClick={handleClose} >Cancel</Button>
                    <Button type='submit' variant="contained">Buy</Button>
                </Box>
            </form>
        </DialogContent>
    </Dialog>
  )
}

export default BuyGoldCard