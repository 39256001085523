import { Box, Chip, CircularProgress, TextField } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';
import theme from '../../utils/theme';

const AsyncMultiSelect = ({ placeholder,options,values, setValues, searchText, setSearchText, pageNo, setPageNo, hasMore, loading, error, position }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [fetchedOptions, setFetchedOptions] = useState([]);
  const optionsContainerRef = useRef(null);

  const handleScroll = () => {
    const container = optionsContainerRef.current;
    const isAtBottom = container.scrollTop + container.clientHeight === container.scrollHeight;

    if (isAtBottom && hasMore && !loading) {
      setPageNo((prev) => prev + 1);
    }
  };

  const handleSelect = (selectedValue) => {
    const isSelected = values.some(value => value.id === selectedValue.id);
    if (isSelected) {
      setValues(prevValues => prevValues.filter(value => value.id !== selectedValue.id));
    } else {
      setValues(prevValues => [...prevValues, selectedValue]);
    }
    setDropdownOpen(false);
    setSearchText('')
  };
  // const handleClear = () => {
  //   setSearchText('');
  //   setValues([]);
  //   setDropdownOpen(false);
  // };

  const handleClickOutside = (event) => {
    if (optionsContainerRef.current && !optionsContainerRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(()=>{
    setValues(values)
  },[values])

  useEffect(() => {
    const fetchData = async () => {
      if (pageNo === 1) {
        setFetchedOptions([...options]);
      } else {
        setFetchedOptions(prev=> [...prev,...options.filter(option => !prev.some(prevItem => prevItem.id === option.id))])
      }
    };
    fetchData();
  }, [options, pageNo]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  
  return (
    <div style={{ position: 'relative', pb: 10 }}>
      <TextField
        multiline
        fullWidth
        error={!!error}
        helperText={(!isDropdownOpen && error) && error?.message}
        placeholder={placeholder}
        size="small"
        type="input"
        autoComplete='off'
        value={searchText || ""}
        onChange={(e) => {
          setPageNo(1);
          setSearchText(e.target.value);
          setDropdownOpen(true);
        }}
        onFocus={() => setDropdownOpen(true)}
        InputProps={{
          endAdornment: (
            <Box sx={{ width: 700 }}>
            {
                values.map((selectedValue, index) => (
                  <Chip key={index} label={selectedValue.name} onDelete={() => handleSelect(selectedValue)} sx={{ m: .3 }}/>
                ))
            }
            </Box>
          ),
        }}
      />
      {isDropdownOpen && (
        <div
          ref={optionsContainerRef}
          style={{
            position: position,
            top: '100%',
            left: 0,
            maxHeight: 150,
            overflowY: 'auto',
            border: '1px solid #ccc',
            borderRadius: '4px',
            backgroundColor: '#fff',
            zIndex: 1000,
            width: '100%',
          }}
          onScroll={handleScroll}
        >
          {
            !(fetchedOptions.length > 0) && !loading ?
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 1 }}>
              There is no option
            </Box>
            :
            fetchedOptions.map((option, index) => (
            <Box
              key={index}
              sx={{
                p: 0.7,
                cursor: 'pointer',
                ':hover': { backgroundColor: theme.palette.common.blue, color: theme.palette.common.white },
              }}
              onClick={() => handleSelect(option)}
            >
              {placeholder === 'Select Staff' ? `${option.name} (${option.phone})` : option.name || ""}
            </Box>
          ))}
          {loading && (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 1 }}>
              <CircularProgress size={15}/>
            </Box>
          )}
        </div>
      )}
    </div>
  );
};

export default AsyncMultiSelect;
