import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL, HEADERS, getInfoFromLocal } from "../utils/config";
import axios from "axios";
import { ShowToast } from "../components/utils/ShowToast";

const initialState = {
    customer: null,
    collectedGoldcard: [],
    collectedMemberships: [],
    collectedMembershipUsages: [],
    collectedBeauticianServices: [],
    collectedServices: [],
    collectedItems: [],
    loading: false,
    data: null,
    error: null
}

export const getAllReceipts = createAsyncThunk(
    'receipt/getAllReceipts',
    async ({ method, data, type }) => {
        const shop_id = getInfoFromLocal().user.shopId
        const searchParams = new URLSearchParams(window.location.search);
        const pageNo = searchParams.get('page');
        const startDate = searchParams.get('start_date')
        const endDate = searchParams.get('end_date')
        const keyword = searchParams.get('keyword')
        try {
            const config = {
                method: method,
                url: `${BASE_URL}/${type === "sale" ? "receipts" : "purchases"}?shopId=${shop_id}${pageNo ? `&page=${pageNo}` : ""}${(startDate && endDate) ? `&startDate=${startDate}&endDate=${endDate}` : ""}${keyword ? `&name=${keyword}` : ""}`,
                headers: HEADERS(),
                data: data
            };
            const response = await axios(config);
            return response.data;
        } catch (error) {
            if (error.code === "ERR_NETWORK") {
                ShowToast('error', error.message)
            } else {
                ShowToast('error', 'Something went wrong')
            }
            throw new Error(error);
        }
    }
);

export const getReceipt = createAsyncThunk(
    'receipt/getReceipt',
    async ({ method, data, type, id }) => {
        const shop_id = getInfoFromLocal().user.shopId
        try {
            const config = {
                method: method,
                url: `${BASE_URL}/${type === "sale" ? "receipts" : "purchases"}${id ? `/${id}` : ""}?shopId=${shop_id}`,
                headers: HEADERS(),
                data: data
            };
            const response = await axios(config);
            return response.data;
        } catch (error) {
            ShowToast('error', 'Something went wrong')
            throw new Error(error);
        }
    }
);

export const getCombinedReceipt = createAsyncThunk(
    'receipt/getCombinedReceipt',
    async ({ method, data }) => {
        const shop_id = getInfoFromLocal().user.shopId
        try {
            const config = {
                method: method,
                url: `${BASE_URL}/receipts/combine-the-receipts?shopId=${shop_id}`,
                headers: HEADERS(),
                data: data
            };
            const response = await axios(config);
            return response.data;
        } catch (error) {
            if(error.response.status === 422){
                ShowToast('error', error.response.data.message)
            }else {
                ShowToast('error', 'Something went wrong')
            }
            throw new Error(error);
        }
    }
);

export const receiptCud = createAsyncThunk(
    'receipt/receiptCud',
    async ({ method, data, type, id }) => {
        const shop_id = getInfoFromLocal().user.shopId
        try {
            const config = {
                method: method,
                url: `${BASE_URL}/${type === "sale" ? "receipts" : "purchases"}${id ? `/${id}` : ""}?shopId=${shop_id}`,
                headers: HEADERS(),
                data: data
            };
            const response = await axios(config);
            if (method === 'post') {
                ShowToast('success', 'Successfully created')
            } else if (method === 'put') {
                ShowToast('success', 'Successfully updated')
            } else if (method === 'delete') {
                ShowToast('success', 'Successfully deleted')
            }
            return response.data;
        } catch (error) {
            console.log(error);
            if (error.response.status === 403) {
                ShowToast('error', 'Access Denied')
            } else {
                ShowToast('error', 'Something went wrong')
            }
            throw new Error(error);
        }
    }
);

const receiptSlice = createSlice({
    name: "receipt",
    initialState,
    reducers: {
        resetCollected: (state)=>{
            state.collectedGoldcard = [];
            state.collectedMemberships = [];
            state.collectedMembershipUsages = [];
            state.collectedBeauticianServices = [];
            state.collectedServices = [];
            state.collectedItems = [];
        },
        updateManualDiscount: (state, action) => {
            const { updateId, value } = action.payload;
            return {
                ...state,
                collectedBeauticianServices: state.collectedBeauticianServices.map(item =>
                    item.id === updateId ? { ...item, manualDiscount: value } : item
                ),
                collectedServices: state.collectedServices.map(item =>
                    item.id === updateId ? { ...item, manualDiscount: value } : item
                ),
                collectedItems: state.collectedItems.map(item =>
                    item.id === updateId ? { ...item, manualDiscount: value } : item
                )
            };
        },
        removeCollectedItem: (state, action) => {
            const { removeId } = action.payload;
            return {
                ...state,
                collectedBeauticianServices: state.collectedBeauticianServices.filter(item => item.id !== removeId),
                collectedServices: state.collectedServices.filter(item => item.id !== removeId),
                collectedMembershipUsages: state.collectedMembershipUsages.filter(item => item.id !== removeId),
                collectedItems: state.collectedItems.filter(item => item.id !== removeId)
            };
        },        
        collectServiceForSale: (state, action) => {
            state.collectedServices.push(action.payload)
        },
        collectItemForSale: (state, action) => {
            state.collectedItems.push(action.payload)
        },
        collectBeauticianServiceForSale: (state, action) => {
            state.collectedBeauticianServices.push(action.payload)
        },
        collectGoldcardForSale: (state, action) => {
            state.collectedGoldcard.push(action.payload)
        },
        collectMembershipForSale: (state, action) => {
            state.collectedMemberships.push(action.payload)
        },
        collectMembershipUsageForSale: (state, action) => {
            state.collectedMembershipUsages.push(action.payload)
        },
        setReceiptCustomer: (state, action) => {
            state.customer = action.payload
        },
        resetReceipt: (state)=>{
            state.receipt = []
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllReceipts.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAllReceipts.fulfilled, (state, action) => {
                state.loading = false;
                state.receipts = action.payload;
            })
            .addCase(getAllReceipts.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getReceipt.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getReceipt.fulfilled, (state, action) => {
                state.loading = false;
                state.receipt = action.payload;
            })
            .addCase(getReceipt.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getCombinedReceipt.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getCombinedReceipt.fulfilled, (state, action) => {
                state.loading = false;
                state.combinedReceipt = action.payload;
            })
            .addCase(getCombinedReceipt.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(receiptCud.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(receiptCud.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(receiptCud.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
    }
})

export const {
    resetReceipt,
    resetCollected,
    updateManualDiscount,
    removeCollectedItem,
    collectBeauticianServiceForSale,
    collectMembershipUsageForSale,
    collectMembershipForSale,
    collectGoldcardForSale,
    collectServiceForSale,
    collectItemForSale,
    setReceiptCustomer,
    } = receiptSlice.actions

export default receiptSlice.reducer;