import { isWithinInterval, parse } from 'date-fns';

export const calcDiscount = (price, percentage)=>{
    const result = ((100 - Number(percentage)) / 100) * Number(price)
    return result;
}

export const validateSeasonalDiscount = (startDateStr, endDateStr, todayStr) => {
    if(!(startDateStr && endDateStr)) return;
    const startDate = parse(startDateStr, 'yyyy-MM-dd', new Date());
    const endDate = parse(endDateStr, 'yyyy-MM-dd', new Date());
    const today = parse(todayStr, 'yyyy-MM-dd', new Date());
    return isWithinInterval(today, { start: startDate, end: endDate });
};
