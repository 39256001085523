import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, TextField, Divider } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getAllStaff } from "../../slices/staffSlice";
import { getAllServices } from "../../slices/serviceSlice";
import { shopId } from "../../utils/config";
import AsyncSelect from "../utils/AsyncSelect";
import AsyncMultiSelect from "../utils/AsyncMultiSelect";
import NormalSelect from "../utils/NormalSelect";
import { collectMembershipForSale } from "../../slices/receiptSlice";
import { nanoid } from "@reduxjs/toolkit";

const createMemberSchema = yup.object().shape({
  service: yup.object().nullable().required("Please select Service Name!"),
  servicePrice: yup.object().nullable().required("Please select a price!"),
  time: yup
    .number()
    .required("Please add Times!")
    .typeError("Your times is Wrong!"),
  freeTime: yup
    .number()
    .required("Please add times for free!")
    .typeError("Your free times is Wrong!"),
  staff: yup.array().of(yup.object()).typeError("Please select at least one staff!"),
  commission: yup
    .number()
    .required("Please enter commission (%) !")
    .typeError("Commission is Wrong!"),
});

const BuyMembership = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const [ staffs, setStaffs ] = useState([]);
  const [service, setService] = React.useState({});
  const [ services, setServices ] = useState([]);
  const { collectedMemberships } = useSelector(state=> state.Receipt)
  const { staffs: staffResponse, loading: staffsLoading } = useSelector(
    (state) => state.Staff
  );
  const { services: serviceResponse, loading: servicesLoading } = useSelector(
    (state) => state.Service
  );
  const [searchText, setSearchText] = React.useState("");
  const [searchTextService, setSearchTextService] = React.useState("");
  const [staff, setStaff] = React.useState([]);
  const [isopen, setIsOpen] = React.useState(open);
  const [time, setTime] = useState("");
  const [ totalPrice, setTotalPrice ] = useState(0);
  const [servicePageNo, setServicePageNo] = useState(1);
  const [staffPageNo, setStaffPageNo] = useState(1);
  const [ prices,setPrices ] = useState([])
  const [ selectedPrice,setSelectedPrice ] = useState(null)

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    trigger,
  } = useForm({
    resolver: yupResolver(createMemberSchema),
    defaultValues: {
      freeTime: 1,
    },
  });

  const fetchStaffs = async () => {
    await dispatch(
      getAllStaff({
        method: "get",
        data: null,
        shop: shopId(),
        keyword: searchText,
        scrollPage: staffPageNo,
        isAdvance: null
      })
    );
  };

  const fetchServices = async () => {
    await dispatch(
      getAllServices({
        method: "get",
        data: null,
        keyword: searchTextService,
        scrollPage: servicePageNo,
      })
    );
  };


  const handleOnSubmit = async (data) => {
    const existingItemIndexById = collectedMemberships.findIndex(item => item.service.id === data.service.id);
    const existingItemIndexByPrice = collectedMemberships.findIndex(item => item.servicePrice.price === data.servicePrice.price);

    if (existingItemIndexById !== -1 && existingItemIndexByPrice !== -1) {
      console.log("you can't save")
    }else{
      const newData = {
        id: nanoid(),
        shopId: shopId(),
        ...data
      };
      dispatch(collectMembershipForSale(newData))
    }
    handleClose();
  };

  function handleClose() {
    setIsOpen(false);
    setOpen(false);
    reset();
    setService(null);
    setStaff(null);
    setTime("");
    setServicePageNo(1)
    setStaffPageNo(1)
  }

  useEffect(() => {
    staffResponse &&
    staffResponse.data &&
    setStaffs(staffResponse.data);
  }, [staffResponse]);

  useEffect(() => {
      serviceResponse &&
      serviceResponse.data &&
      setServices(serviceResponse.data);
  }, [serviceResponse]);

  React.useEffect(() => {
    fetchStaffs();
  }, [searchText, staffPageNo]);

  React.useEffect(() => {
    fetchServices();
  }, [searchTextService, servicePageNo]);

  React.useEffect(() => {
    if (staff) {
      setValue("staff", staff);
      trigger("staff");
    } else {
      setValue("staff", []);
    }
  }, [staff]);

  React.useEffect(() => {
    if(service) {
      setPrices(service.prices)
      setValue("service", service);
      trigger("service");
    }else if(!service) {
      setValue("service", null);
      setPrices([])
    }
    setSelectedPrice(null)
  }, [service]);

  useEffect(() => {
    if (selectedPrice && time) {
      setValue("time", time);
      trigger("time");
      setValue("cash", Number(selectedPrice.price) * time);
      setTotalPrice(Number(selectedPrice.price) * time);
    }
  }, [selectedPrice, time]);

  useEffect(() => {
    if (time) {
      setValue("time", time);
      trigger("time");
    }
  }, [time]);

  useEffect(()=>{
    if(selectedPrice){
      setValue('servicePrice', selectedPrice)
      trigger('servicePrice')
    }else{
      setValue('servicePrice', null)
      setSelectedPrice(null)
    }
  },[selectedPrice])

  React.useEffect(() => {
    fetchStaffs();
    fetchServices();
    setStaff([])
    setService(null)
    setSearchText("")
    setSearchTextService("")
    setIsOpen(open);
    setTotalPrice(0)
  }, [open]);

  return (
    <React.Fragment>
      <Dialog
        open={isopen}
        onClose={handleClose}
      >
          <DialogContent sx={{ width: 500 }}>
            <DialogTitle sx={{ pl: 0, pt: 0 }}>Buy Membership</DialogTitle>
            <Divider sx={{ mb: 3 }}>Price - {totalPrice} MMK</Divider>
            <form autoComplete="off" onSubmit={handleSubmit(handleOnSubmit)}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box sx={{ mb: 3, display: "flex", gap: 2 }}>
                  <Box sx={{ width: '50%' }}>
                    {/* select service */}
                    <AsyncSelect
                        placeholder={"Select Service"}
                        loading={servicesLoading}
                        value={service?.name || ""}
                        setValue={setService}
                        pageNo={servicePageNo}
                        searchText={searchTextService}
                        options={services}
                        setSearchText={setSearchTextService}
                        setPageNo={setServicePageNo}
                        hasMore={servicePageNo < serviceResponse?.meta?.last_page}
                        error={errors?.service}
                        position={'absolute'}
                      ></AsyncSelect>
                  </Box>
                  <Box sx={{ width: '50%' }}>
                    {/* select price */}
                    <NormalSelect
                      label={"Select Price"}
                      size={"small"}
                      value={selectedPrice}
                      setValue={setSelectedPrice}
                      options={prices}
                      error={errors.servicePrice}
                      optionLabel={'price'}
                    ></NormalSelect>
                  </Box>
                </Box>
                {/* select staff */}
                <AsyncMultiSelect
                  placeholder={"Select Staff"}
                  loading={staffsLoading}
                  values={staff || []}
                  setValues={setStaff}
                  pageNo={staffPageNo}
                  searchText={searchText}
                  options={staffs}
                  setSearchText={setSearchText}
                  setPageNo={setStaffPageNo}
                  hasMore={staffPageNo < staffResponse?.meta?.last_page}
                  error={errors?.staff}
                  position={'absolute'}
                ></AsyncMultiSelect>
                <Box sx={{ mt: 3, display: "flex", gap: 2 }}>
                  <Box sx={{ width: "50%" }}>
                    <TextField
                      size="small"
                      fullWidth
                      id="outlined-controlled"
                      label="Total Time"
                      value={time}
                      onChange={(event) => {
                        setTime(event.target.value);
                      }}
                      error={!!errors?.time}
                      helperText={errors?.time?.message}
                    ></TextField>
                  </Box>
                  <Box sx={{ width: "50%" }}>
                    <TextField
                      size="small"
                      fullWidth
                      {...register("freeTime")}
                      error={!!errors?.freeTime}
                      label="Free Time"
                      helperText={errors?.freeTime?.message}
                    ></TextField>
                  </Box>
                </Box>
                <TextField
                  sx={{ mt: 3 }}
                  size="small"
                  fullWidth
                  {...register("commission")}
                  error={!!errors?.commission}
                  label="Commission(%)"
                  helperText={errors?.commission?.message}
                ></TextField>
              </Box>
              <Box sx={{ mt: 3, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Button type='button' variant="text" sx={{ mr: 2 }} onClick={handleClose} >Cancel</Button>
                <Button type='submit' variant="contained">Buy</Button>
              </Box>
            </form>
          </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default BuyMembership;
