import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import CustomDatePicker from '../utils/CustomDatePicker';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { seasonalDiscountCrud } from '../../slices/seasonalDiscountSlice';

const validationSchema = Yup.object({
    startDate: Yup.date().typeError('Invalid date format').required("Start Date is required"),
    endDate: Yup.date().typeError('Invalid date format').required("End Date is required"),
    itemDiscount: Yup.number().typeError("Invalid data type").min(0, "Minimum discount should be 0%").max(100, "Maximum discount should be 100%").required('Item discount is required'),
    serviceDiscount: Yup.number().typeError("Invalid data type").min(0, "Minimum discount should be 0%").max(100, "Maximum discount should be 100%").required('Service discount is required'),
});

const UpdateSeasonalDis = ({ open, setOpen }) => {
    const dispatch = useDispatch()
    const { seasonalDiscount } = useSelector(state=> state.SeasonalDiscount)
    const [ startDate, setStartDate ] = useState(null)
    const [ endDate, setEndDate ] = useState(null)
    
    const {
        handleSubmit,
        register,
        formState: { errors },
        reset,
        setValue,
        trigger
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const handleClose = () => {
        reset()
        setOpen(false)
        setStartDate(null)
        setEndDate(null)
    }

    const handleOnSubmit = async (data) => {
        const formattedStartDate = format(data.startDate, "yyyy-MM-dd")
        const formattedEndDate = format(data.endDate, "yyyy-MM-dd")
        const newData = {
            startDate : formattedStartDate,
            endDate : formattedEndDate,
            itemDiscountPercent: data.itemDiscount,
            advancedServiceDiscountPercent: data.serviceDiscount
        }
        await dispatch(seasonalDiscountCrud({ method: 'put', data: newData, id: seasonalDiscount?.id }))
        handleClose()
        await dispatch(seasonalDiscountCrud({ method: 'get', data: null }))
    }

    useEffect(()=>{
        if(startDate){
            setValue('startDate',startDate)
            trigger('startDate')
        }
        if(endDate){
            setValue('endDate',endDate)
            trigger('endDate')
        }
    },[startDate,endDate,setValue,trigger])

    useEffect(()=>{
        if(seasonalDiscount){
          setStartDate(seasonalDiscount.start_date);
          setEndDate(seasonalDiscount.end_date)
          setValue('serviceDiscount',seasonalDiscount.advanced_service_discount_percent)
          setValue('itemDiscount',seasonalDiscount.item_discount_percent)
        }
    },[open])

    // const dialogPaperClasses = {
    //     paper: 'custom-dialog-paper'
    // };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            // classes={dialogPaperClasses}
            >
            <form onSubmit={handleSubmit(handleOnSubmit)} sx={{ position: 'relative' }}>
                <DialogTitle id="alert-dialog-title">
                    {"Update Seansonal Discount"}
                </DialogTitle>
                <DialogContent sx={{ width: 350 }}>
                    <CustomDatePicker
                        dateVal={startDate}
                        setDate={setStartDate}
                        error={errors.startDate}
                        placeholderText='Start Date'
                    ></CustomDatePicker>
                    <Box sx={{ mb: 1 }}></Box>
                    <CustomDatePicker
                        dateVal={endDate}
                        setDate={setEndDate}
                        error={errors.endDate}
                        placeholderText='End Date'
                    ></CustomDatePicker>
                    <TextField {...register('itemDiscount')} fullWidth sx={{ mt: 2 }} label={"Item Discount"} error={!!errors.itemDiscount} helperText={errors?.itemDiscount?.message}></TextField>
                    <TextField {...register('serviceDiscount')} fullWidth sx={{ mt: 2 }} label={"Service Discount"} error={!!errors.serviceDiscount} helperText={errors?.serviceDiscount?.message}></TextField>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ mr: 2, mb: 2 }}>
                        <Button type='button' sx={{ mr: 2 }} onClick={handleClose}>cancel</Button>
                        <Button type='submit' variant='contained'>
                            Update
                        </Button>
                    </Box>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default UpdateSeasonalDis
