import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import theme from '../../utils/theme';
import ApplicationBar from '../main/AppBar';
import SideBar from '../main/SideBar';
import { goldCardRU } from '../../slices/goldCardSlice';
import { useDispatch } from "react-redux";
import { seasonalDiscountCrud } from '../../slices/seasonalDiscountSlice';
import { getAllShops } from '../../slices/shopSlice'

const drawerWidth = 270;

function ResponsiveDrawer(props) {
  const dispatch = useDispatch()
  const { window } = props;
  const [ mobileOpen, setMobileOpen ] = useState(false);
  const [ isClosing, setIsClosing ] = useState(false);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const container = window !== undefined ? () => window().document.body : undefined;

  const fetchData = async()=>{
    await dispatch(goldCardRU({ method: 'get', data: null }))
    await dispatch(seasonalDiscountCrud({ method: 'get', data: null }))
    await dispatch(getAllShops({ method: 'get', data: null }));
  }
  
  useEffect(()=>{
    fetchData()
  },[])

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          maxWidth: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: theme.palette.common.white
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon sx={{ color: theme.palette.primary.main }}/>
          </IconButton>
          <ApplicationBar></ApplicationBar>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          <SideBar></SideBar>
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          <SideBar></SideBar>
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ width: { sm: `calc(100% - ${drawerWidth}px)` }, backgroundColor: theme.palette.background.main , px: 1, pt: 9, pb: 1, minHeight: '100vh' }}
      >
        { props.children }
      </Box>
    </Box>
  );
}

export default ResponsiveDrawer;

