import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

const AppPagination = ({ pageCount }) => {
  const [currentPage, setCurrentPage] = React.useState(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const page = searchParams.get('page');
    return page ? Number(page) : 1;
  });

  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (event, value) => {
    setCurrentPage(value);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('page', value.toString());
    navigate(`?${searchParams.toString()}`);
  };

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const prevPage = searchParams.get('page');
    if (prevPage) {
      setCurrentPage(Number(prevPage));
    }
  }, [location.search]);

  return (
    <Box sx={{ width: '100%', display: 'flex', my: 3, justifyContent: 'center' }}>
      <Pagination
        page={currentPage}
        onChange={handleChange}
        count={pageCount}
        shape="rounded"
        color="primary"
        variant="outlined"
      />
    </Box>
  );
};

export default AppPagination;
