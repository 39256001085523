import React, { useState, useEffect } from "react";
import { Box, Button, Checkbox, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material"
import theme from "../utils/theme"
import DateFilters from "../components/main/filter-components/DateFilters"
import SearchInput from "../components/main/filter-components/SearchInput"
import StyledTableData from '../components/utils/StyledTableData';
import StyledTableHead from '../components/utils/StyledTableHead';
import DeleteIcon from '@mui/icons-material/Delete';
import AppPagination from "../components/main/filter-components/AppPagination"
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { getAllReceipts, receiptCud } from "../slices/receiptSlice";
import NoDataAlert from '../components/utils/NoDataAlert'
import { changeDateTime } from '../utils/changeDateTime'
import Loading from "../components/utils/Loading";
import AlertDialog from "../components/utils/AlertDialog";
import DetailsIcon from '@mui/icons-material/Details';
import PrintIcon from '@mui/icons-material/Print';
import CombinePrintSale from "./CombinePrintSale";

const SaleAndService = () => {
    const dispatch = useDispatch();
    const { search } = useLocation()
    const [showAlert, setShowAlert] = useState(false);
    const [ combineOpen,setCombineOpen ] = useState(false)
    const [idToDel, setIdToDel] = useState(null);
    const { receipts: saleAndServiceList, loading } = useSelector(state => state.Receipt);
    const navigate = useNavigate();
    const [combinedIds, setCombinedIds] = useState([]);

    useEffect(() => {
        fetchData();
        setCombinedIds([])
    }, [search]);

    const fetchData = async () => {
        await dispatch(getAllReceipts({ method: 'get', data: null, type: 'sale' }));
    };

    const handleCheck = (event, selectedId) => {
        if (event.target.checked) {
            setCombinedIds(prev => [...prev, selectedId]);
        } else {
            setCombinedIds(prev => prev.filter(id => id !== selectedId));
        }
    };
    
    const handleConbinedPrint = () => {
        setCombineOpen(true)
    };

    const handleDelete = (id) => {
        setShowAlert(true);
        setIdToDel(id);
    };

    const handleCancel = () => {
        setShowAlert(false);
        setIdToDel(null);
    };

    const handleConfirm = async () => {
        await dispatch(receiptCud({ method: 'delete', data: null, type: 'sale', id: idToDel }));
        setShowAlert(false);
        setIdToDel(null);
        fetchData();
    };

    const createVoucher = () => {
        navigate(`/create-sale-voucher`);
    };

    return (
        <Box sx={{
            bgcolor: theme.palette.common.white,
            borderRadius: '10px',
            height: '100%',
            pb: 3
        }}>
            {loading && <Loading />}
            <AlertDialog
                toggle={showAlert}
                setToggle={setShowAlert}
                cancel={handleCancel}
                confrim={handleConfirm}
                title={"Are you sure?"}
                content={"You want to delete this receipt."}
            />
            { combineOpen && <CombinePrintSale ids={combinedIds} open={combineOpen} setOpen={setCombineOpen}></CombinePrintSale>}
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                p: 3
            }}>
                    <Box>
                        <Button onClick={createVoucher} variant='contained' sx={{ mr: 2 }}>Add New</Button>
                        <Button disabled={!(combinedIds.length > 0)} onClick={handleConbinedPrint} variant='contained' endIcon={<PrintIcon/>}>Print Voucher</Button>
                    </Box>
                    <DateFilters />
                    <SearchInput placeholderText={"Search by customer name"} />
            </Box>
            <TableContainer sx={{ px: 3 }}>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <StyledTableHead />
                            <StyledTableHead align="center">No</StyledTableHead>
                            <StyledTableHead>Date</StyledTableHead>
                            <StyledTableHead>Voucher Code</StyledTableHead>
                            <StyledTableHead>Account Name</StyledTableHead>
                            <StyledTableHead>Customer Name</StyledTableHead>
                            <StyledTableHead>Total Fee</StyledTableHead>
                            <StyledTableHead sx={{ textAlign: 'center' }}>Actions</StyledTableHead>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {
                        (saleAndServiceList && saleAndServiceList.data.length > 0) ?
                            saleAndServiceList.data.map((saleAndService, index) => (
                            <TableRow key={index}>
                                <StyledTableData align="center">
                                    <Checkbox onChange={(event) => handleCheck(event, saleAndService.id)} />
                                </StyledTableData>
                                <StyledTableData align="center">{((saleAndServiceList.meta.current_page - 1) * saleAndServiceList.meta.per_page) + (index + 1)}</StyledTableData>
                                <StyledTableData>{changeDateTime(saleAndService.date)}</StyledTableData>
                                <StyledTableData>{saleAndService.code}</StyledTableData>
                                <StyledTableData>{saleAndService.userName?.name || "-"}</StyledTableData>
                                <StyledTableData>{saleAndService.customerName?.name || "-"}</StyledTableData>
                                <StyledTableData>{(Number(saleAndService.kpay) + Number(saleAndService.cash)).toLocaleString()}</StyledTableData>
                                <StyledTableData sx={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
                                    <Tooltip title="Print" >
                                        <Link to={`/sales-and-services/sale-voucher-print/${saleAndService.id}`}>
                                            <IconButton sx={{ mr: 2 }}>
                                                <PrintIcon sx={{ color: theme.palette.common.purple }} />
                                            </IconButton>
                                        </Link>
                                    </Tooltip>
                                    <Tooltip title="Detail" >
                                        <Link to={`/sales-and-services/sale-voucher-detail/${saleAndService.id}`}>
                                            <IconButton sx={{ mr: 2 }}>
                                                <DetailsIcon sx={{ color: 'lightseagreen' }} />
                                            </IconButton>
                                        </Link>
                                    </Tooltip>
                                    <Tooltip title="Delete" onClick={() => handleDelete(saleAndService.id)}>
                                        <IconButton>
                                            <DeleteIcon sx={{ color: theme.palette.primary.main }} />
                                        </IconButton>
                                    </Tooltip> 
                                </StyledTableData>
                            </TableRow>
                            )):
                            <TableRow>
                                <TableCell colSpan={8}>
                                    <NoDataAlert content={"There is no receipts for sales and services"} />
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <AppPagination pageCount={saleAndServiceList?.meta.last_page} />
        </Box>
    )
}

export default SaleAndService;
