import React, { useEffect, useState } from 'react'
import { Dialog, DialogContent, DialogTitle, TextField, Button, Box, Divider } from '@mui/material'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import { beauticianServiceByEmployeeCrud, beauticianServiceCrud } from '../../slices/serviceSlice';
import AsyncSelect from '../utils/AsyncSelect';
import CommissionTypeSelect from '../utils/CommissionTypeSelect';
import { useLocation, useParams } from 'react-router-dom';
import Loading from '../utils/Loading';


const validationSchema = yup.object().shape({
    service: yup.object().nullable().required('Please select the service!'),
    price: yup.number().min(0).typeError("Please fill a valid price!").required("Price is required!"),
    normalCommission: yup.number().typeError("Please fill a valid commission value!").required("Commission percentage is required!"),
    normalCommissionType: yup.string().required("Normal commission type is required!"),
    bynameCommission: yup.number().typeError("Please fill a valid commission value!").required("Commission percentage is required!"),
    bynameCommissionType: yup.string().required("Byname commission type is required!")
})

const CUBeauServiceByEmployee = ({open,setOpen,serviceDetail}) => {
    const dispatch = useDispatch()
    const { state } = useLocation()
    const { id: employeeId } = useParams()
    const { beauticianServices, loading } = useSelector(state=> state.Service)
    const [ service, setService ] = useState(null);
    const [ searchQuery, setSearchQuery] = useState("");
    const [ services, setServices ] = useState([]);
    const [ pageNo, setPageNo ] = useState(1);
    const [ normalType,setNormalType ] = useState('percent')
    const [ bynameType,setBynameType ] = useState('percent')

    const fetchServices = async()=>{
      await dispatch(beauticianServiceCrud({ method: 'get', data: null, employeeId: employeeId, notHave: true ,keyword: searchQuery, scrollPage: pageNo }))
    }

    const fetchData = async ()=>{
      await dispatch(beauticianServiceByEmployeeCrud({ method: 'get', data: null, employeeId: employeeId, isDisable: null}))
    }

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        trigger,
        reset
    } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
          price: state?.isAdvance ? undefined : 1000,
          normalCommissionType: 'percent',
          bynameCommissionType: 'percent',
        }
    })

    const handleClose = ()=>{
        setOpen(false)
        setNormalType('percent')
        setBynameType('percent')
        setService(null)
        setPageNo(1)
        reset()
    }

    const handleOnSubmit = async (data)=>{
        const formattedData = {
          advancedServiceId: data.service.id,
          price: state?.isAdvance ? data.price : 0,
          normalCommissionType: data.normalCommissionType,
          normalCommissionValue: data.normalCommission,
          bynameCommissionType: data.bynameCommissionType,
          bynameCommissionValue: data.bynameCommission
        }
        const dispatchData = await dispatch(beauticianServiceByEmployeeCrud({ method: serviceDetail ? 'put' : 'post', data: formattedData, employeeId: employeeId, serviceId: serviceDetail ? serviceDetail.id : null }))
        if(dispatchData.meta.requestStatus === "rejected") return;
        fetchData()
        handleClose()
    }

    React.useEffect(()=>{
      if(service){
          setValue('service', service)
          trigger('service')
      }else{
          setValue('service', null)
      }
  },[service])

  useEffect(()=>{
    if(normalType){
      setValue('normalCommissionType', normalType)
      trigger('normalCommissionType')
    }
  },[normalType])

  useEffect(()=>{
    if(bynameType){
      setValue('bynameCommissionType', bynameType)
      trigger('bynameCommissionType')
    }
  },[bynameType])

  useEffect(() => {
    if (beauticianServices) setServices(beauticianServices.data);
  }, [beauticianServices]);
  
  useEffect(() => {
    fetchServices();
  }, [pageNo, searchQuery])

  useEffect(()=>{
    fetchServices()
    if(serviceDetail){
      setValue("service", { id: serviceDetail.id })
      setValue('price', Number(serviceDetail.price))
      setValue('normalCommission', Number(serviceDetail.normalCommissionValue))
      setValue('bynameCommission',  Number(serviceDetail.bynameCommissionValue))
      setValue('normalCommissionType', serviceDetail.normalCommissionType)
      setValue('bynameCommissionType', serviceDetail.bynameCommissionType)
      setNormalType(serviceDetail.normalCommissionType)
      setBynameType(serviceDetail.bynameCommissionType)
    }else{
      setNormalType('percent')
      setBynameType('percent')
      setService(null)
      setPageNo(1)
      reset()
    }
  },[open,serviceDetail])

  return (
    <Dialog open={open} onClose={handleClose}>
      { loading && !beauticianServices && <Loading/> }
      <DialogContent sx={{ width: 420 }}>
        <DialogTitle sx={{ pl: 0 , pt: 0 }}>{serviceDetail ? "Update" : "Create"} New Service</DialogTitle>
        <form autoComplete='off' onSubmit={handleSubmit(handleOnSubmit)}>
            { !serviceDetail &&
              <AsyncSelect
                placeholder={"Select Service"}
                loading={loading}
                value={service?.name || ""}
                setValue={setService}
                pageNo={pageNo}
                searchText={searchQuery}
                options={services}
                setSearchText={setSearchQuery}
                setPageNo={setPageNo}
                hasMore={pageNo < beauticianServices?.meta?.last_page}
                error={errors.service}
                position='absolute'
              ></AsyncSelect>
            }
            {
              state?.isAdvance &&
              <>
                <TextField size='small' error={!!errors.price} helperText={errors.price?.message} {...register('price')} label='Price' fullWidth sx={!serviceDetail && { mt: 3 }}></TextField>
              </>
            }
            <Divider sx={{ my: 3 }}>Commission</Divider>
            <Box sx={{ display: 'flex', alignItems: 'start', gap: 2 }}>
              <TextField size='small' error={!!errors.normalCommission} helperText={errors.normalCommission?.message} {...register('normalCommission')} label='Normal' fullWidth></TextField>
              <CommissionTypeSelect value={normalType} setValue={setNormalType} error={errors.normalCommissionType}></CommissionTypeSelect>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'start', gap: 2, my: 3 }}>
              <TextField size='small' error={!!errors.bynameCommission} helperText={errors.bynameCommission?.message} {...register('bynameCommission')} label='Byname' fullWidth></TextField>
              <CommissionTypeSelect value={bynameType} setValue={setBynameType} error={errors.bynameCommissionType}></CommissionTypeSelect>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Button type='button' variant="text" sx={{ mr: 2 }} onClick={handleClose} >Cancel</Button>
                <Button type='submit' variant="contained">{serviceDetail ? 'Update' : 'Create'}</Button>
            </Box>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default CUBeauServiceByEmployee
