import React, { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import DatePicker from 'react-datepicker';
import { useLocation, useNavigate } from 'react-router-dom';
import { format, addDays } from 'date-fns';

const DateFilters = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const todayFilter = () => {
    setStartDate(null);
    setEndDate(null);
    const today = new Date();
    const nextDay = addDays(today, 1);
    const todayFormatted = format(today, 'yyyy-MM-dd');
    const nextDayFormatted = format(nextDay, 'yyyy-MM-dd');
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('start_date', todayFormatted);
    searchParams.set('end_date', nextDayFormatted);
    searchParams.set('page', '1');
    navigate(`?${searchParams.toString()}`);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const pageNo = searchParams.get('page') || '1';
    if (startDate && endDate) {
      searchParams.set('start_date', format(startDate, 'yyyy-MM-dd'));
      searchParams.set('end_date', format(endDate, 'yyyy-MM-dd'));
      searchParams.set('page', pageNo);
      navigate(`?${searchParams.toString()}`);
    }
  }, [startDate, endDate, navigate]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const start_date = searchParams.get('start_date');
    const end_date = searchParams.get('end_date');
    if (start_date && end_date) {
      setStartDate(new Date(start_date));
      setEndDate(new Date(end_date));
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  }, [location.search]);

  const preventKeyboardDeletion = (e) => {
    if (e.key === 'Backspace' || e.key === 'Delete') {
      e.preventDefault();
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Button onClick={todayFilter} sx={{ height: '100%', mr: 2 }} variant='outlined'>
        Today
      </Button>
      <DatePicker
        // isClearable
        placeholderText='Start Date'
        dateFormat='dd-MM-yyyy'
        className='customDatePicker'
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        onKeyDown={preventKeyboardDeletion}
      />
      <Box sx={{ width: 1 }}></Box>
      <DatePicker
        // isClearable
        placeholderText='End Date'
        dateFormat='dd-MM-yyyy'
        className='customDatePicker'
        selected={endDate}
        onChange={(date) => setEndDate(date)}
        onKeyDown={preventKeyboardDeletion}
      />
    </Box>
  );
};

export default DateFilters