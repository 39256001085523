import { Dialog, DialogContent, DialogTitle, Box, Button, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch } from 'react-redux'
import { getAllServices, priceCrud } from '../../slices/serviceSlice';

const validationSchema = yup.object().shape({
  price: yup.number().typeError("Please fill a valid price!").required("Price is required!"),
})

const CUPrice = ({open, setOpen, priceDetail, serviceId}) => {
  const dispatch = useDispatch()

  const fetchData = async () => {
    await dispatch(getAllServices({ method: 'get', data: null }));
  };
  
  const handleClose = ()=>{
    setOpen(false)
  }

  const {
      register,
      handleSubmit,
      formState: { errors },
      reset,
      setValue
  } = useForm({
      resolver: yupResolver(validationSchema)
  })

  const handleOnSubmit = async (data)=>{
    const formattedData = priceDetail ? {
      price: data.price
    } : {
      price: data.price,
      serviceId: serviceId
    }
    await dispatch(priceCrud({ method: priceDetail ? 'put':'post', data: formattedData, priceId: priceDetail ? priceDetail.id: null }))
    await fetchData()
  }

  useEffect(()=>{
    if(priceDetail){
      setValue('price', priceDetail.price)
    }else{
      reset()
    }
  },[open])

  return (
    <Dialog open={open} onClose={handleClose}>
        <DialogContent>
            <DialogTitle sx={{ p: 0, pb: 1 }}>{priceDetail ? "Update": "Create"} Price</DialogTitle>
            <form autoComplete='off' onSubmit={handleSubmit(handleOnSubmit)}>
                <TextField
                    size='small'
                    label="Price"
                    sx={{ mt: 1, mb: 3 }}
                    {...register('price')}
                    error={!!errors?.price}
                    helperText={errors?.price?.message}
                    >
                </TextField>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                  <Button type='button' variant="text" sx={{ mr: 2 }} onClick={handleClose} >Cancel</Button>
                  <Button type='submit' variant="contained">Confirm</Button>
                </Box>
            </form>
        </DialogContent>
    </Dialog>
  )
}

export default CUPrice