import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Dialog, DialogContent, DialogTitle ,FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup, Switch, TextField, Typography } from "@mui/material"
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import theme from '../../utils/theme';
import { getAllStaff } from '../../slices/staffSlice';
import AsyncSelect from '../utils/AsyncSelect';
import { getMembershipRec } from '../../slices/membershipSlice'
import { shopId } from "../../utils/config";
import { collectMembershipUsageForSale } from '../../slices/receiptSlice';
import { nanoid } from '@reduxjs/toolkit';
import AsyncMultiSelect from '../utils/AsyncMultiSelect';

const UseMembership = ({open,setOpen}) => {
    const dispatch = useDispatch()
    const [ searchTextStaff,setSearchTextStaff ] = React.useState("")
    const [ searchTextMembership,setSearchTextMembership ] = React.useState("")
    const [ status,setStatus ] = useState(null)
    // const [ staff, setStaff ] = useState(null)
    const [staff, setStaff] = React.useState([]);
    const [ staffs,setStaffs ] = useState([])
    const [ membershipRec, setMembershipRec ] = useState(null)
    const [ membershipRecs,setMembershipRecs ] = useState([])
    const [ staffPageNo, setStaffPageNo ] = useState(1);
    const [ membershipPageNo,setMembershipPageNo ] = useState(1)
    const { customer: selectedCustomer } = useSelector(state=> state.Receipt)
    const [ availableTime,setAvailableTime ] = useState(0)

    const validationSchema = yup.object().shape({
        membershipRec: yup.object().nullable().required("Please select a membership!"),
        // staff: yup.object().nullable().required("Please select the staff!"),
        staff: yup.array().of(yup.object()).typeError("Please select at least one staff!"),
        status: yup.string().required('Please select an option!'),
        quantity: yup.number().min(0, 'Quantity must be atleast 1!').max(availableTime,
            availableTime === 0 ? "There is no time to use memberships!" : availableTime === 1 ? `There is only ${availableTime} time left!` : `There is only ${availableTime} times left!`
        ).required('Please Add Quantity!').typeError('Your Quantity is Wrong!'),
        additionalCost: yup.number().typeError('Your value is Wrong!')
    })

    const { assistants: staffResponse, loading: staffsLoading } = useSelector(
        (state) => state.Staff
    );
    const { memberships: membershipRecsResponse, loading: membershipRecsLoading } = useSelector(
        (state) => state.Membership
    );
      
    const handleClose = ()=>{
        reset()
        setMembershipRec(null)
        setStaff(null)
        setStatus(null)
        setStaffPageNo(1)
        setMembershipPageNo(1)
        setOpen(false)
    }

    const {
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        trigger,
        register
    } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues:{
            additionalCost: 0
        }
    });

    const fetchMemberShipRec = async ()=>{
        await dispatch(
            getMembershipRec({
                method: 'get',
                data: null,
                cusId: selectedCustomer?.id,
                keyword: searchTextMembership,
                scrollPage: membershipPageNo,
            }))
    }

    // const fetchStaffs = async () => {
    //     await dispatch(
    //       getAllStaff({
    //         method: "get",
    //         data: null,
    //         shop: shopId(),
    //         keyword: searchTextStaff,
    //         scrollPage: staffPageNo,
    //         isAdvance: false
    //       })
    //     );
    // };

    const fetchStaffs = async () => {
        await dispatch(
          getAllStaff({
            method: "get",
            data: null,
            shop: shopId(),
            keyword: searchTextStaff,
            scrollPage: staffPageNo,
            isAdvance: false
          })
        );
    };

    const handleOnSubmit = (data)=>{
        dispatch(collectMembershipUsageForSale({ id: nanoid(),...data}))
        handleClose()
    }

    useEffect(()=>{
        if(status){
            setValue('status', status)
            trigger('status')
        }
    },[status])

    useEffect(() => {
        staffResponse &&
        staffResponse.data &&
        setStaffs(staffResponse.data);
    }, [staffResponse]);

    React.useEffect(() => {
        fetchStaffs();
    }, [searchTextStaff, staffPageNo]);

    useEffect(() => {
        membershipRecsResponse &&
        membershipRecsResponse.data &&
        setMembershipRecs(membershipRecsResponse.data);
    }, [membershipRecsResponse]);

    React.useEffect(() => {
        fetchMemberShipRec();
    }, [searchTextMembership, membershipPageNo]);

    React.useEffect(() => {
        if(staff) {
          setValue("staff", staff);
          trigger("staff");
        }else if(!staff) {
          setValue("staff", null);
        }
    }, [staff]);
    
    React.useEffect(() => {
        if(membershipRec) {
          setValue("membershipRec", membershipRec);
          trigger("membershipRec");
          const theTime = membershipRec.time ? Number(membershipRec.time) : 0;
          const freeTime = membershipRec.freeTime ? Number(membershipRec.freeTime) : 0;
          const usedTime = membershipRec.usedTime ? Number(membershipRec.usedTime) : 0;
          setAvailableTime((theTime + freeTime) - usedTime);
        }else if(!membershipRec) {
          setValue("membershipRec", null);
          setValue("quantity", null);
          setAvailableTime(0);
        }
    }, [membershipRec]);

    useEffect(()=>{
        if(selectedCustomer){
            fetchMemberShipRec()
        }
    },[selectedCustomer])

    useEffect(()=>{
        setSearchTextMembership("")
        setSearchTextStaff("")
    },[open])

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogContent sx={{ width: 400 }}>
                <DialogTitle sx={{ pt: 0, pl: 0 }}>Use Membership</DialogTitle>
                <form autoComplete='off' onSubmit={handleSubmit(handleOnSubmit)}>
                    <AsyncSelect
                        placeholder={"Select Available Membership"}
                        loading={membershipRecsLoading}
                        value={membershipRec?.serviceName || ""}
                        setValue={setMembershipRec}
                        pageNo={membershipPageNo}
                        searchText={searchTextMembership}
                        options={membershipRecs}
                        setSearchText={setSearchTextMembership}
                        setPageNo={setMembershipPageNo}
                        error={errors?.membershipRec}
                        hasMore={membershipPageNo < membershipRecsResponse?.meta?.last_page}
                        position='absolute'
                    ></AsyncSelect>
                    <Box sx={{ mb: 3 }}></Box>
                    {/* <AsyncSelect
                        placeholder={"Select Staff"}
                        loading={staffsLoading}
                        value={staff?.name || ""}
                        setValue={setStaff}
                        pageNo={staffPageNo}
                        searchText={searchTextStaff}
                        options={staffs}
                        setSearchText={setSearchTextStaff}
                        setPageNo={setStaffPageNo}
                        error={errors?.staff}
                        hasMore={staffPageNo < staffResponse?.meta?.last_page}
                        position='absolute'
                    ></AsyncSelect> */}
                     <AsyncMultiSelect
                        placeholder={"Select Staff"}
                        loading={staffsLoading}
                        values={staff || []}
                        setValues={setStaff}
                        pageNo={staffPageNo}
                        searchText={searchTextStaff}
                        options={staffs}
                        setSearchText={setSearchTextStaff}
                        setPageNo={setStaffPageNo}
                        hasMore={staffPageNo < staffResponse?.meta?.last_page}
                        error={errors?.staff}
                        position={'absolute'}
                    ></AsyncMultiSelect>
                    <FormControl sx={{ my: 2 }}>
                        <RadioGroup
                            value={status}
                            onClick={(e)=>{
                                if(e.target.value){
                                    setValue("status", e.target.value)
                                    setStatus(e.target.value)
                                }
                            }}
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                        >
                            <FormControlLabel value="normal" control={<Radio />} label={<Typography sx={{ fontSize: '12px' }}>Normal</Typography>} />
                            <FormControlLabel value="byname" control={<Radio />} label={<Typography sx={{ fontSize: '12px' }}>Byname</Typography>} />
                        </RadioGroup>
                        <FormHelperText sx={{ color: theme.palette.danger.main }}>{errors?.status?.message}</FormHelperText>
                    </FormControl>
                    <TextField
                        fullWidth
                        size='small'
                        label={'Quantity'}
                        {...register('quantity')}
                        error={!!errors.quantity}
                        helperText={errors?.quantity?.message}
                    ></TextField>
                    <TextField
                        sx={{ mt: 3 }}
                        fullWidth
                        size='small'
                        label={'Additional Cost'}
                        {...register('additionalCost')}
                        error={!!errors.additionalCost}
                        helperText={errors?.additionalCost?.message}
                    ></TextField>
                    <Box sx={{ mt: 3, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <Button type='button' variant="text" sx={{ mr: 2 }} onClick={handleClose} >Cancel</Button>
                        <Button type='submit' variant="contained">Buy</Button>
                    </Box>
                </form>
            </DialogContent>
        </Dialog>
    )
}

export default UseMembership