import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import theme from "../utils/theme";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useDispatch, useSelector } from "react-redux";
import { getAllCus } from "../slices/customerSlice";
import AsyncSelect from "../components/utils/AsyncSelect";
import BuyMembership from "../components/create_sale_voucher_components/BuyMembership";
import BuyGoldCard from "../components/create_sale_voucher_components/BuyGoldCard";
import BuyItem from "../components/create_sale_voucher_components/BuyItem";
import UseMembership from "../components/create_sale_voucher_components/UseMembership";
import SaveVoucher from "../components/create_sale_voucher_components/SaveVoucher";
import BuyService from "../components/create_sale_voucher_components/BuyService";
import AlertDialog from "../components/utils/AlertDialog";
import BuyBeauticianService from "../components/create_sale_voucher_components/BuyBeauticianService";
import { setReceiptCustomer } from "../slices/receiptSlice";

const CreateSale = () => {
  const dispatch = useDispatch();
  const [ customer, setCustomer ] = useState(null);
  const [ searchQuery, setSearchQuery ] = useState("");
  const [ customers, setCustomers ] = useState([]);
  const [ openBuyGoldCard, setOpenBuyGoldCard] = React.useState(false);
  const [ openBuyMembership, setOpenBuyMembership] = React.useState(false);
  const [ openBuyItem,setOpenBuyItem ] = React.useState(false)
  const [ openUseMembership,setOpenUseMembership ] = React.useState(false)
  const [ openBuyService,setOpenBuyService ] = React.useState(false)
  const [ openBuyBeauticianService, setOpenBuyBeauticianService ] = React.useState(false)
  const [ showAlert,setShowAlert ] = React.useState(false)
  const { customer: selectedCustomer, collectedGoldcard } = useSelector(state=> state.Receipt)
  
  const { customers: response, loading } = useSelector(
    (state) => state.Customer
  );
  const [pageNo, setPageNo] = useState(1);

  const fetchCustomers = async () => {
    await dispatch(
      getAllCus({
        method: "get",
        data: null,
        keyword: searchQuery,
        scrollPage: pageNo,
      })
    );
  };

  const handleCancel = ()=>{
    setShowAlert(false)
  }

  const handleConfirm = async ()=>{
    setShowAlert(false)
  }

  // const checkCustomer = ()=>{
    
  // }

  const buyGoldCardToggle = ()=>{
    if(!selectedCustomer){
      setShowAlert(true)
      return
    }
    setOpenBuyGoldCard(true)
  }

  const buyMembershipToggle = ()=>{
    if(!selectedCustomer){
      setShowAlert(true)
      return
    }
    setOpenBuyMembership(true)
  }

  const useMembershipToggle = ()=>{
    if(!selectedCustomer){
      setShowAlert(true)
      return
    }
    setOpenUseMembership(true)
  }

  const buyBeauticianServiceToggle = ()=>{
    if(!selectedCustomer){
      setShowAlert(true)
      return
    }
    setOpenBuyBeauticianService(true)
  }

  const buyServiceToggle = ()=>{
    if(!selectedCustomer){
      setShowAlert(true)
      return
    }
    setOpenBuyService(true)
  }

  const buyItemToggle = ()=>{
    if(!selectedCustomer){
      setShowAlert(true)
      return
    }
    setOpenBuyItem(true)
  }

  useEffect(()=>{
    if(customer){
      dispatch(setReceiptCustomer(customer))
    }else{
      dispatch(setReceiptCustomer(null))
    }
  },[customer])

  useEffect(() => {
    if (response && response.data){
      setCustomers(response.data);
    }
  }, [response]);

  useEffect(() => {
    fetchCustomers();
  }, [searchQuery, pageNo]);

  useEffect(()=>{
    dispatch(setReceiptCustomer(null))
    setCustomer(null)
  },[])

  return (
    <Box
      sx={{
        bgcolor: theme.palette.common.white,
        borderRadius: "10px",
        minHeight: '100vh',
        width: '100%',
        p: 3,
        overflow: 'hidden'
      }}
    >
      <AlertDialog
        toggle={showAlert}
        setToggle={setShowAlert}
        cancel={handleCancel}
        confrim={handleConfirm}
        title={"Sorry"}
        content={"You need to choose a customer!"}
      ></AlertDialog>
      <UseMembership open={openUseMembership} setOpen={setOpenUseMembership}></UseMembership>
      <BuyGoldCard open={openBuyGoldCard} setOpen={setOpenBuyGoldCard}></BuyGoldCard>
      <BuyMembership open={openBuyMembership} setOpen={setOpenBuyMembership} />
      <BuyItem open={openBuyItem} setOpen={setOpenBuyItem} ></BuyItem>
      <BuyBeauticianService open={openBuyBeauticianService} setOpen={setOpenBuyBeauticianService}></BuyBeauticianService>
      <BuyService open={openBuyService} setOpen={setOpenBuyService}></BuyService>
      <Box sx={{ width: 300, mb: 2 }}>
        <AsyncSelect
          placeholder={"Select Customer"}
          loading={loading}
          value={customer?.name || ""}
          setValue={setCustomer}
          pageNo={pageNo}
          searchText={searchQuery}
          options={customers}
          setSearchText={setSearchQuery}
          setPageNo={setPageNo}
          hasMore={pageNo < response?.meta?.last_page}
          position='absolute'
        ></AsyncSelect>
      </Box>
      {
        !selectedCustomer?.isGoldMember && !(collectedGoldcard.length > 0) &&
        <Button onClick={buyGoldCardToggle} sx={{ mr: 2, mt: 1, minWidth: 120 }} variant="contained" endIcon={<AddCircleIcon />}>
          gold card
        </Button>
      }
      <Button onClick={buyMembershipToggle} sx={{ mr: 2, mt: 1, minWidth: 120 }} variant="contained" endIcon={<AddCircleIcon />}>
        membership
      </Button>
      <Button onClick={useMembershipToggle} sx={{ mr: 2, mt: 1, minWidth: 120 }} variant="contained" endIcon={<AddCircleIcon />}>
        use membership
      </Button>
      <Button onClick={buyBeauticianServiceToggle} sx={{ mr: 2, mt: 1, minWidth: 120 }} variant="contained" endIcon={<AddCircleIcon />}>
        beauticians' service
      </Button>
      <Button onClick={buyServiceToggle} sx={{ mr: 2, mt: 1, minWidth: 120 }} variant="contained" endIcon={<AddCircleIcon />}>
        service
      </Button>
      <Button onClick={buyItemToggle} sx={{ mr: 2, mt: 1, minWidth: 120 }} variant="contained" endIcon={<AddCircleIcon />}>
        item
      </Button>
      <SaveVoucher></SaveVoucher>
    </Box>
  );
};

export default CreateSale;
