import React, { useEffect, useState } from 'react'
import { Box, Button, Toolbar, Typography, alpha } from '@mui/material';
import theme from '../../utils/theme';
import { useLocation, useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import ShopMenu from '../utils/ShopMenu';
import { decryptData } from '../../utils/decrypt';
import { authName } from '../../utils/config';
import ProfilePopover from './ProfilePopover';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

const ApplicationBar = ({ drawerWidth }) => {

  const { id } = useParams()
  let title = '';
  const location = useLocation()
  const navigate = useNavigate()
  const path = location.pathname
  const parts = path.split('/');
  const [ accountType,setAccountType ] = useState(null)

  let lastPart;
  if(parts[3]) {
    lastPart = parts[2] + '/' + parts[3];
  } else {
    lastPart = parts[parts.length -1];
  }

  switch (lastPart) {
    case '':
      title = 'Hua Mei Admin Dashboard';
      break;
    case 'shops-list':
      title = 'Shops List';
      break;
    case 'sales-and-services':
      title = 'Sales & Services List';
      break;
    case 'create-sale-voucher':
      title = 'Create Voucher';
      break;
    case `sale-voucher-detail/${id}`:
      title = 'Sale Voucher Detail';
      break;
    case `sale-voucher-print/${id}`:
      title = 'Sale Voucher Print';
      break;
    case 'purchases':
      title = 'Purchases';
      break;
    case 'create-purchase-voucher':
      title = 'Create Voucher';
      break;
    case `purchase-voucher-detail/${id}`:
      title = 'Purchase Voucher Detail';
      break;
    case 'items':
      title = 'Items';
      break;
    case `stock-detail/${id}`:
      title = 'Stock Detail';
      break;
    case 'services':
      title = 'Services';
      break;
    case "beautician-services-by-employee":
      title = 'Beauticians\' Services By Employee';
      break;
    case 'customers':
      title = 'Customers List';
      break;
    case 'members':
      title = 'Members List';
      break;
    case 'beauticians':
      title = 'Beauticians List';
      break;
    case 'assistants':
        title = 'Assistants List';
        break;
    case 'create-new-staff':
      title = 'Create New Staff';
      break;
    case `staff-detail/${id}`:
      title = 'Staff Detail';
      break;
    case 'attendance':
      title = 'Attendance';
      break;
    case 'salary':
      title = 'Salary';
      break;
    case 'cash-advance':
      title = 'Salary Advance';
      break;
    case 'expenses':
      title = 'Expenses';
      break;
    case 'report':
      title = 'Report';
      break;
    case `detail/${id}`:
      title = 'Daily Report';
      break;
    case 'user-management':
      title = 'User Management';
      break;
    case 'gold-cards':
      title = 'Gold Card Sales';
      break;
    case `permission-controls-for-user/${id}`:
      title = 'Permission Controls for User';
      break;
    default:
      title = '';
      break;
  }

  const handleRefresh = ()=>{
    navigate("?page=1")
    // navigate(0)
  }

  useEffect(()=>{
    if(!localStorage.getItem(authName)) return;
    const localData = localStorage.getItem(authName)
    setAccountType(JSON.parse(decryptData(localData)).user.type)
  },[])

  return (
      <Toolbar sx={{ 
        bgcolor: alpha(theme.palette.common.white, 0.95),
        width: '100%',
        ml: `${drawerWidth + 5}px`,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
        <Typography variant='h6' fontFamily='Poppins' sx={{ color: theme.palette.common.black }}>
          {title}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <ProfilePopover></ProfilePopover>
          {
            accountType === 'owner' &&
            <ShopMenu></ShopMenu>
          }
          <Button onClick={()=> handleRefresh() } variant='contained' color='primary' size='large' sx={{ ml: 2 }} startIcon={<RestartAltIcon/>}>Refresh</Button>
        </Box>
      </Toolbar>
  )
}

export default ApplicationBar
