    import React, { useEffect, useState } from 'react';
    import { useDispatch, useSelector } from 'react-redux';
    import { Box, Button, Dialog, DialogContent, DialogTitle ,FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup, TextField, Typography } from "@mui/material"
    import { useForm } from 'react-hook-form';
    import { yupResolver } from '@hookform/resolvers/yup';
    import * as yup from 'yup';
    import theme from '../../utils/theme';
    import { getAllStaff } from '../../slices/staffSlice';
    import AsyncSelect from '../utils/AsyncSelect';
    import { beauticianServiceByEmployeeCrud } from '../../slices/serviceSlice';
    import NormalSelect from '../utils/NormalSelect';
    import { collectBeauticianServiceForSale } from '../../slices/receiptSlice';
    import { nanoid } from '@reduxjs/toolkit';
    import { shopId } from "../../utils/config";

    const validationSchema = yup.object().shape({
        service: yup.object().nullable().required('Please select a service!'),
        beautician: yup.object().nullable().required("Please select a beautician!").typeError("Please select a beautician!"),
        beauticianStatus: yup.string().required('Please select an option!'),
        assistant: yup.object().nullable().typeError("Please select an assistant!"),
        assistantStatus: yup.string().nullable(),
        quantity: yup.number().required('Please Add Quantity!').typeError('Your Quantity is Wrong!')
    })

    const BuyBeauticianService = ({open,setOpen}) => {
        const dispatch = useDispatch()
        const [ searchTextAssistant,setSearchTextAssistant ] = React.useState("")
        const [ searchTextBeautician,setSearchTextBeautician ] = React.useState("")
        const [ beautician, setBeautician ] = React.useState(null);
        const [ beauticians, setBeauticians ] = useState([]);
        const [ assistant, setAssistant ] = useState(null)
        const [ assistants,setAssistants ] = useState([])
        const [ relevantServices,setRelevantServices ] = useState([])
        const [ selectedService,setSelectedService ] = useState(null)
        const [ assistantPageNo, setAssistantPageNo ] = useState(1);
        const [ beauticianPageNo, setBeauticianPageNo] = useState(1);
        const [ assistantStatus,setAssistantStatus ] = useState(null)
        const [ beauticianStatus,setBeauticianStatus ] = useState(null)
        const [ selectedBeauticianServiceId,setSelectedBeauticianServiceId ] = useState(null)

        const { beauticians: beauticiansResponse, loading: beauticiansLoading } = useSelector(
            (state) => state.Staff
        );

        const { assistants: assistantsResponse, loading: assistantsLoading } = useSelector(
            (state) => state.Staff
        );
        
        const handleClose = ()=>{
            reset()
            setBeautician(null)
            setAssistant(null)
            setAssistantStatus(null)
            setBeauticianStatus(null)
            setAssistantPageNo(1)
            setBeauticianPageNo(1)
            setOpen(false)
        }

        const {
            handleSubmit,
            formState: { errors },
            reset,
            setValue,
            trigger,
            register
        } = useForm({
            resolver: yupResolver(validationSchema),
        });

        const fetchStaffs = async (isAdvance) => {
            await dispatch(
            getAllStaff({
                method: "get",
                data: null,
                shop: shopId(),
                keyword: isAdvance ? searchTextBeautician : searchTextAssistant,
                scrollPage: isAdvance ? beauticianPageNo : assistantPageNo,
                isAdvance: isAdvance,
                advancedServiceId: (isAdvance === false && selectedBeauticianServiceId) ? selectedBeauticianServiceId : null 
            })
            );
        };

        const fetchBeauticianServiceByEmployee = async()=>{
            const dispatchData = await dispatch(beauticianServiceByEmployeeCrud({ method: 'get', data: null, employeeId: beautician?.id, isDisable: null}))
            dispatchData.payload && dispatchData.payload.data && setRelevantServices(dispatchData.payload.data)
        }

        const handleOnSubmit = (data)=>{
            const formattedData = {
                id: nanoid(),
                manualDiscount: 0,
                ...data
            }
            dispatch(collectBeauticianServiceForSale(formattedData))
            handleClose()
        }

        useEffect(()=>{
            if(beauticianStatus){
                setValue('beauticianStatus', beauticianStatus)
                trigger('beauticianStatus')
            }
        },[beauticianStatus])

        useEffect(()=>{
            if(assistantStatus){
                setValue('assistantStatus', assistantStatus)
                trigger('assistantStatus')
            }
        },[assistantStatus])

        useEffect(() => {
            beauticiansResponse && beauticiansResponse.data &&
            setBeauticians(beauticiansResponse.data);
        }, [beauticiansResponse]);

        useEffect(() => {
            if(assistantsResponse && assistantsResponse.data){
                const assistantsData = assistantsResponse.data
                setAssistants(assistantsData);
            }
        }, [assistantsResponse]);

        React.useEffect(() => {
            if(selectedBeauticianServiceId){
                fetchStaffs(false);
            }
        }, [searchTextAssistant, assistantPageNo, selectedBeauticianServiceId]);

        React.useEffect(() => {
            fetchStaffs(true);
        }, [searchTextBeautician, beauticianPageNo]);

        React.useEffect(() => {
            if(assistant) {
            setValue("assistant", assistant);
            trigger("assistant");
            }else if(!assistant) {
            setValue("assistant", null);
            setValue("assistantStatus", null)
            setAssistantStatus(null)
            }
        }, [assistant]);

        React.useEffect(() => {
            if(beautician) {
                fetchBeauticianServiceByEmployee()
                setValue("beautician", beautician);
                trigger("beautician");
            }else if(!beautician) {
                setValue("beautician", null);
                setValue("assistant", null);
                setValue('beauticianStatus', null)
                setValue('assistantStatus', null)
                setAssistant(null)
                setBeauticianStatus(null)
                setAssistantStatus(null)
                setRelevantServices([])
            }
            setSelectedService(null)
        }, [beautician]);

        useEffect(()=>{
            if(selectedService){
            setValue('service', selectedService)
            trigger('service')
            setValue('assistant', null)
            setAssistant(null)
            setSelectedBeauticianServiceId(selectedService.id);
            }else{
            setValue('service', null)
            setSelectedService(null)
            }
        },[selectedService])

        useEffect(()=>{
            setSearchTextAssistant("")
            setSearchTextBeautician("")
            setSelectedBeauticianServiceId(null)
        },[open])

        return (
            <Dialog open={open} onClose={handleClose}>
                <DialogContent sx={{ width: 400 }}>
                    <DialogTitle sx={{ pt: 0, pl: 0 }}>Buy Beautician's Service</DialogTitle>
                    <form autoComplete='off' onSubmit={handleSubmit(handleOnSubmit)}>
                        {/* select service */}
                        <AsyncSelect
                            placeholder={"Select Beautician"}
                            loading={beauticiansLoading}
                            value={beautician?.name || ""}
                            setValue={setBeautician}
                            pageNo={beauticianPageNo}
                            searchText={searchTextBeautician}
                            options={beauticians}
                            setSearchText={setSearchTextBeautician}
                            setPageNo={setBeauticianPageNo}
                            hasMore={beauticianPageNo < beauticiansResponse?.meta?.last_page}
                            error={errors?.beautician}
                            position={'absolute'}
                        ></AsyncSelect>
                        <FormControl disabled={!beautician} sx={{ mt: 2 }}>
                            <RadioGroup
                                value={beauticianStatus}
                                onClick={(e)=>{
                                    if(e.target.value){
                                        setValue("beauticianStatus", e.target.value)
                                        setBeauticianStatus(e.target.value)
                                    }
                                }}
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                            >
                                <FormControlLabel value="normal" control={<Radio />} label={<Typography sx={{ fontSize: '12px' }}>Normal</Typography>} />
                                <FormControlLabel value="byname" control={<Radio />} label={<Typography sx={{ fontSize: '12px' }}>Byname</Typography>} />
                            </RadioGroup>
                            <FormHelperText sx={{ color: theme.palette.danger.main }}>{errors?.beauticianStatus?.message}</FormHelperText>
                        </FormControl>
                        <Box sx={{ mb: 2 }}></Box>
                        {/* select beautician's service */}
                        <NormalSelect
                        label={"Select Service"}
                        size={"small"}
                        value={selectedService}
                        setValue={setSelectedService}
                        options={relevantServices}
                        error={errors.service}
                        optionLabel={'name'}
                        ></NormalSelect>
                        <Box sx={{ mb: 3 }}></Box>
                        <AsyncSelect
                            placeholder={"Select Assistant"}
                            loading={assistantsLoading}
                            value={assistant?.name || ""}
                            setValue={setAssistant}
                            pageNo={assistantPageNo}
                            searchText={searchTextAssistant}
                            options={assistants}
                            setSearchText={setSearchTextAssistant}
                            setPageNo={setAssistantPageNo}
                            error={errors?.assistant}
                            hasMore={assistantPageNo < assistantsResponse?.meta?.last_page}
                            position='absolute'
                        ></AsyncSelect>
                        <FormControl disabled={!assistant} sx={{ my: 2 }}>
                            <RadioGroup
                                value={assistantStatus}
                                onClick={(e)=>{
                                    if(e.target.value){
                                        setValue("assistantStatus", e.target.value)
                                        setAssistantStatus(e.target.value)
                                    }
                                }}
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                            >
                                <FormControlLabel value="normal" control={<Radio />} label={<Typography sx={{ fontSize: '12px' }}>Normal</Typography>} />
                                <FormControlLabel value="byname" control={<Radio />} label={<Typography sx={{ fontSize: '12px' }}>Byname</Typography>} />
                            </RadioGroup>
                            <FormHelperText sx={{ color: theme.palette.danger.main }}>{errors?.assistantStatus?.message}</FormHelperText>
                        </FormControl>
                        <TextField
                            fullWidth
                            size='small'
                            label={'Quantity'}
                            {...register('quantity')}
                            error={!!errors.quantity}
                            helperText={errors?.quantity?.message}
                        ></TextField>
                        <Box sx={{ mt: 3, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <Button type='button' variant="text" sx={{ mr: 2 }} onClick={handleClose} >Cancel</Button>
                            <Button type='submit' variant="contained">Buy</Button>
                        </Box>
                    </form>
                </DialogContent>
            </Dialog>
        )
    }

    export default BuyBeauticianService