import React, { useEffect } from 'react'
import { Dialog, DialogContent, DialogTitle, TextField, Button, Box } from '@mui/material'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch } from "react-redux";
import { goldCardRU } from '../../slices/goldCardSlice';

const validationSchema =  yup.object().shape({
    price: yup.number().required('Please enter price!').typeError('Your price is Wrong!'),
    commission: yup.number().required('Please enter commission!').typeError('Your commission is Wrong!'),
    itemDiscountPercent: yup.number().required('Please enter item discount percent!').typeError('Your item discount percent is Wrong!'),
    advancedServiceDiscountPercent: yup.number().required('Please enter service discount percent!').typeError('Your service discount percent is Wrong!'),
})

const UpdateGoldCardInfo = ({open,setOpen,info}) => {
    const dispatch = useDispatch()

    const fetchData = async()=>{
        await dispatch(goldCardRU({ method: 'get', data: null }))
    }

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        trigger,
        reset
    } = useForm({
        resolver: yupResolver(validationSchema)
    })

    const handleClose = ()=>{
        setOpen(false)
        reset()
    }

    const onSubmit = async (data)=>{
        await dispatch(goldCardRU({ method: 'put', data: data, id: info?.id }))
        await fetchData()
        handleClose()
    }

    useEffect(()=>{
        if(info){
            setValue('price', info.price)
            setValue('commission', info.commission)
            setValue('itemDiscountPercent', info.itemDiscountPercent)
            setValue('advancedServiceDiscountPercent', info.advancedServiceDiscountPercent)
            trigger(['price','commission','itemDiscountPercent', 'advancedServiceDiscountPercent'])
        }
    },[info, open])

  return (
    <Dialog open={open} onClose={handleClose}>
        <DialogContent sx={{ width: 400 }}>
            <DialogTitle sx={{ pl: 0, pt: 0 }}>Update Gold Card Info</DialogTitle>
            <form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
                <TextField
                    fullWidth
                    error={!!errors?.price}
                    helperText={errors?.price?.message}
                    {...register('price')}
                    sx={{ mb: 3 }}
                    size='small'
                    label={'Price'}>
                </TextField>
                <TextField
                    fullWidth
                    error={!!errors?.commission}
                    helperText={errors?.commission?.message}
                    {...register('commission')}
                    sx={{ mb: 3 }}
                    size='small'
                    label={'Commission'}>
                </TextField>
                <TextField
                    fullWidth
                    error={!!errors?.itemDiscountPercent}
                    helperText={errors?.itemDiscountPercent?.message}
                    {...register('itemDiscountPercent')}
                    sx={{ mb: 3 }}
                    size='small'
                    label={'Item Discount Percent'}>
                </TextField>
                <TextField
                    fullWidth
                    error={!!errors?.advancedServiceDiscountPercent}
                    helperText={errors?.advancedServiceDiscountPercent?.message}
                    {...register('advancedServiceDiscountPercent')}
                    sx={{ mb: 3 }}
                    size='small'
                    label={'Service Discount Percent'}>
                </TextField>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Button type='button' variant="text" sx={{ mr: 2 }} onClick={handleClose} >Cancel</Button>
                    <Button type='submit' variant="contained">Update</Button>
                </Box>
            </form>
        </DialogContent>
    </Dialog>
  )
}

export default UpdateGoldCardInfo