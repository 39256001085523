import React, { Fragment, useEffect, useState } from "react";
import { Box, Button, FormControl, IconButton, InputAdornment, OutlinedInput, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import NoDataAlert from '../utils/NoDataAlert';
import AlertDialog from "../utils/AlertDialog";
import { removeCollectedItem, resetCollected, updateManualDiscount } from "../../slices/receiptSlice";
import { calcDiscount, validateSeasonalDiscount } from "../../utils/discountFunctions";
import { format } from "date-fns";
import { createSaleReceipt } from "../../utils/createSaleReceipt";
import { useNavigate } from "react-router-dom";

const validationSchema = yup.object().shape({
    cash: yup.number().positive().min(0).required('Please add cash!').typeError('Your cash is Wrong!'),
    kpay: yup.number().positive().min(0).required('Please add kpay!').typeError('Your kpay is Wrong!'),
    tax: yup.number().typeError('Your tax value is Wrong!'),
});

const SaveVoucher = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [ saveDisable,setSaveDisable ] = useState(true)
    const [ isValidSeasonalDiscount, setIsValidSeasonalDiscount] = useState(false);
    const [ itemSeasonalDiscount, setItemSeasonalDiscount] = useState(null);
    const [ serviceSeasonalDiscount, setServiceSeasonalDiscount] = useState(null);
    const [ itemGoldCardDiscount, setItemGoldCardDiscount] = useState(false);
    const [ serviceGoldCardDiscount, setServiceGoldCardDiscount] = useState(null);
    const [ showAlert, setShowAlert] = useState(false);
    const [ showAlertGoBack, setShowAlertGoBack] = useState(false);
    const [ subtotal, setSubtotal] = useState(0);
    const [ tableItemsToShow, setTableItemsToShow] = useState([]);
    const [ tax, setTax] = useState(0);
    const [ grandTotal, setGrandTotal] = useState(0);
    const { customer: selectedCustomer, collectedGoldcard, collectedMemberships, collectedMembershipUsages, collectedServices, collectedBeauticianServices, collectedItems, loading } = useSelector(state => state.Receipt);
    const { goldCardInfo } = useSelector(state => state.GoldCard);
    const { seasonalDiscount } = useSelector(state => state.SeasonalDiscount);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset
    } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            'kpay': 0,
            'cash': 0,
            'tax': 0,
        }
    });

    const changeItemFormat = (itemData, type) => {
        if (type === 'goldCard') {
            return {
                id: itemData.id,
                name: `Gold Card (code- ${itemData.code})`,
                mainStaff: itemData.staff,
                price: goldCardInfo ? Number(goldCardInfo[0].price) : 0,
                qty: 1,
                subtotal: goldCardInfo ? Number(goldCardInfo[0].price) : 0,
                total: goldCardInfo ? Number(goldCardInfo[0].price) : 0,
            };
        } else if (type === 'membership') {
            return {
                id: itemData.id,
                service: itemData.service,
                name: `Membership ( ${itemData.service.name} )`,
                mainStaff: itemData.staff,
                price: Number(itemData.servicePrice.price),
                qty: Number(itemData.freeTime + itemData.time),
                subtotal: Number(itemData.servicePrice.price) * Number(itemData.time),
                total: Number(itemData.servicePrice.price) * Number(itemData.time)
            };
        } else if (type === 'membershipUsage'){
            return {
                id: itemData.id,
                name: `Membership Usage ( ${itemData.membershipRec.serviceName} )`,
                mainStaff: itemData.staff,
                price: 0,
                qty: itemData.quantity,
                additionalCost: itemData.additionalCost,
                subtotal: itemData.additionalCost,
                total: itemData.additionalCost
            }
        } else if (type === 'beauticianService') {
            let discountPercent;
            if (itemData.service.isDiscounted && isValidSeasonalDiscount) {
                discountPercent = serviceSeasonalDiscount;
            } else if (itemData.service.isDiscounted && selectedCustomer.isGoldMember) {
                discountPercent = serviceGoldCardDiscount;
            } else {
                discountPercent = null;
            }
            return {
                id: itemData.id,
                service: itemData.service,
                name: itemData.service.name,
                mainStaff: [{ ...itemData.beautician, status: itemData.beauticianStatus }],
                assistantStaff: itemData.assistantStatus ? [{ ...itemData.assistant, status: itemData.assistantStatus }] : null,
                price: itemData.service.price,
                qty: itemData.quantity,
                subtotal: Number(itemData.service.price) * Number(itemData.quantity),
                discount: discountPercent,
                manualDiscount: itemData.manualDiscount,
                total: calcDiscount((Number(itemData.service.price) * Number(itemData.quantity)), discountPercent) - Number(itemData.manualDiscount)
            };
        } else if (type === 'service') {
            return {
                id: itemData.id,
                service: itemData.service,
                name: itemData.service.name,
                mainStaff: [{ ...itemData.staff, status: itemData.status }],
                price: itemData.servicePrice.price,
                qty: itemData.quantity,
                subtotal: Number(itemData.servicePrice.price) * Number(itemData.quantity),
                manualDiscount: itemData.manualDiscount,
                total: (Number(itemData.servicePrice.price) * Number(itemData.quantity)) - Number(itemData.manualDiscount)
            };
        } else if (type === 'item') {
            const discountPercent = isValidSeasonalDiscount ? itemSeasonalDiscount : selectedCustomer.isGoldMember ? itemGoldCardDiscount : null;
            return {
                id: itemData.id,
                item: itemData.item,
                name: itemData.item.name,
                mainStaff: itemData.staff,
                price: itemData.item.salePrice,
                qty: itemData.quantity,
                subtotal: Number(itemData.item.salePrice) * Number(itemData.quantity),
                discount: discountPercent,
                manualDiscount: itemData.manualDiscount,
                total: calcDiscount((Number(itemData.item.salePrice) * Number(itemData.quantity)), discountPercent) - Number(itemData.manualDiscount)
            };
        }
    };

    const handleRemove = (id) => {
        const removedItems = tableItemsToShow.filter(item => item.id !== id);
        setTableItemsToShow(removedItems);
        dispatch(removeCollectedItem({ removeId: id }));
    };

    const SaleTableRow = ({ voucherItem, index }) => {
        const [manualDiscountAmount, setManualDiscountAmount] = useState(0);

        const handleChange = (value) => {
            setManualDiscountAmount(value);
        };

        const updateDiscount = async (updateId) => {
            await dispatch(updateManualDiscount({ value: manualDiscountAmount, updateId: updateId }));
        };

        return (
            <TableRow>
                <TableCell align="center">{index + 1}</TableCell>
                <TableCell>{voucherItem.name}</TableCell>
                <TableCell>{voucherItem.mainStaff.map((employee) => `${employee.name} ${employee.status ? `(${employee.status})` : ""}, `)}</TableCell>
                <TableCell>{voucherItem.assistantStaff ? voucherItem.assistantStaff.map((employee) => `${employee.name} ${employee.status ? `(${employee.status})` : ""}, `) : '-'}</TableCell>
                <TableCell align="center">{voucherItem.price}</TableCell>
                <TableCell align="center">{voucherItem.qty}</TableCell>
                <TableCell align="center">{voucherItem.subtotal}</TableCell>
                <TableCell align="center">{voucherItem.discount ? `${voucherItem.discount} %` : '-'}</TableCell>
                <TableCell align="center">
                    {voucherItem.manualDiscount != null &&
                        <FormControl size="small" sx={{ m: 1, width: 150 }} variant="outlined">
                            <OutlinedInput
                                defaultValue={voucherItem.manualDiscount}
                                onChange={(event) => handleChange(event.target.value)}
                                sx={{ pr: 0.5 }}
                                id="manual_discount"
                                type='number'
                                endAdornment={
                                    <InputAdornment position="end">
                                        <Button onClick={() => updateDiscount(voucherItem.id)} size="small" variant="contained">Save</Button>
                                    </InputAdornment>
                                }
                                placeholder="Enter Amount"
                            />
                        </FormControl>
                    }
                </TableCell>
                <TableCell align="center">{voucherItem.additionalCost ? Number(voucherItem.additionalCost).toLocaleString() : "-"}</TableCell>
                <TableCell align="center">
                  {(Number(voucherItem.total)).toLocaleString()}
                </TableCell>
                <TableCell align="center">
                    <IconButton onClick={() => handleRemove(voucherItem.id)}>
                        <DeleteIcon color="danger" />
                    </IconButton>
                </TableCell>
            </TableRow>
        );
    };

    const handleCancel = () => {
        setShowAlert(false);
    };

    const handleConfirm = () => {
        setShowAlert(false);
    };

    const handleCancelGoBack = () => {
      setShowAlertGoBack(false);
    };

    const handleConfirmGoBack = () => {
        setShowAlertGoBack(false);
        navigate(-1)
    };

    const handleOnSubmit = async (data) => {
        await createSaleReceipt(data,dispatch, navigate, selectedCustomer,collectedGoldcard, collectedMemberships, collectedMembershipUsages ,collectedBeauticianServices, collectedServices, collectedItems)
    };

    const updateTableItems = (collectedItems, changeItemFormat, type, setTableItemsToShow) => {
        collectedItems.forEach(item => {
            setTableItemsToShow(prev => {
                const existingItemIndex = prev.findIndex(prevItem => prevItem.id === item.id);
                if (existingItemIndex !== -1) {
                    const updatedItems = [...prev];
                    updatedItems[existingItemIndex] = changeItemFormat(item, type);
                    return updatedItems;
                } else {
                    return [...prev, changeItemFormat(item, type)];
                }
            });
        });
    };

    const handleGoBack = ()=>{
      setShowAlertGoBack(true);
    }

    useEffect(() => {
        if (seasonalDiscount) {
            const today = format(new Date(), 'yyyy-MM-dd');
            const isValid = validateSeasonalDiscount(seasonalDiscount.startDate, seasonalDiscount.endDate, today);
            setIsValidSeasonalDiscount(isValid);
            setServiceSeasonalDiscount(seasonalDiscount.advancedServiceDiscountPercent);
            setItemSeasonalDiscount(seasonalDiscount.itemDiscountPercent);
        }
    }, [seasonalDiscount]);

    useEffect(() => {
        if (goldCardInfo) {
            setServiceGoldCardDiscount(goldCardInfo[0].advancedServiceDiscountPercent);
            setItemGoldCardDiscount(goldCardInfo[0].itemDiscountPercent);
        }
    }, [goldCardInfo]);

    useEffect(() => {
        const total = Number(subtotal) + Number(tax);
        setGrandTotal(total);
        setValue('cash', total);
        setValue('kpay', 0)
    }, [subtotal, tax]);

    useEffect(() => {
        if (collectedGoldcard) {
            updateTableItems(collectedGoldcard, changeItemFormat, 'goldCard', setTableItemsToShow);
        }
    }, [collectedGoldcard]);

    useEffect(() => {
        if (collectedMemberships) {
            updateTableItems(collectedMemberships, changeItemFormat, 'membership', setTableItemsToShow);
        }
    }, [collectedMemberships]);

    useEffect(() => {
        if (collectedMembershipUsages) {
            updateTableItems(collectedMembershipUsages, changeItemFormat, 'membershipUsage', setTableItemsToShow);
        }
    }, [collectedMembershipUsages]);

    useEffect(() => {
        if (collectedBeauticianServices) {
            updateTableItems(collectedBeauticianServices, changeItemFormat, 'beauticianService', setTableItemsToShow);
        }
    }, [collectedBeauticianServices]);

    useEffect(() => {
        if (collectedServices) {
            updateTableItems(collectedServices, changeItemFormat, 'service', setTableItemsToShow);
        }
    }, [collectedServices]);

    useEffect(() => {
        if (collectedItems) {
            updateTableItems(collectedItems, changeItemFormat, 'item', setTableItemsToShow);
        }
    }, [collectedItems]);

    useEffect(() => {
        if (tableItemsToShow.length > 0) {
            const total = tableItemsToShow.reduce((acc, currentItem) => acc += Number(currentItem.total), 0);
            setSubtotal(total);
            setSaveDisable(false)
        }else{
            setSubtotal(0);
            setSaveDisable(true)
        }
    }, [tableItemsToShow]);

    useEffect(()=>{
      dispatch(resetCollected())
      setTableItemsToShow([])
    },[selectedCustomer])

    useEffect(() => {
        setTableItemsToShow([]);
        dispatch(resetCollected());
        setTax(0)
        setSubtotal(0)
        setGrandTotal(0)
        reset()
    }, []);

    return (
      <Box
        sx={{
          pt: 3,
        }}
      >
        <AlertDialog
          toggle={showAlert}
          setToggle={setShowAlert}
          cancel={handleCancel}
          confrim={handleConfirm}
          title={"Warning !"}
          content={"You need to select the customer."}
        ></AlertDialog>
        <AlertDialog
          toggle={showAlertGoBack}
          setToggle={setShowAlertGoBack}
          cancel={handleCancelGoBack}
          confrim={handleConfirmGoBack}
          title={"Are you sure !"}
          content={"You want to go back without saving your voucher"}
        ></AlertDialog>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <Box
            sx={{
              borderRadius: "10px",
            }}
          >
            <TableContainer sx={{ borderRadius: "10px", minWidth: 650, pb: 3 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontSize: '12px' }} align="center">No</TableCell>
                    <TableCell sx={{ fontSize: '12px' }}>Item Or Service</TableCell>
                    <TableCell sx={{ fontSize: '12px' }}>Main Staff</TableCell>
                    <TableCell sx={{ fontSize: '12px' }}>Assistant Staff</TableCell>
                    <TableCell sx={{ fontSize: '12px' }} align="center">Price</TableCell>
                    <TableCell sx={{ fontSize: '12px' }} align="center">Qty</TableCell>
                    <TableCell sx={{ fontSize: '12px' }} align="center">Subtotal</TableCell>
                    <TableCell sx={{ fontSize: '12px' }} align="center">Discount</TableCell>
                    <TableCell sx={{ fontSize: '12px' }} align="center">Manual Discount</TableCell>
                    <TableCell sx={{ fontSize: '12px' }} align="center">Additional Cost</TableCell>
                    <TableCell sx={{ fontSize: '12px' }} align="center">Total</TableCell>
                    <TableCell sx={{ fontSize: '12px' }} align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableItemsToShow.length > 0 ? (
                    tableItemsToShow.map((item, index) => (
                      <Fragment key={index}>
                        <SaleTableRow
                          voucherItem={item}
                          index={index}
                        ></SaleTableRow>
                      </Fragment>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={12}>
                        <NoDataAlert
                          content={"There is no items"}
                        ></NoDataAlert>
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell
                      sx={{
                        borderBottom: "0px",
                        pt: 3,
                        pb: 1,
                        fontSize: "14px",
                      }}
                      align="right"
                      colSpan={9}
                    >
                      Subtotal
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "0px", py: 1, fontSize: "14px" }}
                      align="center"
                      colSpan={3}
                    >
                      {subtotal.toLocaleString()}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{ borderBottom: "0px" }}
                      colSpan={6}
                    ></TableCell>
                    <TableCell
                      sx={{ borderBottom: "0px", py: 1, fontSize: "14px" }}
                      align="right"
                      colSpan={3}
                    >
                      <TextField
                        disabled={saveDisable}
                        error={!!errors?.tax}
                        helperText={errors?.tax?.message}
                        label="Tax"
                        onChange={(event) =>{
                            setTax(event.target.value)
                            setValue('tax', event.target.value)
                        }}
                        fullWidth
                        size="small"
                      ></TextField>
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "0px", py: 1, fontSize: "14px" }}
                      align="center"
                      colSpan={3}
                    >
                      {tax.toLocaleString()}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        borderBottom: "0px",
                        pt: 3,
                        pb: 1,
                        fontSize: "14px",
                      }}
                      align="right"
                      colSpan={9}
                    >
                      Total
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "0px",
                        py: 1,
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                      align="center"
                      colSpan={3}
                    >
                      {grandTotal.toLocaleString()}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer sx={{ borderRadius: "10px", width: 400, ml: "auto", mt: 3 }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell
                      sx={{
                        borderBottom: "0px",
                        fontSize: "14px",
                        verticalAlign: "top",
                      }}
                      align="right"
                    >
                      Paid
                    </TableCell>
                    <TableCell
                      sx={{ borderBottom: "0px", py: 1, fontSize: "14px" }}
                      align="right"
                    >
                      <TextField
                        disabled={saveDisable}
                        {...register("cash")}
                        error={!!errors?.cash}
                        helperText={errors?.cash?.message}
                        sx={{ mb: 3 }}
                        fullWidth
                        size="small"
                        label="Paid (Cash)"
                        onChange={(event)=> {
                            setValue('kpay', Number(grandTotal)- Number(event.target.value))
                        }}
                      ></TextField>
                      <TextField
                        disabled={saveDisable}
                        {...register("kpay")}
                        error={!!errors?.kpay}
                        helperText={errors?.kpay?.message}
                        fullWidth
                        size="small"
                        label="Paid (Kpay)"
                        onChange={(event)=> {
                            setValue('cash', Number(grandTotal)- Number(event.target.value))
                        }}
                      ></TextField>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        borderBottom: "0px",
                        fontSize: "14px",
                        verticalAlign: "top",
                      }}
                      align="right"
                    ></TableCell>
                    <TableCell
                      sx={{ borderBottom: "0px", py: 1, fontSize: "14px" }}
                      align="right"
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', mt: 3 }}>
                        <Button sx={{ color: 'black' }} onClick={handleGoBack}>Cancel</Button>
                        <Button
                          type="submit"
                          variant="contained"
                          size="large"
                          sx={{ float: "inline-end", ml: 3 }}
                          disabled={saveDisable || loading || !selectedCustomer}
                        >
                          Save
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </form>
      </Box>
    );
};

export default SaveVoucher;
