import React, { useState } from 'react'
import { Tab, Tabs, Box } from "@mui/material"
import NormalServiceList from './NormalServiceList';
import BeauticianServices from './BeauticianServices';
import theme from '../utils/theme';

const ServicesList = () => {
    let [ bodyScreen, updateBodyScreen ] = useState(<NormalServiceList />);
    const [value, setValue] = useState(0);
    const [itemTypeList, setItemTypeList] = useState(
        [
            {
                name: 'Normal Services',
                isSelected: true
            },
            {
                name: "Beauticians' services",
                isSelected: false
            },
        ]
    )

    const handleChange = (event, newValue) => {
        setValue(newValue);
        const updatedList = itemTypeList.map((item, index) => ({
            ...item,
            isSelected: newValue === index,
        }));

        let updateScreen = bodyScreen;

        switch (newValue) {
            case 0:
                updateScreen = <NormalServiceList />
                break;
            case 1:
                updateScreen = <BeauticianServices />
                break;
            default:
                updateScreen = <Box></Box>
                break;
        }

        updateBodyScreen(updateScreen);

        setItemTypeList(updatedList);
    };

  return (
    <>
        <Tabs
            value={value}
            onChange={handleChange}
            aria-label="disabled tabs example"
            TabIndicatorProps={{
                style: {
                    // display: 'none'
                },
            }}
            sx={{
                borderTopLeftRadius: '10px',
                borderTopRightRadius: '10px',
                backgroundColor: '#fff',
                mb: 1
            }}
            >
                { itemTypeList.map((itemType, index) => (
                    <Tab
                        key={index}
                        label={itemType.name}
                        sx={{
                            fontSize: '16px',
                            color: theme.palette.dark.main,
                            textTransform: 'none',
                        }}
                    />
                ))}
            </Tabs>
            <Box sx={{
                bgcolor: theme.palette.common.white,
                borderBottomLeftRadius: '10px',
                borderBottomRightRadius: '10px',
                height: '100%'
            }}>
                {bodyScreen}
            </Box>
        </>
  )
}

export default ServicesList
