import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import StarIcon from '@mui/icons-material/Star';
import theme from '../../utils/theme';
import { TableCell, Typography } from '@mui/material';
import { formatCountNum } from '../../utils/formatCountNum';
import NoDataAlert from '../utils/NoDataAlert';

const EmployeeListTable = ({ employees }) => {
    const [sortedEmployees, setSortedEmployees] = React.useState({});
    
    React.useEffect(()=>{
        if(employees){
            const sortedEntries = Object.entries(employees).sort((a,b) => b[1]-a[1]);
            setSortedEmployees(sortedEntries);
        }
    },[employees]);

    return (
        <Box sx={{ mb: 3 }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Typography sx={{ fontSize: { xs: '16px', sm: '18px', lg: '20px', xl: '22px'}, pr: 2 }}>
                    Top Employees for this month
                </Typography>
                <StarIcon sx={{ color: theme.palette.warning.main, fontSize: '30px' }} />
                <StarIcon sx={{ color: theme.palette.warning.main, fontSize: '30px' }} />
                <StarIcon sx={{ color: theme.palette.warning.main, fontSize: '30px' }} />
            </Box>
            <Table aria-label="custom pagination table">
                <TableBody>
                    <TableRow>
                        <TableCell sx={{ fontWeight: 'bold' }} align='center'>No</TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>Name</TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>Amount</TableCell>
                    </TableRow>
                    {sortedEmployees && sortedEmployees.length > 0 ? (
                        sortedEmployees.slice(0, 5).map(([name, amount], index) => (
                            <TableRow key={index}>
                                <TableCell align='center'>{index + 1}</TableCell>
                                <TableCell>{name}</TableCell>
                                <TableCell>{formatCountNum(amount) || 0}</TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={3}>
                                <NoDataAlert content={'There is no staffs'} />
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>            
        </Box>
    );
}

export default EmployeeListTable;
