import { receiptCud } from "../slices/receiptSlice";
import { shopId } from "./config";

export const createSaleReceipt = async (
  paymentData,
  dispatch,
  navigate,
  customer,
  collectedGoldcard,
  collectedMemberships,
  collectedMembershipUsages,
  collectedBeauticianServices,
  collectedServices,
  collectedItems
) => {
  const formattedData = {
    ...paymentData,
    customerId: customer.id,
    shopId: shopId(),
  };

  // changing format for buying gold card
  if (collectedGoldcard && collectedGoldcard.length > 0) {
    formattedData.goldCard = {
      code: collectedGoldcard[0].code,
      employees: collectedGoldcard[0].staff.map((el) => {
        return { id: el.id };
      }),
    };
  }

  // changing format for buying membership
  if (collectedMemberships && collectedMemberships.length > 0) {
    formattedData.memberships = collectedMemberships.map((membership) => {
      return {
        serviceId: membership.service.id,
        servicePriceId: membership.servicePrice.id,
        time: membership.time,
        freeTime: membership.freeTime,
        commissionPercent: membership.commission,
        employees: membership.staff.map((el) => {
          return { id: el.id };
        }),
      };
    });
  }

  // changing format for using membership
  if (collectedMembershipUsages && collectedMembershipUsages.length > 0) {
    formattedData.membershipUsages = collectedMembershipUsages.map((record) => {
      return {
        id: record.membershipRec.id,
        status: record.status,
        quantity: record.quantity,
        additionalCost: record.additionalCost,
        // employees: [
        //     { id : record.staff.id }
        // ]
        employees: record.staff.map((el) => {
          return { id: el.id };
        }),
      };
    });
  }

  // changing format for buying beauticians' service
  if (collectedBeauticianServices && collectedBeauticianServices.length > 0) {
    formattedData.advancedServices = collectedBeauticianServices.map((beauService) => {
      return {
        id: beauService.service.id,
        quantity: beauService.quantity,
        manualDiscountPrice: beauService.manualDiscount,
        advancedEmployee: {
          id: beauService.beautician.id,
          status: beauService.beauticianStatus
        },
        assistantEmployee: beauService.assistant ? {
            id: beauService.assistant.id,
            status: beauService.assistantStatus
        } : null
      };
    });
  }

  // changing format for buying service
  if (collectedServices && collectedServices.length > 0) {
    formattedData.services = collectedServices.map((normalService) => {
      return {
        id: normalService.service.id,
        servicePriceId: normalService.servicePrice.id,
        quantity: normalService.quantity,
        manualDiscountPrice: normalService.manualDiscount,
        employees: [
            { id: normalService.staff.id }
        ],
        status: normalService.status
      };
    });
  }

  // changing format for buying item
  if (collectedItems && collectedItems.length > 0) {
    formattedData.items = collectedItems.map((item) => {
      return {
        id: item.item.id,
        quantity: item.quantity,
        manualDiscountPrice: item.manualDiscount,
        employees: item.staff.map((el) => {
            return { id: el.id };
          }),
      };
    });
  }

  const dispatchData = await dispatch(receiptCud({ method: "post", data: formattedData, type: "sale" }));
  if(dispatchData.meta.requestStatus === 'fulfilled'){
    navigate('/sales-and-services')
  }
};
