import { Dialog, DialogContent, DialogTitle, TextField, Button, Box } from '@mui/material'
import React from 'react'
import AsyncAutoComplete from '../utils/AsyncAutoComplete';
import { useDispatch, useSelector } from "react-redux";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { getInfoFromLocal, shopId } from "../../utils/config";
import { getAllItem, stockTransfer } from '../../slices/itemSlice';


const validationSchema = yup.object().shape({
    quantity: yup.number().required('Please Add Quantity!').typeError('Your Quantity is Wrong!'),
    shop: yup.object().nullable().required('Please select an option!'),
})

const   TransferStock = ({open,setOpen,itemId}) => {
    const dispatch = useDispatch()
    const [ shop,setShop ] = React.useState(null)
    const { shops, loading: shopLoading } = useSelector(state=> state.Shop)
    const [ searchShop,setSearchShop ] = React.useState("")
    const [ filteredShops,setFilteredShops ] = React.useState([])

    const fetchData = async()=>{
        await dispatch(getAllItem({ method: 'get', data: null, keyword: null }))
    }

    const {
        handleSubmit,
        setValue,
        trigger,
        register,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    function handleClose() {
        setOpen(false)
        reset()
        setShop(null)
    }

    const handleOnSubmit = async (data)=>{
        const formattedData = {
            senderId: shopId(),
            receiverId: data.shop.id,
            itemId: Number(itemId),
            quantity: data.quantity
        }
        await dispatch(stockTransfer({ method: 'post', data: formattedData }))
        handleClose()
        fetchData()
    }

    React.useEffect(()=>{
        if(shops){
            const filtered = shops.data.filter(shop=> shop.id !== Number(getInfoFromLocal()?.user?.shopId))
            setFilteredShops(filtered)
        }
    },[shops])

    React.useEffect(()=>{
        if(shop){
            setValue('shop', shop)
            trigger('shop')
        }else{
            setValue('shop', null)
        }
    },[shop])

   return (
    <Dialog
        open={open}
        onClose={handleClose}
    >
        <DialogContent sx={{ width: 350 }}>
            <DialogTitle sx={{ pl: 0, pt: 0 }}>Stock Transfer</DialogTitle>
            <form autoComplete='off' onSubmit={handleSubmit(handleOnSubmit)}>
                <TextField
                    fullWidth
                    error={!!errors?.quantity}
                    helperText={errors?.quantity?.message}
                    {...register('quantity')}
                    sx={{ mb:2 }}
                    size='small'
                    label={'Quantity'}>
                </TextField>
                <AsyncAutoComplete
                    size={"small"}
                    setSearchText={setSearchShop}
                    selectedOption={shop}
                    setSelectedOption={setShop}
                    options={filteredShops || []}
                    placeholder={'Select A Shop'}
                    error={errors?.shop}
                    loading={shopLoading}
                />
                <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Button type='button' variant="text" sx={{ mr: 2 }} onClick={handleClose} >Cancel</Button>
                    <Button type='submit' variant="contained">Confirm</Button>
                </Box>
            </form>
        </DialogContent>
    </Dialog>
  )
}

export default TransferStock
