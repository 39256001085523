import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL, HEADERS } from "../utils/config";
import axios from "axios";
import { ShowToast } from "../components/utils/ShowToast";

const initialState = {
    seasonalDiscount: null,
    loading: false,
    data: null,
    error: null
}

export const seasonalDiscountCrud = createAsyncThunk(
    'seasonalDiscount/seasonalDiscountCrud',
    async ({ method,data,id }) => {
        try {
            const config = {
                method: method,
                url: `${BASE_URL}/seasonal-discounts${id ? `/${id}`: ""}`,
                headers: HEADERS(),
                data: data
            };
            const response = await axios(config);
            if(method === 'put'){
                ShowToast('success', 'Successfully changed')
            }
            return response.data;
        } catch (error) {
            console.log(error)
            if(error.response.status === 403){
                ShowToast('error', 'Access Denied')
            }else{
                ShowToast('error', 'Something went wrong')
            }
            throw new Error(error);
        }
    }
);


const seasonalDiscountSlice = createSlice({
    name: "seasonalDiscount",
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
        .addCase(seasonalDiscountCrud.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(seasonalDiscountCrud.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            if(action.meta.arg.method === 'get'){
                state.seasonalDiscount = action.payload?.data[0]
            }
        })
        .addCase(seasonalDiscountCrud.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })
    }
})


export default seasonalDiscountSlice.reducer;