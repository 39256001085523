import { Box, IconButton, Typography } from "@mui/material"
import theme from "../utils/theme";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loading from "../components/utils/Loading";
import { useEffect, useRef, useState } from "react";
import { getDailyReport } from "../slices/reportSlice";
import ItemSaleTable from "../components/report/ItemSaleTable";
import ServiceSaleTable from "../components/report/ServiceSaleTable";
import GoldCardSaleTable from "../components/report/GoldCardSaleTable";
import MembershipSaleTable from "../components/report/MembershipSaleTable";
import MembershipUsageTable from "../components/report/MembershipUsageTable";
import GoToBackButton from '../components/utils/GoToBackButton'
import { useReactToPrint } from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
import { changeDate } from "../utils/changeDateTime";

const DailyReport = () => {

    const dispatch = useDispatch()
    const { id } = useParams()
    const printRef = useRef()
    const [ printScreen,setPrintScreen ] = useState(false)
    const [ saleRecords,setSaleRecords ] = useState(null)
    const [ serviceRecords,setServiceRecords ] = useState(null)
    const [ membershipSales,setMembershipSales ] = useState(null)
    const [ membershipUsages,setMembershipUsages ] = useState(null)
    const [ goldCardSales,setGoldCardSales ] = useState(null)
    const [ date,setDate ] = useState(null)
    const { dailyReport, loading } = useSelector(state=> state.Report)

    const fetchData = async()=>{
        await dispatch(getDailyReport({ method: 'get', data: null, id: id }))
    }

    const handlePrint = useReactToPrint({
        content: ()=> printRef.current,
        documentTitle: `salaries_list_${Date.now()}`,
        documentTitle: Date.now(),
        onAfterPrint: ()=> setPrintScreen(false)
    })

    useEffect(()=>{
        if(dailyReport && dailyReport.data){
            const data = dailyReport.data
            setSaleRecords(data.saleRecords)
            setDate(data.date)
            setServiceRecords(data.serviceRecords)
            setMembershipSales(data.memberships)
            setMembershipUsages(data.membershipUsages)
            setGoldCardSales(data.goldCardRecords)
        }
    },[dailyReport])
  
    useEffect(()=>{
        fetchData()
    },[id])

    return (
        <Box sx={{
        bgcolor: theme.palette.common.white,
        borderRadius: '10px',
        minHeight: "100vh",
        p: 3
        }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 3 }}>
                <IconButton color="info" onClick={ async()=>{
                    await setPrintScreen(true)
                    handlePrint()
                }}>
                    <PrintIcon sx={{ fontSize: '30px' }}></PrintIcon>
                </IconButton>
            </Box>
            <Box
                ref={printRef}
                sx={{
                    width: { xs: '100%', lg: '100%' },
                    height: '100%',
                    p: 2,
                    '@media print': {
                        // width: { xs: '210mm', lg: '210mm' },
                        '@page': { 
                            size: 'landscape',
                            margin: '25px 20px',
                        }
                    },
                }}>
                { loading && <Loading/> }
                <Typography variant="h5" sx={{ textAlign: 'center', fontWeight: 'bold' }}>{date ? `Daily Report For ${changeDate(date)}` : ""}</Typography>
                <ItemSaleTable saleRecords={saleRecords}></ItemSaleTable>
                <Box sx={{ mb: 3 }}></Box>
                <ServiceSaleTable serviceRecords={serviceRecords}></ServiceSaleTable>
                <Box sx={{ mb: 3 }}></Box>
                <GoldCardSaleTable goldCardRecords={goldCardSales}></GoldCardSaleTable>
                <Box sx={{ mb: 3 }}></Box>
                <MembershipSaleTable membershipRecords={membershipSales}></MembershipSaleTable>
                <Box sx={{ mb: 3 }}></Box>
                <MembershipUsageTable membershipUsageRecords={membershipUsages}></MembershipUsageTable>
            </Box>
            <GoToBackButton/>
        </Box>
    )
}

export default DailyReport