import { Box, Typography, Button } from "@mui/material";
import DatePicker from "react-datepicker";
import theme from "../../utils/theme";
import { useDispatch, useSelector } from "react-redux";
import { getDailyCommission, getStaffDetail, staffTotalSaving } from "../../slices/staffSlice";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import Loading from "../utils/Loading";
import ItemSaleTable from "../report/ItemSaleTable";
import ServiceSaleTable from "../report/ServiceSaleTable";
import GoldCardSaleTable from "../report/GoldCardSaleTable";
import MembershipSaleTable from "../report/MembershipSaleTable";
import MembershipUsageTable from "../report/MembershipUsageTable";
import GoToBackButton from "../utils/GoToBackButton";

const StaffDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const {
    staffDetail: staffDetail,
    dailyCommission,
    loading,
    totalSaving,
  } = useSelector((state) => state.Staff);
  const [selectedDate, setSelectedDate] = useState(null);
  const [saleRecords, setSaleRecords] = useState(null);
  const [serviceRecords, setServiceRecords] = useState(null);
  const [membershipSales, setMembershipSales] = useState(null);
  const [membershipUsages, setMembershipUsages] = useState(null);
  const [goldCardSales, setGoldCardSales] = useState(null);
  const today = format(new Date(), "yyyy-MM-dd");

  const fetchStaffDetail = async (date) => {
    const dispatchData = await dispatch(
      getStaffDetail({ method: "get", id: id, date: date || today })
    );
    if (dispatchData.meta.requestStatus === "fulfilled") {
      const payloadData = await dispatchData.payload?.data;
      const formattedDataForPayload = await [
        ...payloadData.goldCardRecords,
        ...payloadData.membershipUsages,
        ...payloadData.memberships,
        ...payloadData.saleRecords,
        ...payloadData.serviceRecords,
      ];
      await dispatch(getDailyCommission(formattedDataForPayload ? formattedDataForPayload : []));
    }
  };

  const getTotalSaving = async () => {
    await dispatch(staffTotalSaving({ method: "get", data: null, id: id }));
  };

  useEffect(() => {
    if (selectedDate) {
      const formattedDate = format(selectedDate, "yyyy-MM-dd");
      fetchStaffDetail(formattedDate);
    } else {
      fetchStaffDetail();
    }
  }, [selectedDate]);

  useEffect(() => {
    if (staffDetail && staffDetail.data) {
      const data = staffDetail.data;
      setSaleRecords(data.saleRecords);
      setServiceRecords(data.serviceRecords);
      setMembershipSales(data.memberships);
      setMembershipUsages(data.membershipUsages);
      setGoldCardSales(data.goldCardRecords);
    }
  }, [staffDetail]);

  useEffect(() => {
    fetchStaffDetail();
    getTotalSaving();
  }, []);

  return (
    <Box
      sx={{
        bgcolor: theme.palette.common.white,
        borderRadius: "10px",
        height: "100%",
        p: 3,
      }}
    >
      {loading && <Loading />}
      <Typography variant="h6" align="center" sx={{ fontWeight: "bold" }}>
        Daily Reports for "{staffDetail?.data && staffDetail.data.name}"
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          my: 3,
          gap: 1,
          px: 3,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <DatePicker
            placeholderText="Select Date"
            dateFormat={"dd-MM-yyyy"}
            className="customDatePicker"
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
          />
          <Button
            onClick={() => setSelectedDate(null)}
            disabled={!selectedDate}
            sx={{ height: "100%", ml: 2 }}
            variant="contained"
          >
            Today
          </Button>
        </Box>
        <Typography>Total Commission - {dailyCommission || "0"} MMK</Typography>
        <Typography>Total Savings - {totalSaving || "0"} MMK</Typography>
      </Box>
      <ItemSaleTable saleRecords={saleRecords}></ItemSaleTable>
      <Box sx={{ mb: 3 }}></Box>
      <ServiceSaleTable serviceRecords={serviceRecords}></ServiceSaleTable>
      <Box sx={{ mb: 3 }}></Box>
      <GoldCardSaleTable goldCardRecords={goldCardSales}></GoldCardSaleTable>
      <Box sx={{ mb: 3 }}></Box>
      <MembershipSaleTable
        membershipRecords={membershipSales}
      ></MembershipSaleTable>
      <Box sx={{ mb: 3 }}></Box>
      <MembershipUsageTable
        membershipUsageRecords={membershipUsages}
      ></MembershipUsageTable>
      <GoToBackButton />
    </Box>
  );
};

export default StaffDetail;
