import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL, HEADERS } from "../utils/config";
import axios from "axios";
import { ShowToast } from "../components/utils/ShowToast";

const initialState = {
    goldCardInfo: null,
    goldCardSales: null,
    loading: false,
    data: null,
    error: null
}

export const goldCardRU = createAsyncThunk(
    'seasonalDiscount/goldCardRU',
    async ({ method,data,id }) => {
        try {
            const config = {
                method: method,
                url: `${BASE_URL}/gold-cards${id ? `/${id}`: ""}`,
                headers: HEADERS(),
                data: data
            };
            const response = await axios(config);
            if(method === 'put'){
                ShowToast('success', 'Successfully changed')
            }
            return response.data;
        } catch (error) {
            if(error.response.status === 403){
                ShowToast('error', 'Access Denied')
            }else{
                ShowToast('error', 'Something went wrong')
            }
            throw new Error(error);
        }
    }
);

export const getGoldCardSales = createAsyncThunk(
    'seasonalDiscount/getGoldCardSales',
    async () => {
        const searchParams = new URLSearchParams(window.location.search);
        const keyword = searchParams.get('keyword')
        const pageNo = searchParams.get('page');
        try {
            const config = {
                method: "get",
                url: `${BASE_URL}/gold-card-records${keyword ? `?customer=${keyword}` : '?customer=' }${pageNo ? `&page=${pageNo}` : '' }`,
                headers: HEADERS(),
                data: null
            };
            const response = await axios(config);
            return response.data;
        } catch (error) {
            if(error.response.status === 403){
                ShowToast('error', 'Access Denied')
            }else{
                ShowToast('error', 'Something went wrong')
            }
            throw new Error(error);
        }
    }
);



const seasonalDiscountSlice = createSlice({
    name: "seasonalDiscount",
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
        .addCase(goldCardRU.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(goldCardRU.fulfilled, (state, action) => {
            state.loading = false;
            if(action.meta.arg.method === 'get'){
                state.goldCardInfo = action.payload.data
            }else{
                state.data = action.payload;
            }
        })
        .addCase(goldCardRU.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })
        .addCase(getGoldCardSales.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(getGoldCardSales.fulfilled, (state, action) => {
            state.loading = false;
            state.goldCardSales = action.payload;
        })
        .addCase(getGoldCardSales.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })
    }
})


export default seasonalDiscountSlice.reducer;