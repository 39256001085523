import { decryptData } from '../utils/decrypt'
import { encrypt } from './encrypt';

const BASE_URL = 'https://hua-mei.rcs-mm.com/api'; 

const IMG_BASE_URL = 'https://hua-mei.rcs-mm.com'

const secretKey = "7D97449D989FA7664494C74F827BE"

const authName = 'huamei_beauty_center'

const auth = localStorage.getItem(authName);

const setInfoToLocal = async (info)=>{
    const encryptedData = await encrypt(info)
    localStorage.setItem(authName, encryptedData)
}

const getInfoFromLocal = ()=>{
    const authData = localStorage.getItem(authName)
    return JSON.parse(decryptData(authData))
}

const updateShopToLocal = (id)=>{
    if(!auth) return;
    const existingJson = JSON.parse(decryptData(auth))
    existingJson.user.shopId = id;
    setInfoToLocal(existingJson)
}

const token = ()=> {
    if(!auth) return;
    return JSON.parse(decryptData(auth)).token
}

const shopId = ()=> {
    if(!auth) return;
    return Number(JSON.parse(decryptData(auth)).user.shopId)
}

const HEADERS = ()=>{
    return {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(decryptData(auth)).token}`,
    }
};
    
export { BASE_URL,HEADERS,setInfoToLocal,authName, token, shopId, updateShopToLocal, secretKey, IMG_BASE_URL, getInfoFromLocal };