import React, { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material"
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import { getAllStaff } from "../../slices/staffSlice";
import { shopId } from '../../utils/config';
import { advanceCud, getAllAdvance } from '../../slices/salaryAdvanceSlice';
import AsyncSelect from '../utils/AsyncSelect';

const createCashAdvanceSchema = yup.object().shape({
    staff: yup.object().nullable().required('Please Select Staff Name!'),
    amount: yup.number().required('Please Add Amount!').typeError('Your Amount is Wrong!'),
    note: yup.string().nullable(true)
})

const AddCashAdvance = ({ open, setOpen }) => {
    const dispatch = useDispatch()
    const { staffs: staffResponse, loading: staffLoading} = useSelector(state=> state.Staff)
    const [ isopen, setIsOpen ] = React.useState(open);
    const [ searchTextStaff,setSearchTextStaff ] = useState('')
    const [ staff,setStaff ] = useState(null)
    const [ staffPageNo,setStaffPageNo ] = useState(1)
    const [ staffs,setStaffs ] = useState([])

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        trigger,
    } = useForm({
        resolver: yupResolver(createCashAdvanceSchema)
    })

    function handleClose() {
        setIsOpen(false)
        setOpen(false)
        reset()
        setStaff(null)
    }

    const fetchStaffs = async () => {
        await dispatch(
          getAllStaff({
            method: "get",
            data: null,
            keyword: searchTextStaff,
            scrollPage: staffPageNo,
          })
        );
    };

    const handleOnSubmit = async (data)=>{
        const newData = {
            employeeId: data.staff.id,
            price: data.amount,
            description: data.note,
            shopId: shopId
        }
        await dispatch(advanceCud({ method: 'post', data: newData, id: null }))
        await dispatch(getAllAdvance({ method: 'get', data: null }))
        handleClose()
    }

    React.useEffect(() => {
        staffResponse &&
        staffResponse.data &&
        setStaffs(staffResponse.data);
    }, [staffResponse]);

    React.useEffect(() => {
        fetchStaffs();
    }, [searchTextStaff, staffPageNo]);

    React.useEffect(()=>{
        if(staff){
            setValue('staff', staff)
            trigger('staff')
        }else{
            setValue('staff', null)
        }
    },[staff])

    React.useEffect(
        () => {
            setIsOpen(open)
        }, [open]
    )
    return (
        <React.Fragment>
            <Dialog
                open={isopen}
                onClose={handleClose}
            >
                <DialogContent sx={{ width: 400 }}>
                    <DialogTitle sx={{ pl: 0, pt: 0 }}>Add Salary Advance</DialogTitle>
                    <form autoComplete='off' onSubmit={handleSubmit(handleOnSubmit)}>
                        <AsyncSelect
                            placeholder={"Select Staff"}
                            loading={staffLoading}
                            value={staff?.name || ""}
                            setValue={setStaff}
                            pageNo={staffPageNo}
                            searchText={searchTextStaff}
                            options={staffs}
                            setSearchText={setSearchTextStaff}
                            setPageNo={setStaffPageNo}
                            error={errors?.staff}
                            hasMore={staffPageNo < staffResponse?.meta?.last_page}
                            position='absolute'
                        ></AsyncSelect>
                        <TextField fullWidth sx={{ my: 3 }} size='small' {...register('amount')} error={!!errors?.amount} helperText={errors?.amount?.message} label="Amount"></TextField>
                        <TextField fullWidth size='small' {...register('note')} error={!!errors?.note} helperText={errors?.note?.message} label="Note"></TextField>
                        <Box sx={{ mt: 3, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <Button type='button' variant="text" sx={{ mr: 2 }} onClick={handleClose} >Cancel</Button>
                            <Button type='submit' variant="contained">Confirm</Button>
                        </Box>
                    </form>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

export default AddCashAdvance