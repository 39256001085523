import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DiscountIcon from '@mui/icons-material/Discount';
import UpdateSeasonalDis from './UpdateSeasonalDis';
import TimerIcon from '@mui/icons-material/Timer';
import { useSelector } from 'react-redux';

const SeasonalDis = () => {
    // const dispatch = useDispatch()
    const { seasonalDiscount } = useSelector(state=> state.SeasonalDiscount)
    const [ open,setOpen ] = useState(false)
    const [ serviceDiscount, setServiceDiscount ] = useState(null)
    const [ itemDiscount,setItemDiscount ] = useState(null)
    const [ startDate,setStartDate ] = useState(null)
    const [ endDate,setEndDate ] = useState(null)
    useEffect(()=>{
      if(seasonalDiscount){
        setStartDate(seasonalDiscount?.startDate);
        setEndDate(seasonalDiscount?.endDate)
        setServiceDiscount(seasonalDiscount?.advancedServiceDiscountPercent)
        setItemDiscount(seasonalDiscount?.itemDiscountPercent)
      }
    },[seasonalDiscount])

  return (
    <Box>
        <UpdateSeasonalDis open={open} setOpen={setOpen}></UpdateSeasonalDis>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">Seasonal Discount</Typography>
            <Typography onClick={()=> setOpen(true) } underline="hover" color="primary" sx={{ fontSize: { md: '12px', xl: '14px'}, cursor: "pointer" }}>Change...</Typography>
        </Box>
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            <ListItem>
                <ListItemAvatar>
                    <Avatar className='gradiant2'>
                      <TimerIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={"Start Date"} secondary={startDate || 'loading...'} />
                  <ListItemText primary={"End Date"} secondary={endDate || 'loading...'} />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                    <Avatar className='gradiant2'>
                      <DiscountIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={"Item Discount"} secondary={itemDiscount || '0'} />
                  <ListItemText primary={"Service Discount"} secondary={serviceDiscount || '0'} />
              </ListItem>
          </List>
      </Box>
  )
}

export default SeasonalDis
