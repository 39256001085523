import React , { useEffect, useState } from "react";
import { Switch, Box, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import theme from "../utils/theme";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import StyledTableHead from "../components/utils/StyledTableHead";
import StyledTableData from "../components/utils/StyledTableData";
import SaveIcon from '@mui/icons-material/Save';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import NoDataAlert from "../components/utils/NoDataAlert";
import { useNavigate, useLocation } from "react-router-dom";
import Loading from "../components/utils/Loading";
import AlertDialog from "../components/utils/AlertDialog";
import { beauticianServiceCrud } from "../slices/serviceSlice";
import AppPagination from "../components/main/filter-components/AppPagination";

const createCategorySchema = yup.object().shape({
    name: yup.string().required("Enter beautician's service name!"),
});

const BeauticianService = () => {
    const location = useLocation()
    const [ showAlert,setShowAlert ] = useState(false)
    const [ idToDel,setIdToDel ] = useState(null)
    const navigate = useNavigate()
    const [ edit,setEdit ] = useState(false)
    const [ serviceToEdit,setServiceToEdit ] = useState(null)
    const { beauticianServices, loading } = useSelector(state=> state.Service)
    const dispatch = useDispatch()
    // const [checked, setChecked] = React.useState(true);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue
    } = useForm({
        resolver: yupResolver(createCategorySchema)
    });

    const fetchData = async()=>{
        await dispatch(beauticianServiceCrud({ method: 'get', data: null }))
    }

    const handleAdd = async (data) => {
        if(edit){
            await dispatch(beauticianServiceCrud({ method: 'put', data: {...data, isDiscounted: serviceToEdit.isDiscounted}, id: serviceToEdit.id }))
            setServiceToEdit(null)
            setEdit(false)
        }else{
            await dispatch(beauticianServiceCrud({ method: 'post', data: {...data, isDiscounted: false }, id: null }))
        }
        fetchData()
        reset()
    };

    const handleDelete = async (id)=>{
        setShowAlert(true)
        setIdToDel(id)
    }

    const handleCancel = ()=>{
        setShowAlert(false)
        setIdToDel(null)
    }
    
    const handleConfirm = async ()=>{
        await dispatch(beauticianServiceCrud({ method: 'delete', data: null, id: idToDel }))
        setShowAlert(false)
        setIdToDel(null)
        fetchData();
    }

    const handleUpdate = async (service)=>{
        window.scrollTo(0,0);
        setValue('name', service.name)
        setServiceToEdit(service)
        setEdit(true)
    }

    const handleChange = async (event, service) => {
        await dispatch(beauticianServiceCrud({ method: 'put', data: { name: service.name, isDiscounted: event.target.checked }, id: service.id }))
        fetchData()
    };

    useEffect(() => {
        fetchData();
        setValue("name", null)
        setServiceToEdit(null)
        setEdit(false)
    },[location.search]);

    useEffect(()=>{
        navigate("")
    },[])

    return (
        <Box sx={{ p: 3 }}>
            {
                loading && <Loading/>
            }
            <AlertDialog
                toggle={showAlert}
                setToggle={setShowAlert}
                cancel={handleCancel}
                confrim={handleConfirm}
                title={"Are you sure?"}
                content={"You want to delete this service."}
            ></AlertDialog>
            <form autoComplete='off' onSubmit={handleSubmit(handleAdd)}>
                <Box sx={{
                    p: 2,
                    width: 400
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                        <Typography variant="subtitle1">Add New Beautician's Service</Typography>
                        <IconButton type="submit">
                            <SaveIcon sx={{ color: '#D14D72'}}/>
                        </IconButton>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'fit-content'
                    }}>
                        <TextField
                            {...register('name')}
                            error={!!errors?.name}
                            placeholder='Service Name'
                            helperText={errors?.name?.message}
                            // size="small"
                            sx={{ my: 1 }}
                        />
                    </Box>
                </Box>
            </form>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <StyledTableHead align="center">No</StyledTableHead>
                            <StyledTableHead>Name</StyledTableHead>
                            <StyledTableHead>Discount</StyledTableHead>
                            <StyledTableHead align="center">Action</StyledTableHead>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            (beauticianServices && beauticianServices.data.length > 0 ) ?
                            [...beauticianServices.data].reverse().map((bService,index)=>
                                <TableRow key={index}>
                                <StyledTableData align="center">{((beauticianServices?.meta?.current_page - 1) * beauticianServices?.meta?.per_page)+(index + 1)}</StyledTableData>
                                <StyledTableData>{bService.name}</StyledTableData>
                                <StyledTableData>
                                    <Switch
                                        checked={Number(bService.isDiscounted) === 1}
                                        onChange={(event)=>handleChange(event,bService)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />    
                                </StyledTableData>
                                <StyledTableData align="center">
                                    <Tooltip title={'Edit'}>
                                        <IconButton onClick={()=> handleUpdate(bService) } sx={{ mr: 2 }}>
                                            <BorderColorIcon sx={{ color: theme.palette.warning.main }} />
                                        </IconButton>
                                    </Tooltip>                                          
                                    <Tooltip title={'Delete'}>
                                        <IconButton onClick={()=> handleDelete(bService.id)}>
                                            <DeleteIcon sx={{ color: theme.palette.primary.main }} />
                                        </IconButton>
                                    </Tooltip>                                          
                                </StyledTableData>
                                </TableRow>
                            ):
                            <TableRow>
                                <TableCell colSpan={4}>
                                <NoDataAlert content={"There is no category names"}></NoDataAlert>
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <AppPagination pageCount={beauticianServices?.meta?.last_page}></AppPagination>
        </Box>
    );
};

export default BeauticianService;
