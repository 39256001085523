import React, { useState } from 'react';
import { Box, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import huaMeiLogo from '../../assets/images/hua_mei_logo.png';
import theme from '../../utils/theme';
import { useParams } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import SideBarItem from '../main/SideBarItem';

import {
    Dashboard as DashboardIcon,
    Store as StoreIcon,
    BarChart as BarChartIcon,
    ShoppingCart as ShoppingCartIcon,
    Widgets as WidgetsIcon,
    FaceRetouchingNatural as FaceRetouchingNaturalIcon,
    PeopleAlt as PeopleAltIcon,
    Dvr as DvrIcon,
    AttachMoney as AttachMoneyIcon,
    ManageAccounts as ManageAccountsIcon,
    Groups2 as Groups2Icon,
    CardMembership as CardMembershipIcon,
    // Handshake as HandshakeIcon,
    Receipt as ReceiptIcon,
    Description as DescriptionIcon,
    LocalAtm as LocalAtmIcon,
    // ReceiptLong as ReceiptLongIcon,
    // LocalOffer as LocalOfferIcon,
    // Groups as GroupsIcon,
    AssistantPhoto as AssistantPhotoIcon,
    // Summarize as SummarizeIcon,
    CardGiftcard as CardGiftcardIcon,
    ExpandLess,
    ExpandMore,
  } from '@mui/icons-material';
  
  const SideBar = ({ drawerWidth }) => {
    const { id } = useParams();
    const [openReceipts, setOpenReceipts] = useState(false);
    const [openServicePlan, setOpenServicePlan] = useState(false);
    const [openEmployee, setOpenEmployee] = useState(false);
    const [openSetting, setOpenSetting] = useState(false);
  
    const toggleOpenState = (stateSetter) => () => {
      stateSetter((prevState) => !prevState);
    };
  
    const receipts = [
      { title: 'Sales & Services', link: 'sales-and-services', link2: 'create-sale-voucher', link3: `sale-voucher-detail/${id}`, icon: <ReceiptIcon /> },
      { title: 'Purchases', link: 'purchases', link2: 'create-purchase-voucher', link3: `purchase-voucher-detail/${id}`, icon: <ShoppingCartIcon /> },
    ];
  
    const servicePlan = [
      { title: 'Memberships', link: 'members', icon: <CardMembershipIcon /> },
      { title: 'Gold Cards', link: 'gold-cards', icon: <CardGiftcardIcon /> },
    ];
  
    const employeeSettings = [
      { title: 'Beauticians', link: 'beauticians', link2: 'create-new-beautician', link3: `beautician-detail/${id}`, icon: <PeopleAltIcon /> },
      { title: 'Assistants', link: 'assistants', link2: 'create-new-assistant', link3: `assistant-detail/${id}`, icon: <AssistantPhotoIcon /> },
    ];
  
    const settings = [
      { title: 'Attendance', link: 'attendance', icon: <DvrIcon /> },
      { title: 'Salary', link: 'salary', icon: <AttachMoneyIcon /> },
      { title: 'Report', link: 'report', icon: <DescriptionIcon /> },
      { title: 'User Management', link: 'user-management', link2: `permission-controls-for-user/${id}`, icon: <ManageAccountsIcon /> },
    ];
  
    const menuSectionsOne = [
      { title: 'Dashboard', link: '', icon: <DashboardIcon /> },
      { title: 'Shops List', link: 'shops-list', icon: <StoreIcon /> },
      { title: 'Items', link: 'items', link2: `stock-detail/${id}`, icon: <WidgetsIcon /> },
      { title: 'Services', link: 'services', icon: <FaceRetouchingNaturalIcon /> },
      { title: 'Customers', link: 'customers', icon: <Groups2Icon /> },
    ];
  
    const menuSectionsTwo = [
      { title: 'Salary Advance', link: 'cash-advance', icon: <LocalAtmIcon /> },
      { title: 'Expenses', link: 'expenses', icon: <BarChartIcon /> },
    ];
  
    const renderExpandableSection = (title, state, toggleHandler, items) => (
      <>
        <ListItemButton onClick={toggleHandler}>
          <ListItemIcon>{items[0]?.icon}</ListItemIcon>
          <ListItemText primary={title} />
          {state ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={state} timeout='auto' unmountOnExit>
          <List component='div' disablePadding sx={{ pl: 2 }}>
            {items.map((section, index) => (
              <SideBarItem key={index} section={section} isExpandable={true} />
            ))}
          </List>
        </Collapse>
        <Box sx={{ height: 12 }}></Box>
      </>
    );
  
    return (
      <>
        <Box>
          <Box sx={{ display: 'flex', pl: 2, pt: 3 }}>
            <img height={70} src={`${huaMeiLogo}`} alt={'Hua Mei'} loading='lazy' />
            <Box sx={{ py: 1, px: 2 }}>
              <Typography sx={{ color: theme.palette.primary.main, fontWeight: 'bold', fontFamily: 'Lato', fontSize: 20, pb: 1 }}>ဟွားမိန်</Typography>
              <Typography>Beauty Center</Typography>
            </Box>
          </Box>
  
          <List>
            {menuSectionsOne.map((section, index) => (
              <SideBarItem key={index} section={section} />
            ))}
  
            {renderExpandableSection('Receipts', openReceipts, toggleOpenState(setOpenReceipts), receipts)}
            {renderExpandableSection('Service Plan', openServicePlan, toggleOpenState(setOpenServicePlan), servicePlan)}
            {renderExpandableSection('Employee', openEmployee, toggleOpenState(setOpenEmployee), employeeSettings)}
            {renderExpandableSection('Setting & Report', openSetting, toggleOpenState(setOpenSetting), settings)}
  
            {menuSectionsTwo.map((section, index) => (
              <SideBarItem key={index} section={section} />
            ))}
          </List>
          <Box sx={{ height: 150 }}></Box>
        </Box>
      </>
    );
  };
  
  export default SideBar;
