import { Box, CircularProgress, IconButton, TextField } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';
import theme from '../../utils/theme';
import ClearIcon from '@mui/icons-material/Clear';

const AsyncSelect = ({ placeholder ,value, setValue, options, searchText, setSearchText, pageNo, setPageNo, hasMore, loading, error, position, size }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [fetchedOptions, setFetchedOptions] = useState([]);
  const optionsContainerRef = useRef(null);

  const handleScroll = () => {
    const container = optionsContainerRef.current;
    const isAtBottom = container.scrollTop + container.clientHeight === container.scrollHeight;

    if (isAtBottom && hasMore && !loading) {
      setPageNo((prev) => prev + 1);
    }
  };

  const handleSelect = (selectedValue) => {
    setValue(selectedValue);
    setDropdownOpen(false);
  };

  const handleClear = () => {
    setSearchText('');
    setValue(null);
    setDropdownOpen(false);
  };

  const handleClickOutside = (event) => {
    if (optionsContainerRef.current && !optionsContainerRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  const fetchData = async () => {
    if (pageNo === 1) {
      setFetchedOptions([...options]);
    } else {
      setFetchedOptions(prev=> [...prev,...options.filter(option => !prev.some(prevItem => prevItem.id === option.id))])
      // setFetchedOptions((prev) => [...prev, ...options]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [options,pageNo]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div style={{ pb: 10, position: 'relative' }}>
      <TextField
        fullWidth
        error={!!error}
        helperText={(!isDropdownOpen && error?.message) && error?.message}
        placeholder={placeholder}
        size={size ? size : 'small'}
        type="input"
        autoComplete='off'
        value={value || searchText}
        onChange={(e) => {
          setPageNo(1);
          setSearchText(e.target.value);
          setDropdownOpen(true);
        }}
        onFocus={() => setDropdownOpen(true)}
        InputProps={{
          endAdornment: (
            <IconButton onClick={handleClear} size="small" style={{ visibility: (searchText?.length > 0 || value?.length > 0) || value?.name?.length > 0 ? 'visible' : 'hidden' }}>
              <ClearIcon />
            </IconButton>
          ),
        }}
      />
      {isDropdownOpen && (
        <Box
          // data-popper-placement="bottom"
          ref={optionsContainerRef}
          style={{
            maxHeight: 150,
            overflowY: 'auto',
            border: '1px solid #ccc',
            borderRadius: '4px',
            backgroundColor: '#fff',
            width: '100%',
            position: position,
            // inset: '0px auto auto 0px',
            // transform: 'translate(583px,430px)',
            zIndex: 1000
          }}
          onScroll={handleScroll}
        >
          {
            !(fetchedOptions.length > 0) && !loading ?
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 1 }}>
              There is no option
            </Box>
            :
            fetchedOptions.map((option, index) => (
            <Box
              key={index}
              sx={{
                p: 0.7,
                cursor: 'pointer',
                ':hover': { backgroundColor: theme.palette.common.blue, color: theme.palette.common.white },
              }}
              onClick={() => handleSelect(option)}
            >
              {placeholder === 'Select Customer' ? `${option.name} ${option.phone ? `(${option.phone})` : ``}` : placeholder === 'Select Available Membership' ? `${option.serviceName} (${option.servicePrice})` : option.name || ""}
            </Box>
          ))}
          {loading && (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 1 }}>
              <CircularProgress size={15}/>
            </Box>
          )}
        </Box>
      )}
    </div>
  );
};

export default AsyncSelect;
