import React, { useEffect, useState } from 'react';
import { Box, Button, FormControlLabel, FormGroup, IconButton, Switch, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography, alpha } from "@mui/material"
import theme from "../../utils/theme";
import StyledTableData from '../utils/StyledTableData';
import StyledTableHead from '../utils/StyledTableHead';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import CheckIcon from '@mui/icons-material/Check';
import { useDispatch, useSelector } from 'react-redux';
import { getAllItem } from '../../slices/itemSlice';
import DeleteIcon from '@mui/icons-material/Delete';
import { nanoid } from '@reduxjs/toolkit';
import { shopId } from '../../utils/config';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from '../utils/AsyncSelect';
import { receiptCud } from '../../slices/receiptSlice';
import NoDataAlert from '../utils/NoDataAlert';
import AlertDialog from '../utils/AlertDialog';
import GoToBackButton from '../utils/GoToBackButton';

const itemSchema = yup.object().shape({
    item: yup.object().nullable().required('Please Select Item Name!'),
    quantity: yup.number().min(1, 'Quantity must be atleast 1').required('Please add quantity!').typeError('Your quantity is wrong!')
})

const CreatePurchaseVoucher = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [ showAlertGoBack, setShowAlertGoBack] = useState(false);
    const [ collectedItems, setCollectedItems ] = useState([])
    const [ totalPrice,setTotalPrice ] = useState(0)
    const [ searchQuery, setSearchQuery ] = useState("");
    const [ items,setItems ] = useState([])
    const [ item, setItem ] = React.useState(null);
    const [ addToStock, setAddToStock ] = useState(false)
    const [ pageNo, setPageNo ] = useState(1);
    const { items: response, loading } = useSelector(state=> state.Item)


    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        trigger,
    } = useForm({
        resolver: yupResolver(itemSchema),
        defaultValues:{
            quantity: 0
        }
    });
    
    const fetchItems = async ()=>{
        await dispatch(getAllItem({ method: 'get', data: null, keyword: searchQuery, scrollPage: pageNo }))
    }

    const handleAddPurchaseItem = (data) => {
        const newData = {
            id: nanoid(),
            item: data.item,
            quantity: data.quantity
        }
        const existingItemIndex = collectedItems.findIndex(item => item.item.id === newData.item.id);
        if (existingItemIndex !== -1) {
            const updatedItems = [...collectedItems];
            updatedItems[existingItemIndex].quantity += newData.quantity;
            setCollectedItems(updatedItems);
        } else {
            setCollectedItems(prevItems => [...prevItems, newData]);
        }

        reset();
        setItem(null);
    }

    const handleRemoveItem = (id)=>{
        const result = collectedItems.filter(el=> el.id !== id);
        setCollectedItems(result)
    }

    const handleCreate = async ()=>{
        if(!(collectedItems.length > 0)) return;
        const createData = {
            price: totalPrice,
            shopId: shopId(),
            addToStock: addToStock,
            items: collectedItems.map(collectItem=>{
                return {
                id: collectItem.item.id,
                quantity: collectItem.quantity,
                price: Number(collectItem.item.purchasePrice)
            }})
        }
        await dispatch(receiptCud({ method: 'post', data: createData, type: 'purchase' }))
        navigate('/purchases')
    }

    const handleCancelGoBack = () => {
        setShowAlertGoBack(false);
    };
  
      const handleConfirmGoBack = () => {
        setShowAlertGoBack(false);
        navigate(-1)
    };

    const handleCancel = ()=>{
        reset()
        setItem(null)
    }

    const handleGoBack = ()=>{
        setShowAlertGoBack(true);
    }

    useEffect(()=>{
        const total = collectedItems.reduce((acc, item) => acc + (item.item.purchasePrice * item.quantity), 0);
        setTotalPrice(total)
    },[collectedItems])
    

    React.useEffect(()=>{
        if(item){
            setValue('item', item)
            trigger('item')
        }else{
            setValue('item', null)
        }
    },[item])

    useEffect(() => {
        if (response) setItems(response.data);
      }, [response]);
    
    useEffect(() => {
        fetchItems()
    }, [pageNo, searchQuery]);

    return (
        <Box sx={{
            bgcolor: theme.palette.common.white,
            borderRadius: '10px',
            p: 5,
            height: '100%'
        }}>
            <AlertDialog
                toggle={showAlertGoBack}
                setToggle={setShowAlertGoBack}
                cancel={handleCancelGoBack}
                confrim={handleConfirmGoBack}
                title={"Are you sure !"}
                content={"You want to go back without saving your voucher"}
            ></AlertDialog>
            <Typography sx={{ fontWeight: 'bold', mb: 3 }} variant='h6'>Add Item</Typography>
            {/* add section */}
            <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between' }}>
                <Box sx={{ width: '50%' }}>
                    <form autoComplete='off' onSubmit={handleSubmit(handleAddPurchaseItem)}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'start',
                            justifyContent: 'space-between'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                gap: 2
                            }}>
                                <Box sx={{ width: 300 }}>
                                    <AsyncSelect
                                        placeholder={"Select Item"}
                                        loading={loading}
                                        value={item?.name || ""}
                                        setValue={setItem}
                                        pageNo={pageNo}
                                        searchText={searchQuery}
                                        options={items}
                                        setSearchText={setSearchQuery}
                                        setPageNo={setPageNo}
                                        hasMore={pageNo < response?.meta?.last_page}
                                        error={errors?.item}
                                        position={'absolute'}
                                    ></AsyncSelect>
                                </Box>
                                <TextField
                                    sx={{ width: 300 }}
                                    size='small'
                                    label={'Quantity'}
                                    {...register('quantity')}
                                    error={!!errors.quantity}
                                    helperText={errors?.quantity?.message}
                                ></TextField>
                            </Box>
                            <Button type='button' onClick={handleCancel} variant='text' sx={{ color: theme.palette.dark.main, ml: 5, mr: 3 }}>Cancel</Button>
                            <Button type='submit' variant='contained'>Add</Button>
                        </Box>
                    </form>
                </Box>
            </Box>
            <Table sx={{ mt: 5 }}>
                <TableHead>
                    <TableRow>
                        <StyledTableHead align='center'>No</StyledTableHead>
                        <StyledTableHead>Item Name</StyledTableHead>
                        <StyledTableHead align='center'>Price</StyledTableHead>
                        <StyledTableHead align='center'>Quantity</StyledTableHead>
                        <StyledTableHead align='center'>Total</StyledTableHead>
                        <StyledTableHead></StyledTableHead>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { 
                        collectedItems.length > 0 ?
                        collectedItems.map((item, index) => (
                        <TableRow key={index}>
                            <StyledTableData align='center'>{index + 1}</StyledTableData>
                            <StyledTableData>{item.item.name}</StyledTableData>
                            <StyledTableData align='center'>{item.item.purchasePrice}</StyledTableData>
                            <StyledTableData align='center'>{item.quantity}</StyledTableData>
                            <StyledTableData align='center'>{item.quantity * item.item.purchasePrice}</StyledTableData>
                            <StyledTableData align='center'>
                                <IconButton onClick={()=> handleRemoveItem(item.id)}>
                                    <DeleteIcon sx={{ color: theme.palette.primary.main }} />
                                </IconButton>
                            </StyledTableData>
                        </TableRow>
                    ))
                    :
                    <TableRow>
                        <TableCell colSpan={9}>
                            <NoDataAlert content={"There is no item"}></NoDataAlert>
                        </TableCell>
                    </TableRow>
                    }
                    <TableRow>
                        <TableCell sx={{ bgcolor: alpha(theme.palette.dark.main, 0.1)}} colSpan={3}></TableCell>
                        <TableCell sx={{ bgcolor: alpha(theme.palette.dark.main, 0.1), fontSize: '18px' }} align='center'>Total Price</TableCell>
                        <TableCell sx={{ bgcolor: alpha(theme.palette.dark.main, 0.1), fontSize: '18px' }} align='center'>{totalPrice}</TableCell>
                        <TableCell sx={{ bgcolor: alpha(theme.palette.dark.main, 0.1)}}></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' , mt: 3 }}>
                <Button sx={{ mr: 5, color: 'black' }} onClick={handleGoBack}>Cancel</Button>
                <FormGroup>
                    <FormControlLabel control={
                        <Switch
                            checked={addToStock}
                            onChange={(e)=> setAddToStock(e.target.checked) }
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    } label="Add To Stock" />
                </FormGroup>
                <Button disabled={!(collectedItems.length > 0)} onClick={()=> handleCreate() } sx={{ ml: 3 }} variant="contained" startIcon={<CheckIcon />}>Save</Button>
            </Box>
            <GoToBackButton/>
        </Box>
    )
}

export default CreatePurchaseVoucher